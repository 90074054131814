import React from 'react'

export default function Bonfire() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0,512) scale(0.1,-0.1)"
                fill="#000000" stroke="none">
                <path d="M2662 4870 c-235 -120 -419 -323 -560 -619 -122 -256 -199 -569 -239
-967 l-8 -80 -30 8 c-112 29 -210 220 -248 485 l-13 88 -42 -75 c-252 -447
-380 -938 -343 -1316 22 -223 89 -420 195 -568 53 -75 181 -198 259 -249 255
-169 631 -247 1056 -218 371 24 627 96 834 234 106 71 228 199 286 302 51 88
102 234 122 345 20 109 17 419 -4 545 -66 382 -224 736 -426 955 l-60 65 15
-59 c68 -266 6 -475 -171 -577 -63 -36 -81 -38 -64 -6 21 38 24 233 5 307 -41
159 -115 262 -268 372 -112 80 -162 140 -199 237 -65 172 -65 441 0 741 12 52
21 95 21 97 0 10 -30 -2 -118 -47z m156 -1517 c-67 -76 -87 -226 -48 -354 41
-136 118 -265 311 -524 137 -182 164 -224 198 -293 88 -178 58 -339 -85 -471
-202 -185 -597 -258 -914 -170 -338 93 -513 359 -460 699 11 70 72 270 93 308
l13 23 29 -41 c41 -59 132 -140 184 -163 51 -22 116 -19 157 8 23 14 25 22 20
53 -3 21 -9 89 -12 152 -5 97 -3 129 15 203 52 217 194 401 416 538 83 51 109
61 83 32z"/>
                <path d="M260 2065 c-207 -57 -316 -285 -227 -478 31 -67 102 -142 161 -168
24 -10 817 -226 1762 -479 946 -254 1955 -524 2244 -602 509 -136 527 -140
605 -136 99 6 144 27 213 102 67 75 95 146 95 251 1 90 -25 162 -81 226 -65
74 17 49 -1457 444 -222 60 -372 95 -390 92 -16 -2 -64 -12 -105 -21 -214 -48
-564 -69 -785 -47 -424 43 -734 183 -948 429 -32 37 -74 92 -93 123 l-35 56
-402 107 c-221 59 -424 109 -452 111 -28 3 -74 -2 -105 -10z m4702 -1365 c72
-81 74 -211 4 -303 -117 -153 -270 -111 -293 81 -8 71 12 133 60 189 60 67 93
85 147 80 37 -4 51 -12 82 -47z"/>
                <path d="M4685 2065 c-33 -8 -217 -56 -409 -108 l-348 -94 -52 -76 c-96 -144
-229 -261 -395 -350 l-83 -45 53 -15 c154 -44 658 -177 672 -177 28 0 757 199
802 219 60 26 131 100 163 169 23 48 27 71 27 142 0 72 -4 94 -28 145 -70 150
-238 229 -402 190z"/>
                <path d="M610 975 c-220 -59 -407 -115 -438 -130 -195 -101 -226 -386 -60
-554 63 -64 109 -84 203 -89 65 -3 99 1 175 21 52 14 451 121 885 237 1018
272 962 257 945 263 -12 5 -1253 338 -1310 352 -8 2 -188 -43 -400 -100z
m-301 -240 c47 -24 117 -109 131 -160 27 -97 -14 -225 -83 -259 -127 -62 -286
121 -248 287 12 56 58 122 95 137 32 13 74 11 105 -5z"/>
            </g>
        </svg>
    )
}
