import React from 'react'

export function Vegan() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path d="M2453 4766 c-110 -35 -168 -108 -168 -211 1 -108 59 -179 168 -206
54 -13 162 -7 228 12 l42 13 -7 115 c-3 63 -8 117 -11 119 -2 2 -43 1 -90 -3
l-85 -7 0 -44 c0 -44 0 -44 35 -44 33 0 35 -2 35 -35 l0 -35 -55 0 c-48 0 -60
4 -91 33 -66 60 -54 161 24 194 26 11 59 14 115 11 72 -4 78 -3 82 16 13 55
10 69 -15 77 -41 12 -162 9 -207 -5z" />
                <path d="M1947 4690 c-71 -21 -131 -40 -133 -43 -10 -9 108 -407 120 -407 6 0
74 18 151 40 77 22 141 40 141 40 8 0 -22 83 -30 86 -6 2 -49 -7 -96 -20 -46
-14 -86 -23 -88 -22 -1 2 -5 19 -8 38 l-6 34 77 19 c42 10 78 22 80 25 4 6
-13 81 -20 88 -2 2 -39 -7 -84 -19 -45 -12 -83 -20 -85 -18 -2 2 -6 16 -10 31
-5 20 -2 28 11 32 10 2 50 14 89 25 l72 21 -13 43 c-7 23 -18 43 -26 44 -8 1
-72 -16 -142 -37z" />
                <path d="M2996 4708 c-58 -92 -216 -353 -216 -358 0 -6 89 -30 110 -30 4 0 20
20 35 45 19 33 31 44 43 41 9 -3 35 -10 58 -15 40 -11 41 -12 47 -62 4 -29 8
-54 11 -56 6 -6 118 -35 121 -31 6 7 -34 441 -42 448 -7 6 -132 40 -147 40 -3
0 -12 -10 -20 -22z m74 -157 l0 -79 -40 10 -40 10 36 69 c20 38 38 69 40 69 2
0 4 -36 4 -79z" />
                <path d="M1588 4558 c-27 -16 -48 -37 -48 -46 0 -20 55 -259 66 -287 4 -11
-47 31 -114 93 l-121 114 -56 -32 c-30 -17 -55 -33 -55 -35 0 -6 337 -295 343
-295 3 0 32 16 64 35 l59 35 -40 225 c-22 124 -42 225 -45 224 -3 0 -27 -14
-53 -31z" />
                <path d="M3466 4558 c-16 -26 -167 -292 -185 -325 -10 -20 -7 -24 35 -49 56
-33 45 -42 134 121 34 64 63 115 64 113 1 -2 -1 -77 -4 -168 -4 -110 -2 -169
4 -176 6 -6 32 -23 58 -37 l46 -27 106 185 c58 101 106 187 106 189 0 5 -92
57 -96 54 -1 -2 -31 -55 -66 -119 l-63 -115 3 155 3 156 -60 33 c-33 17 -62
32 -65 32 -3 0 -12 -10 -20 -22z" />
                <path d="M1039 3946 c-213 -229 -385 -544 -468 -856 -141 -533 -65 -1102 212
-1573 334 -567 916 -939 1577 -1008 201 -21 465 -3 675 45 636 147 1184 617
1431 1228 166 412 198 861 89 1282 -85 331 -254 646 -474 882 -54 58 -85 68
-114 36 -30 -33 -20 -63 48 -142 445 -514 595 -1201 401 -1841 -248 -819 -999
-1378 -1853 -1379 -856 -1 -1610 558 -1859 1379 -61 201 -77 317 -78 556 0
161 4 233 18 310 69 383 216 692 461 975 69 80 80 113 47 143 -32 29 -59 20
-113 -37z" />
                <path d="M2790 3712 c-161 -167 -222 -301 -222 -492 1 -123 17 -194 69 -300
48 -95 169 -221 263 -273 228 -126 519 -101 724 62 88 71 186 176 186 200 0
20 -32 61 -47 61 -43 1 -355 39 -360 44 -3 3 18 99 47 214 35 138 51 218 46
235 -11 46 -42 45 -268 -13 -115 -29 -211 -50 -214 -47 -5 5 -43 317 -44 360
0 4 -9 16 -20 27 -37 37 -59 26 -160 -78z m92 -258 c17 -164 28 -194 72 -194
12 0 106 23 211 50 104 27 190 48 192 47 1 -2 -20 -88 -47 -193 -27 -104 -50
-198 -50 -210 0 -44 30 -55 194 -72 86 -9 156 -19 156 -24 0 -12 -99 -87 -150
-113 -168 -85 -372 -76 -526 23 -273 177 -329 531 -125 790 23 29 45 52 49 52
5 0 15 -70 24 -156z" />
                <path d="M2035 2874 c-188 -46 -350 -177 -437 -355 -91 -185 -204 -566 -268
-906 -28 -144 -21 -184 39 -244 63 -63 100 -68 265 -35 318 64 636 154 817
231 178 77 299 185 370 331 192 396 -25 866 -449 975 -91 23 -248 24 -337 3z
m309 -119 c196 -51 360 -215 410 -411 9 -34 16 -99 16 -144 0 -157 -52 -284
-159 -390 -80 -80 -175 -129 -388 -200 -234 -78 -694 -187 -740 -175 -13 3
-30 17 -39 30 -19 29 -17 44 46 320 76 330 164 599 239 725 71 119 179 201
316 241 76 22 221 24 299 4z" />
                <path d="M1837 2402 c-34 -37 -22 -61 81 -164 104 -104 132 -117 165 -80 34
38 22 63 -81 165 -103 103 -131 116 -165 79z" />
                <path d="M2292 2374 c-44 -31 -30 -61 77 -168 104 -104 138 -120 168 -80 29
40 17 64 -85 166 -101 101 -120 111 -160 82z" />
                <path d="M1740 1952 c-43 -35 -31 -66 66 -164 107 -108 116 -114 155 -98 31
13 44 39 35 68 -9 26 -200 212 -218 212 -9 0 -26 -8 -38 -18z" />
            </g>
        </svg>
    )
}

export function Gluten() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M2401 4863 l-34 -4 7 -67 c19 -190 19 -231 3 -255 -28 -44 -97 -41
-126 5 -7 12 -20 83 -29 159 l-16 139 -55 -7 c-31 -3 -58 -8 -60 -10 -3 -2 2
-66 11 -143 17 -151 37 -204 90 -243 60 -45 216 -27 270 30 51 54 57 94 36
289 -13 128 -4 118 -97 107z"/>
                <path d="M2654 4825 c-3 -25 -4 -49 0 -52 3 -3 28 -8 56 -11 28 -2 52 -7 54
-11 2 -3 -6 -77 -18 -165 -15 -108 -19 -159 -12 -162 6 -2 33 -5 60 -6 l50 -3
22 160 c13 88 23 160 23 161 1 0 27 -3 58 -7 32 -4 59 -6 61 -4 3 2 7 24 9 48
l5 45 -38 6 c-22 3 -93 13 -159 21 -66 9 -130 18 -142 20 -19 5 -23 0 -29 -40z"/>
                <path d="M3179 4744 c-91 -233 -139 -368 -132 -375 9 -8 270 -109 282 -109 12
0 34 81 24 90 -4 4 -43 20 -85 36 -81 30 -91 42 -65 79 13 17 17 17 83 -9 38
-14 75 -26 81 -26 11 0 33 50 33 75 0 7 -34 25 -75 41 -41 16 -75 32 -75 36 0
4 4 18 10 32 l9 25 86 -34 85 -33 15 35 c8 20 15 41 15 47 0 7 -57 34 -127 61
-71 26 -134 50 -140 52 -7 3 -18 -8 -24 -23z"/>
                <path d="M1703 4701 c-23 -9 -44 -19 -47 -22 -5 -5 130 -388 140 -398 7 -7
276 93 281 104 5 14 -25 85 -35 85 -5 0 -44 -13 -88 -29 l-79 -29 -56 151
c-30 83 -60 152 -65 153 -5 1 -28 -6 -51 -15z"/>
                <path d="M1395 4546 c-110 -52 -195 -161 -195 -252 0 -59 45 -142 98 -179 92
-63 226 -30 345 86 l59 57 -64 96 c-34 52 -66 96 -71 96 -9 0 -147 -91 -147
-98 0 -10 42 -72 49 -72 4 0 16 7 27 15 26 20 27 19 47 -14 17 -29 17 -30 -14
-52 -92 -65 -209 -12 -209 95 0 49 54 107 124 137 l54 22 -15 43 c-8 24 -20
44 -26 44 -7 0 -34 -11 -62 -24z"/>
                <path d="M3528 4400 c-65 -92 -118 -172 -118 -177 0 -4 10 -14 23 -22 12 -8
31 -20 43 -28 19 -12 23 -10 47 24 140 206 149 217 144 188 -3 -16 -14 -94
-24 -173 l-17 -143 29 -24 c17 -13 41 -30 55 -38 l25 -15 123 173 c67 95 122
175 122 178 0 6 -78 57 -88 57 -4 0 -41 -49 -82 -110 -40 -60 -74 -110 -75
-110 -1 0 4 28 11 63 7 34 17 103 21 154 l9 92 -58 41 c-31 22 -61 40 -65 40
-4 -1 -61 -77 -125 -170z"/>
                <path d="M952 3953 c-67 -73 -197 -273 -257 -397 -97 -199 -155 -385 -191
-611 -27 -166 -25 -459 5 -629 67 -381 217 -706 461 -999 106 -126 289 -294
367 -335 43 -23 97 26 80 72 -3 8 -39 40 -79 72 -255 201 -466 482 -591 789
-263 642 -159 1380 269 1929 60 76 69 114 32 140 -34 24 -48 20 -96 -31z"/>
                <path d="M4030 3980 c-11 -11 -20 -30 -20 -41 0 -12 31 -62 69 -113 363 -477
488 -1083 340 -1661 -63 -248 -186 -499 -348 -710 -69 -91 -216 -239 -313
-317 -48 -38 -90 -78 -93 -89 -13 -41 36 -86 77 -70 41 16 181 136 282 241
294 307 477 673 553 1105 24 140 24 500 0 640 -62 355 -198 669 -404 938 -79
102 -104 116 -143 77z"/>
                <path d="M3177 3586 c-80 -66 -128 -116 -161 -164 -43 -62 -50 -68 -76 -64
-26 4 -46 -9 -144 -92 -80 -66 -127 -115 -157 -160 -39 -59 -46 -65 -76 -64
-18 1 -41 -6 -50 -14 -10 -8 -62 -52 -115 -97 -63 -53 -113 -105 -140 -146
-37 -57 -44 -63 -68 -58 -22 4 -41 -7 -106 -58 -220 -176 -294 -303 -294 -509
l0 -86 -32 -18 c-18 -10 -57 -36 -85 -58 -62 -45 -69 -78 -28 -126 l25 -30
-62 -54 c-35 -30 -108 -91 -163 -137 -120 -100 -145 -126 -145 -152 0 -25 34
-59 60 -59 11 0 107 73 216 164 l197 164 52 -24 c61 -28 171 -54 230 -54 63 0
172 27 239 58 66 31 282 203 291 231 3 10 3 25 0 33 -4 11 8 20 42 33 66 25
132 69 241 159 83 70 92 81 92 112 0 23 5 34 14 34 34 0 155 76 256 160 91 76
110 96 110 119 0 25 9 32 83 69 94 48 284 201 293 237 9 35 -9 58 -65 85 l-50
23 63 62 c99 97 156 230 156 367 0 38 -5 54 -24 73 -45 45 -224 47 -335 2
l-60 -24 -21 68 c-20 67 -39 89 -74 89 -8 0 -66 -42 -129 -94z m117 -171 c7
-68 -6 -186 -30 -257 -15 -46 -16 -46 -67 -53 -29 -4 -76 -13 -104 -20 l-53
-13 0 53 c0 121 65 246 173 335 70 57 70 57 81 -45z m402 -2 c-9 -69 -58 -165
-108 -215 -49 -50 -138 -98 -180 -98 -33 0 -32 -1 -14 82 8 35 17 96 21 136 6
63 11 75 32 89 46 30 127 52 192 53 l64 0 -7 -47z m-781 -306 c7 -78 -7 -211
-29 -274 -12 -37 -15 -38 -70 -45 -32 -4 -74 -12 -94 -18 -65 -18 -66 -17 -59
58 7 80 36 159 83 223 37 51 142 142 154 134 4 -3 11 -38 15 -78z m497 -131
c41 -8 89 -20 107 -28 l33 -13 -64 -52 c-129 -104 -236 -136 -382 -113 -47 7
-89 16 -94 19 -7 4 -4 34 7 80 20 83 19 82 126 106 80 18 177 18 267 1z m-873
-252 c1 -68 -27 -226 -43 -241 -2 -2 -35 -8 -72 -14 -38 -5 -82 -14 -98 -19
-40 -14 -48 1 -40 70 14 120 70 220 173 307 l66 56 7 -39 c4 -21 7 -75 7 -120z
m516 -68 c33 -8 74 -20 90 -26 l30 -11 -35 -30 c-149 -128 -266 -161 -448
-129 -60 11 -72 16 -67 29 4 9 12 42 19 75 7 33 19 62 28 67 65 36 276 50 383
25z m-899 -142 c10 -68 -2 -241 -20 -300 l-16 -52 -56 -7 c-31 -4 -79 -13
-106 -20 l-50 -13 4 77 c7 115 47 194 145 288 42 40 80 73 84 73 4 0 11 -21
15 -46z m557 -185 c43 -12 77 -25 75 -30 -2 -4 -34 -33 -73 -64 -117 -93 -223
-119 -381 -95 -92 14 -90 12 -73 87 21 88 22 89 96 106 37 9 82 18 98 21 58 7
181 -5 258 -25z m-374 -316 c35 -10 66 -22 67 -28 5 -15 -113 -104 -174 -133
-78 -36 -167 -48 -242 -34 -61 11 -166 56 -204 86 -20 16 -20 16 0 31 31 23
162 76 228 91 78 18 239 11 325 -13z"/>
                <path d="M1795 1007 c-3 -6 -34 -95 -70 -196 -36 -102 -64 -190 -61 -196 2 -6
21 -17 42 -24 22 -7 44 -14 51 -17 6 -3 19 20 32 58 32 95 27 92 111 63 40
-14 76 -25 80 -25 4 0 13 18 20 41 16 48 16 48 -78 80 -60 20 -63 23 -57 48
11 43 20 45 95 17 39 -14 77 -26 84 -26 12 0 42 77 34 86 -2 1 -53 21 -113 43
-61 21 -123 44 -138 50 -21 8 -29 8 -32 -2z"/>
                <path d="M3145 968 c-77 -28 -141 -51 -142 -52 -2 -2 137 -397 142 -404 1 -1
68 21 149 50 161 57 154 50 127 122 l-10 24 -91 -33 -92 -33 -13 31 c-16 37
-13 43 20 51 14 4 50 16 79 27 l54 20 -16 42 c-9 23 -17 43 -17 45 -1 1 -39
-11 -84 -27 l-82 -29 -10 25 c-5 13 -9 28 -9 32 0 4 38 21 85 37 47 16 85 33
85 39 0 5 -8 26 -18 47 l-17 36 -140 -50z"/>
                <path d="M2170 879 c-1 -8 -11 -102 -24 -209 -13 -107 -21 -198 -17 -201 3 -4
29 -9 58 -12 l52 -6 5 32 c3 18 8 53 11 80 7 51 22 60 62 32 15 -11 24 -33 33
-84 13 -76 18 -81 96 -81 l42 0 -15 77 c-8 42 -23 89 -33 103 -18 26 -18 26
11 43 36 20 63 78 54 117 -15 69 -90 104 -242 115 -75 6 -93 4 -93 -6z m200
-109 c11 -11 20 -24 20 -30 0 -24 -41 -50 -80 -50 -43 0 -46 6 -34 69 6 27 10
31 40 31 21 0 42 -8 54 -20z"/>
                <path d="M2730 873 c-63 -8 -117 -16 -119 -18 -6 -7 41 -409 48 -418 8 -8 292
23 305 34 4 4 5 26 1 49 l-7 42 -67 -6 c-36 -4 -81 -10 -99 -13 l-33 -5 3 38
3 39 80 8 c44 5 81 10 83 12 2 2 0 24 -4 50 l-7 47 -43 -6 c-24 -3 -64 -9 -89
-12 l-45 -6 0 35 0 34 73 7 c39 4 80 9 90 12 17 5 21 31 11 72 -7 26 -16 26
-184 5z"/>
            </g>
        </svg>
    )
}

export function Dairy() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M2577 4698 c-4 -45 -8 -144 -9 -218 l-3 -135 57 -3 57 -3 5 33 c3 18
7 115 10 216 l5 182 -28 0 c-16 0 -42 3 -58 6 l-30 6 -6 -84z"/>
                <path d="M2181 4744 c-40 -7 -74 -14 -76 -16 -1 -2 -14 -93 -29 -203 -15 -110
-30 -209 -33 -221 -4 -20 -2 -21 53 -12 31 5 61 11 66 14 4 3 8 23 8 44 0 42
20 70 49 70 10 0 32 3 49 6 29 6 33 3 54 -41 l23 -47 65 6 c35 4 66 8 68 10 4
5 -211 406 -218 405 -3 -1 -38 -7 -79 -15z m79 -227 c-3 -2 -22 -7 -44 -10
l-39 -6 7 46 c3 25 6 62 7 82 0 35 1 34 37 -36 20 -40 34 -74 32 -76z"/>
                <path d="M2840 4558 c-23 -106 -43 -199 -44 -207 -1 -9 18 -18 54 -27 30 -7
56 -13 57 -14 1 0 9 36 19 80 15 74 19 80 38 74 34 -9 56 -43 56 -84 0 -83 7
-96 62 -109 27 -6 54 -11 59 -11 14 0 0 149 -17 182 -17 34 -17 38 0 38 8 0
28 14 45 31 25 25 31 39 31 73 0 79 -61 126 -197 152 -129 24 -113 41 -163
-178z m218 66 c30 -20 29 -61 -2 -75 -32 -15 -100 -3 -92 16 2 7 8 30 12 49 6
33 9 35 33 30 14 -4 36 -13 49 -20z"/>
                <path d="M3360 4628 c0 -7 5 -73 11 -148 l11 -136 -40 -80 -40 -81 54 -26 54
-27 42 84 c41 83 42 84 127 135 47 28 104 62 125 75 l40 24 -63 31 -63 32 -61
-54 c-34 -29 -66 -56 -72 -59 -7 -4 -8 25 -5 84 3 54 1 93 -5 98 -30 23 -115
59 -115 48z"/>
                <path d="M1625 4571 c-65 -23 -180 -86 -180 -98 0 -5 38 -91 84 -191 57 -123
90 -182 100 -182 26 0 165 64 204 94 47 36 94 107 102 155 14 81 -36 186 -105
221 -50 25 -137 26 -205 1z m141 -114 c31 -28 37 -42 41 -84 6 -62 -17 -103
-78 -133 l-39 -20 -14 32 c-8 18 -30 68 -50 110 -20 42 -36 81 -36 86 0 14 70
41 108 41 23 1 42 -8 68 -32z"/>
                <path d="M1072 3983 c-139 -148 -262 -323 -351 -498 -226 -449 -280 -969 -150
-1455 115 -431 375 -823 725 -1096 45 -34 66 -44 89 -42 33 3 63 44 52 71 -3
8 -56 57 -118 108 -357 296 -593 711 -676 1189 -23 131 -23 469 0 600 65 377
217 698 463 981 88 101 98 125 64 159 -30 30 -58 24 -98 -17z"/>
                <path d="M3950 4000 c-34 -34 -24 -58 63 -159 252 -290 407 -624 469 -1006 18
-117 16 -456 -5 -575 -64 -369 -211 -687 -449 -965 -79 -93 -149 -160 -290
-278 -61 -51 -68 -68 -42 -105 25 -36 64 -28 131 25 123 99 197 169 285 271
262 304 434 681 490 1077 16 117 16 435 0 550 -64 436 -254 830 -554 1148 -40
41 -68 47 -98 17z"/>
                <path d="M2126 3784 c-12 -12 -16 -37 -16 -107 l0 -92 -175 -303 -175 -302 0
-824 c0 -796 1 -825 19 -847 l19 -24 762 0 762 0 19 24 c18 22 19 51 19 847
l0 824 -175 302 -175 303 0 92 c0 70 -4 95 -16 107 -13 14 -67 16 -434 16
-367 0 -421 -2 -434 -16z m764 -129 l0 -25 -330 0 -330 0 0 25 0 25 330 0 330
0 0 -25z m30 -160 c7 -9 71 -116 141 -238 l128 -222 -312 -3 c-171 -1 -317 0
-323 2 -10 4 -255 420 -270 459 -6 16 14 17 309 17 271 0 316 -2 327 -15z
m-629 -258 l116 -202 -119 -3 c-65 -1 -172 -1 -238 0 l-119 3 117 203 c64 111
119 202 122 202 3 -1 57 -92 121 -203z m159 -1082 l0 -755 -282 2 -283 3 -3
753 -2 752 285 0 285 0 0 -755z m788 558 l-3 -198 -330 0 -330 0 -3 185 c-1
101 0 190 2 197 4 10 76 13 336 13 l330 0 -2 -197z m-8 -558 l0 -235 -325 0
-325 0 0 235 0 235 325 0 325 0 0 -235z m8 -552 l-3 -198 -330 0 -330 0 -3
198 -2 197 335 0 335 0 -2 -197z"/>
                <path d="M1812 912 c-3 -9 -36 -100 -73 -202 l-67 -185 47 -17 c25 -10 51 -18
57 -18 6 0 22 28 34 63 13 34 27 66 31 71 7 8 45 -3 147 -41 13 -6 46 62 39
81 -3 6 -37 22 -76 36 -73 25 -78 31 -62 73 l10 25 63 -23 c113 -40 105 -40
123 1 8 20 15 40 15 44 0 9 -2 10 -158 66 -111 40 -123 42 -130 26z"/>
                <path d="M3161 880 c-74 -27 -135 -51 -137 -53 -3 -3 46 -144 130 -373 l11
-32 140 50 c77 28 144 53 148 58 4 4 0 26 -9 49 -13 32 -21 41 -33 36 -33 -14
-155 -55 -162 -55 -7 0 -32 61 -27 65 2 1 32 12 68 24 94 31 94 31 79 80 -7
22 -15 41 -18 41 -3 0 -41 -12 -84 -26 -43 -14 -80 -24 -82 -23 -2 2 -6 16
-10 30 -6 25 -3 28 72 54 43 16 81 31 85 35 8 6 -21 90 -31 89 -3 0 -66 -22
-140 -49z"/>
                <path d="M2780 789 c-74 -9 -140 -18 -146 -18 -10 -1 33 -410 45 -422 6 -7
300 26 308 34 2 2 1 23 -3 47 l-7 42 -86 -7 c-47 -4 -90 -8 -96 -8 -5 -1 -11
14 -13 35 l-3 36 74 7 c41 4 79 10 85 14 6 4 8 23 5 49 l-6 43 -76 -6 c-42 -4
-80 -8 -86 -9 -5 0 -11 13 -13 30 l-3 31 87 11 c48 7 89 14 91 16 7 6 -8 96
-16 95 -3 -1 -67 -10 -141 -20z"/>
                <path d="M2190 799 c-23 -186 -47 -415 -44 -419 3 -2 29 -7 59 -10 l54 -6 6
56 c10 92 13 100 39 100 34 0 52 -27 66 -101 12 -66 12 -67 48 -73 21 -3 49
-6 63 -6 l26 0 -15 77 c-8 43 -23 90 -34 105 -11 16 -15 28 -9 28 5 0 23 14
40 31 25 25 31 39 31 75 0 25 -7 55 -15 68 -26 40 -102 65 -214 72 -56 4 -101
5 -101 3z m198 -112 c40 -32 -11 -94 -68 -82 -31 7 -38 29 -24 83 6 20 11 22
43 16 20 -4 42 -11 49 -17z"/>
            </g>
        </svg>

    )
}

export function Soy() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M2470 4769 c-137 -55 -184 -245 -88 -355 47 -54 96 -74 175 -74 105
1 176 38 215 113 63 124 4 282 -119 316 -47 13 -149 13 -183 0z m167 -114 c62
-71 30 -205 -53 -221 -46 -8 -79 7 -104 48 -37 62 -16 167 40 196 35 18 91 8
117 -23z"/>
                <path d="M2920 4755 c0 -3 18 -66 40 -141 l40 -137 -22 -83 c-12 -46 -19 -86
-16 -89 3 -4 29 -12 57 -20 45 -12 51 -11 55 3 3 10 14 49 25 89 18 67 24 76
93 141 40 38 89 83 108 99 l35 31 -41 11 c-103 28 -95 30 -154 -50 -53 -70
-54 -71 -61 -45 -4 15 -11 56 -14 91 -4 36 -8 65 -9 65 0 0 -31 9 -69 19 -37
11 -67 18 -67 16z"/>
                <path d="M2020 4717 c-125 -35 -180 -120 -135 -207 21 -40 61 -59 144 -66 84
-8 108 -26 73 -55 -25 -20 -98 -34 -137 -27 -24 5 -25 3 -25 -48 l0 -54 48 0
c63 0 149 26 190 56 115 88 60 232 -90 235 -60 1 -92 12 -96 32 -4 22 52 47
107 47 35 0 42 4 47 24 3 13 4 35 2 47 -2 20 -9 24 -43 26 -22 1 -60 -4 -85
-10z"/>
                <path d="M1477 4310 c-184 -107 -417 -318 -555 -502 -210 -280 -337 -578 -399
-933 -24 -140 -24 -490 0 -630 79 -453 270 -828 582 -1140 109 -110 282 -248
369 -294 40 -22 48 -24 70 -11 29 15 40 41 31 70 -3 11 -52 51 -108 89 -526
359 -847 965 -847 1601 0 637 326 1252 850 1602 58 39 113 77 123 86 24 22 22
67 -5 86 -31 22 -35 21 -111 -24z"/>
                <path d="M3532 4334 c-27 -19 -29 -64 -4 -87 9 -8 58 -42 107 -74 590 -387
926 -1097 854 -1804 -59 -583 -371 -1101 -861 -1428 -42 -28 -80 -60 -83 -71
-9 -29 2 -55 31 -70 22 -13 30 -11 70 11 83 45 258 183 360 284 311 310 505
680 586 1125 18 95 21 154 21 340 0 186 -3 245 -21 340 -79 434 -273 809 -572
1110 -119 120 -261 232 -377 300 -76 45 -80 46 -111 24z"/>
                <path d="M1608 3808 c-25 -20 -101 -169 -153 -301 -91 -231 -108 -411 -49
-532 33 -67 116 -162 181 -206 62 -43 73 -71 73 -186 0 -54 7 -122 15 -152 31
-119 103 -226 199 -296 81 -60 96 -92 96 -212 0 -287 140 -429 563 -568 161
-53 198 -60 226 -45 12 6 46 60 77 123 83 165 123 272 158 428 l6 26 52 -15
c80 -23 389 -22 536 2 160 26 155 20 160 204 10 341 -46 565 -168 675 -63 57
-131 91 -235 116 -43 11 -91 29 -105 39 -26 19 -30 27 -75 124 -56 123 -251
272 -403 308 -53 12 -122 65 -122 93 0 7 -13 39 -29 72 -56 118 -155 209 -269
247 -61 20 -87 22 -302 23 -210 1 -243 4 -310 23 -86 25 -100 27 -122 10z
m187 -155 c105 -33 242 -93 311 -134 131 -80 174 -161 174 -328 0 -140 21
-193 102 -263 107 -92 140 -130 172 -197 27 -59 31 -79 36 -196 9 -176 25
-209 138 -292 70 -52 137 -143 148 -200 12 -66 -3 -194 -35 -290 -30 -92 -103
-260 -131 -302 l-15 -23 -128 42 c-158 51 -309 126 -374 185 -75 67 -95 121
-103 280 -9 171 -25 205 -143 295 -59 44 -107 105 -139 177 -17 38 -23 76 -28
184 -5 123 -8 140 -33 182 -15 25 -53 66 -85 91 -68 52 -124 112 -148 158 -55
106 -6 343 123 601 30 60 37 67 56 62 12 -3 58 -18 102 -32z m529 -23 c81 -31
135 -90 196 -215 66 -135 115 -176 242 -199 45 -8 168 -89 233 -151 29 -29 58
-72 81 -122 56 -124 94 -154 243 -192 100 -25 157 -56 205 -113 69 -80 106
-256 106 -504 l0 -132 -87 -13 c-50 -8 -163 -14 -258 -14 -143 1 -179 4 -225
20 -53 19 -55 22 -62 64 -16 95 -82 191 -189 277 -86 69 -102 100 -93 187 17
171 -75 361 -227 473 -68 50 -83 81 -80 176 3 139 -38 270 -110 353 -17 19
-66 58 -108 86 l-76 51 77 -6 c42 -3 101 -15 132 -26z"/>
                <path d="M1821 3538 c-107 -16 -200 -89 -249 -197 -20 -44 -26 -71 -26 -132 0
-67 5 -86 33 -143 62 -127 171 -191 316 -184 128 5 222 65 282 180 25 47 28
63 28 148 0 81 -4 103 -25 145 -66 134 -206 205 -359 183z m183 -156 c22 -15
50 -47 64 -72 20 -38 23 -56 20 -112 -3 -56 -9 -74 -35 -110 -43 -59 -100 -88
-175 -88 -124 0 -212 87 -212 210 0 175 194 274 338 172z"/>
                <path d="M2079 2877 c-91 -34 -155 -93 -196 -182 -73 -160 -4 -352 159 -436
32 -17 66 -24 130 -27 80 -4 92 -2 150 25 78 37 133 91 167 163 22 46 26 69
26 140 0 71 -4 94 -26 140 -31 67 -93 130 -157 161 -58 28 -196 37 -253 16z
m229 -141 c81 -56 114 -173 73 -261 -39 -84 -103 -125 -198 -125 -98 0 -179
64 -205 163 -23 87 22 184 108 235 33 19 51 22 110 20 59 -3 78 -8 112 -32z"/>
                <path d="M2400 2231 c-90 -29 -174 -104 -213 -189 -30 -66 -30 -198 0 -264 99
-218 387 -269 549 -96 68 73 89 126 89 228 0 101 -20 154 -86 225 -66 71 -126
98 -229 101 -47 2 -96 0 -110 -5z m188 -130 c139 -72 163 -248 47 -349 -47
-42 -77 -53 -142 -53 -64 0 -112 21 -157 71 -94 104 -63 264 63 330 49 26 140
26 189 1z"/>
                <path d="M1812 912 c-3 -9 -36 -100 -73 -202 l-67 -185 47 -17 c25 -10 51 -18
57 -18 6 0 22 28 34 63 13 34 27 66 31 71 7 8 45 -3 147 -41 13 -6 46 62 39
81 -3 6 -37 22 -76 36 -73 25 -78 31 -62 73 l10 25 63 -23 c113 -40 105 -40
123 1 8 20 15 40 15 44 0 9 -2 10 -158 66 -111 40 -123 42 -130 26z"/>
                <path d="M3161 880 c-74 -27 -135 -51 -137 -53 -3 -3 46 -144 130 -373 l11
-32 140 50 c77 28 144 53 148 58 4 4 0 26 -9 49 -13 32 -21 41 -33 36 -33 -14
-155 -55 -162 -55 -7 0 -32 61 -27 65 2 1 32 12 68 24 94 31 94 31 79 80 -7
22 -15 41 -18 41 -3 0 -41 -12 -84 -26 -43 -14 -80 -24 -82 -23 -2 2 -6 16
-10 30 -6 25 -3 28 72 54 43 16 81 31 85 35 8 6 -21 90 -31 89 -3 0 -66 -22
-140 -49z"/>
                <path d="M2780 789 c-74 -9 -140 -18 -146 -18 -10 -1 33 -410 45 -422 6 -7
300 26 308 34 2 2 1 23 -3 47 l-7 42 -86 -7 c-47 -4 -90 -8 -96 -8 -5 -1 -11
14 -13 35 l-3 36 74 7 c41 4 79 10 85 14 6 4 8 23 5 49 l-6 43 -76 -6 c-42 -4
-80 -8 -86 -9 -5 0 -11 13 -13 30 l-3 31 87 11 c48 7 89 14 91 16 7 6 -8 96
-16 95 -3 -1 -67 -10 -141 -20z"/>
                <path d="M2190 799 c-23 -186 -47 -415 -44 -419 3 -2 29 -7 59 -10 l54 -6 6
56 c10 92 13 100 39 100 34 0 52 -27 66 -101 12 -66 12 -67 48 -73 21 -3 49
-6 63 -6 l26 0 -15 77 c-8 43 -23 90 -34 105 -11 16 -15 28 -9 28 5 0 23 14
40 31 25 25 31 39 31 75 0 25 -7 55 -15 68 -26 40 -102 65 -214 72 -56 4 -101
5 -101 3z m198 -112 c40 -32 -11 -94 -68 -82 -31 7 -38 29 -24 83 6 20 11 22
43 16 20 -4 42 -11 49 -17z"/>
            </g>
        </svg>
    )
}

export function Nut() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M2427 4774 c-4 -4 -7 -73 -7 -154 0 -131 2 -150 23 -190 31 -63 82
-90 170 -90 80 0 123 16 160 59 36 43 44 82 50 239 l6 132 -59 0 c-70 0 -70 1
-70 -153 0 -126 -14 -173 -56 -183 -15 -4 -38 -3 -53 2 -44 16 -52 46 -49 199
l2 140 -55 3 c-30 2 -58 0 -62 -4z"/>
                <path d="M2170 4749 c-19 -6 -40 -12 -47 -14 -8 -3 -2 -41 22 -132 18 -70 32
-129 31 -130 -2 -2 -17 16 -34 40 -18 23 -62 74 -99 114 l-68 72 -65 -16 c-36
-9 -69 -19 -73 -23 -4 -4 15 -99 44 -212 l51 -206 50 14 c28 7 52 14 54 16 2
2 -14 71 -35 153 l-39 148 27 -34 c14 -19 66 -78 115 -132 79 -87 93 -98 115
-92 79 19 101 27 101 36 0 8 -61 258 -91 372 -5 20 -13 37 -17 36 -4 0 -23 -5
-42 -10z"/>
                <path d="M2991 4703 c-16 -57 -15 -59 37 -73 26 -7 50 -16 54 -19 4 -3 -13
-76 -38 -161 -24 -85 -44 -155 -44 -157 0 -2 105 -33 112 -33 2 0 24 70 48
155 33 113 49 155 60 155 8 0 36 -7 62 -14 l46 -14 11 45 c7 25 10 47 8 50 -3
2 -74 24 -158 49 -85 24 -162 47 -171 50 -13 4 -19 -4 -27 -33z"/>
                <path d="M1477 4310 c-184 -107 -417 -318 -555 -502 -210 -280 -337 -578 -399
-933 -24 -140 -24 -490 0 -630 79 -453 270 -828 582 -1140 109 -110 282 -248
369 -294 40 -22 48 -24 70 -11 29 15 40 41 31 70 -3 11 -52 51 -108 89 -526
359 -847 965 -847 1601 0 637 326 1252 850 1602 58 39 113 77 123 86 24 22 22
67 -5 86 -31 22 -35 21 -111 -24z"/>
                <path d="M3532 4334 c-27 -19 -29 -64 -4 -87 9 -8 58 -42 107 -74 590 -387
926 -1097 854 -1804 -59 -583 -371 -1101 -861 -1428 -42 -28 -80 -60 -83 -71
-9 -29 2 -55 31 -70 22 -13 30 -11 70 11 83 45 258 183 360 284 311 310 505
680 586 1125 18 95 21 154 21 340 0 186 -3 245 -21 340 -79 434 -273 809 -572
1110 -119 120 -261 232 -377 300 -76 45 -80 46 -111 24z"/>
                <path d="M1845 3806 c-267 -62 -452 -240 -521 -500 -22 -82 -22 -242 0 -325
66 -250 240 -419 562 -549 292 -118 434 -265 553 -572 55 -143 128 -258 226
-356 127 -128 262 -190 441 -201 317 -20 606 193 690 507 26 96 24 244 -5 345
-67 240 -238 403 -560 535 -290 119 -433 266 -546 560 -108 283 -284 470 -510
540 -90 28 -246 35 -330 16z m309 -135 c191 -67 330 -226 431 -491 114 -298
307 -490 607 -604 207 -78 375 -210 446 -351 58 -115 73 -280 37 -402 -9 -35
-20 -63 -24 -63 -3 0 -16 26 -29 58 -77 190 -281 411 -592 644 -236 177 -373
310 -517 503 -176 237 -215 285 -313 385 -115 118 -233 206 -351 264 l-89 43
53 16 c79 24 94 26 192 22 57 -2 112 -11 149 -24z m-401 -145 c183 -76 383
-252 561 -494 150 -206 178 -242 249 -318 120 -131 220 -219 407 -358 210
-156 379 -320 465 -449 97 -147 134 -262 100 -313 -16 -24 -21 -26 -74 -22
-188 13 -462 242 -711 593 -165 232 -320 389 -580 583 -361 271 -566 515 -596
710 -10 64 13 92 74 92 26 0 73 -11 105 -24z m-255 -224 c76 -187 281 -412
577 -632 258 -192 394 -324 538 -524 259 -357 441 -534 660 -641 l90 -44 -59
-18 c-88 -27 -248 -25 -336 5 -190 65 -332 227 -433 492 -114 298 -307 490
-607 604 -207 78 -375 210 -446 351 -57 114 -72 280 -37 403 10 34 20 62 24
62 3 0 16 -26 29 -58z"/>
                <path d="M1812 912 c-3 -9 -36 -100 -73 -202 l-67 -185 47 -17 c25 -10 51 -18
57 -18 6 0 22 28 34 63 13 34 27 66 31 71 7 8 45 -3 147 -41 13 -6 46 62 39
81 -3 6 -37 22 -76 36 -73 25 -78 31 -62 73 l10 25 63 -23 c113 -40 105 -40
123 1 8 20 15 40 15 44 0 9 -2 10 -158 66 -111 40 -123 42 -130 26z"/>
                <path d="M3161 880 c-74 -27 -135 -51 -137 -53 -3 -3 46 -144 130 -373 l11
-32 140 50 c77 28 144 53 148 58 4 4 0 26 -9 49 -13 32 -21 41 -33 36 -33 -14
-155 -55 -162 -55 -7 0 -32 61 -27 65 2 1 32 12 68 24 94 31 94 31 79 80 -7
22 -15 41 -18 41 -3 0 -41 -12 -84 -26 -43 -14 -80 -24 -82 -23 -2 2 -6 16
-10 30 -6 25 -3 28 72 54 43 16 81 31 85 35 8 6 -21 90 -31 89 -3 0 -66 -22
-140 -49z"/>
                <path d="M2780 789 c-74 -9 -140 -18 -146 -18 -10 -1 33 -410 45 -422 6 -7
300 26 308 34 2 2 1 23 -3 47 l-7 42 -86 -7 c-47 -4 -90 -8 -96 -8 -5 -1 -11
14 -13 35 l-3 36 74 7 c41 4 79 10 85 14 6 4 8 23 5 49 l-6 43 -76 -6 c-42 -4
-80 -8 -86 -9 -5 0 -11 13 -13 30 l-3 31 87 11 c48 7 89 14 91 16 7 6 -8 96
-16 95 -3 -1 -67 -10 -141 -20z"/>
                <path d="M2190 799 c-23 -186 -47 -415 -44 -419 3 -2 29 -7 59 -10 l54 -6 6
56 c10 92 13 100 39 100 34 0 52 -27 66 -101 12 -66 12 -67 48 -73 21 -3 49
-6 63 -6 l26 0 -15 77 c-8 43 -23 90 -34 105 -11 16 -15 28 -9 28 5 0 23 14
40 31 25 25 31 39 31 75 0 25 -7 55 -15 68 -26 40 -102 65 -214 72 -56 4 -101
5 -101 3z m198 -112 c40 -32 -11 -94 -68 -82 -31 7 -38 29 -24 83 6 20 11 22
43 16 20 -4 42 -11 49 -17z"/>
            </g>
        </svg>
    )
}

export function Gmo() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M2326 4758 c-5 -26 -26 -326 -26 -375 l0 -33 54 0 53 0 7 77 c3 42 6
109 6 149 0 81 14 107 23 41 3 -23 18 -93 32 -155 l27 -112 47 0 48 0 49 159
49 159 5 -162 5 -161 58 -3 57 -3 0 124 c0 68 -3 165 -6 215 l-7 92 -83 0 -83
0 -37 -117 c-19 -65 -37 -122 -39 -128 -4 -14 -10 5 -40 138 l-27 117 -84 0
c-79 0 -84 -1 -88 -22z"/>
                <path d="M2025 4713 c-82 -11 -159 -46 -211 -97 -59 -58 -80 -112 -68 -180 22
-134 127 -203 267 -177 55 11 207 75 207 88 0 14 -64 209 -70 217 -11 12 -170
-43 -170 -58 0 -7 5 -27 11 -45 11 -32 15 -33 66 -14 15 6 28 -56 15 -69 -19
-19 -106 -22 -140 -4 -66 34 -82 122 -32 179 29 34 113 67 168 67 19 0 22 5
22 44 0 31 -5 46 -16 50 -9 3 -16 5 -17 5 -1 -1 -15 -4 -32 -6z"/>
                <path d="M3075 4671 c-139 -63 -179 -247 -77 -354 50 -53 98 -71 189 -72 74 0
85 3 124 30 51 36 90 106 97 174 9 96 -35 166 -133 212 -71 34 -140 38 -200
10z m176 -110 c24 -24 29 -38 29 -78 0 -49 -25 -104 -59 -130 -9 -7 -35 -13
-57 -13 -61 0 -94 36 -94 102 0 122 107 192 181 119z"/>
                <path d="M1477 4310 c-184 -107 -417 -318 -555 -502 -210 -280 -337 -578 -399
-933 -24 -140 -24 -490 0 -630 79 -453 270 -828 582 -1140 109 -110 282 -248
369 -294 40 -22 48 -24 70 -11 29 15 40 41 31 70 -3 11 -52 51 -108 89 -526
359 -847 965 -847 1601 0 637 326 1252 850 1602 58 39 113 77 123 86 24 22 22
67 -5 86 -31 22 -35 21 -111 -24z"/>
                <path d="M3532 4334 c-27 -19 -29 -64 -4 -87 9 -8 58 -42 107 -74 590 -387
926 -1097 854 -1804 -59 -583 -371 -1101 -861 -1428 -42 -28 -80 -60 -83 -71
-9 -29 2 -55 31 -70 22 -13 30 -11 70 11 83 45 258 183 360 284 311 310 505
680 586 1125 18 95 21 154 21 340 0 186 -3 245 -21 340 -79 434 -273 809 -572
1110 -119 120 -261 232 -377 300 -76 45 -80 46 -111 24z"/>
                <path d="M2218 3800 c-58 -31 -83 -74 -83 -146 0 -50 5 -66 27 -96 15 -19 41
-41 58 -48 l30 -13 -2 -350 -3 -351 -61 -35 c-241 -140 -389 -375 -402 -641
-11 -229 67 -429 228 -590 163 -163 361 -239 590 -227 127 7 199 25 305 78
136 67 259 183 335 314 16 28 44 93 62 145 31 90 32 102 33 240 0 136 -2 150
-31 231 -72 202 -192 348 -369 451 l-60 34 -3 351 -2 350 30 13 c17 7 43 29
58 48 22 30 27 46 27 96 0 73 -25 115 -85 146 -37 19 -60 20 -342 20 -280 -1
-306 -2 -340 -20z m640 -112 c7 -7 12 -21 12 -33 0 -44 -10 -45 -310 -45 -300
0 -310 1 -310 45 0 44 10 45 310 45 209 0 289 -3 298 -12z m-108 -553 c0 -389
0 -389 59 -436 16 -13 57 -40 92 -59 34 -19 86 -59 116 -89 51 -49 133 -163
133 -183 0 -4 -265 -8 -590 -8 -324 0 -590 4 -590 8 0 5 18 37 41 72 51 79
133 158 210 201 34 19 74 45 90 58 59 47 59 47 59 436 l0 355 190 0 190 0 0
-355z m453 -925 c34 -165 -1 -346 -92 -486 -81 -126 -209 -223 -356 -271 -68
-22 -98 -26 -195 -26 -97 0 -127 4 -195 26 -324 106 -514 428 -448 757 l6 30
637 0 638 0 5 -30z"/>
                <path d="M2230 2159 c-62 -25 -100 -85 -100 -157 0 -123 129 -199 239 -140 51
27 91 91 91 143 0 49 -41 117 -86 142 -42 24 -101 29 -144 12z m100 -129 c15
-29 3 -59 -26 -66 -30 -8 -54 11 -54 40 0 46 59 64 80 26z"/>
                <path d="M2746 2148 c-45 -26 -86 -94 -86 -143 0 -91 90 -171 183 -162 154 14
202 206 75 300 -42 31 -123 33 -172 5z m112 -110 c17 -17 15 -53 -3 -68 -20
-17 -62 -5 -70 20 -14 44 41 80 73 48z"/>
                <path d="M2481 1859 c-100 -61 -114 -178 -32 -260 65 -65 157 -65 222 0 46 46
62 99 48 156 -26 103 -150 158 -238 104z m113 -115 c9 -8 16 -19 16 -22 0 -18
-33 -52 -50 -52 -17 0 -50 34 -50 52 0 14 32 38 50 38 10 0 26 -7 34 -16z"/>
                <path d="M1812 912 c-3 -9 -36 -100 -73 -202 l-67 -185 47 -17 c25 -10 51 -18
57 -18 6 0 22 28 34 63 13 34 27 66 31 71 7 8 45 -3 147 -41 13 -6 46 62 39
81 -3 6 -37 22 -76 36 -73 25 -78 31 -62 73 l10 25 63 -23 c113 -40 105 -40
123 1 8 20 15 40 15 44 0 9 -2 10 -158 66 -111 40 -123 42 -130 26z"/>
                <path d="M3161 880 c-74 -27 -135 -51 -137 -53 -3 -3 46 -144 130 -373 l11
-32 140 50 c77 28 144 53 148 58 4 4 0 26 -9 49 -13 32 -21 41 -33 36 -33 -14
-155 -55 -162 -55 -7 0 -32 61 -27 65 2 1 32 12 68 24 94 31 94 31 79 80 -7
22 -15 41 -18 41 -3 0 -41 -12 -84 -26 -43 -14 -80 -24 -82 -23 -2 2 -6 16
-10 30 -6 25 -3 28 72 54 43 16 81 31 85 35 8 6 -21 90 -31 89 -3 0 -66 -22
-140 -49z"/>
                <path d="M2780 789 c-74 -9 -140 -18 -146 -18 -10 -1 33 -410 45 -422 6 -7
300 26 308 34 2 2 1 23 -3 47 l-7 42 -86 -7 c-47 -4 -90 -8 -96 -8 -5 -1 -11
14 -13 35 l-3 36 74 7 c41 4 79 10 85 14 6 4 8 23 5 49 l-6 43 -76 -6 c-42 -4
-80 -8 -86 -9 -5 0 -11 13 -13 30 l-3 31 87 11 c48 7 89 14 91 16 7 6 -8 96
-16 95 -3 -1 -67 -10 -141 -20z"/>
                <path d="M2190 799 c-23 -186 -47 -415 -44 -419 3 -2 29 -7 59 -10 l54 -6 6
56 c10 92 13 100 39 100 34 0 52 -27 66 -101 12 -66 12 -67 48 -73 21 -3 49
-6 63 -6 l26 0 -15 77 c-8 43 -23 90 -34 105 -11 16 -15 28 -9 28 5 0 23 14
40 31 25 25 31 39 31 75 0 25 -7 55 -15 68 -26 40 -102 65 -214 72 -56 4 -101
5 -101 3z m198 -112 c40 -32 -11 -94 -68 -82 -31 7 -38 29 -24 83 6 20 11 22
43 16 20 -4 42 -11 49 -17z"/>
            </g>
        </svg>
    )
}

export function Flavor() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M2385 4743 c-27 -1 -69 -5 -92 -9 -42 -5 -43 -6 -43 -45 l0 -39 54 0
54 0 6 -47 c3 -27 8 -92 12 -146 6 -95 7 -99 28 -93 11 3 36 6 54 6 27 0 32 3
28 18 -2 9 -7 74 -11 143 l-7 126 51 7 51 7 0 39 0 40 -67 -2 c-38 -1 -90 -3
-118 -5z"/>
                <path d="M2688 4558 c-9 -101 -15 -184 -13 -186 1 -1 25 -5 52 -9 l50 -5 12
138 c7 77 15 163 18 192 l6 52 -55 0 -54 0 -16 -182z"/>
                <path d="M2921 4534 c-24 -98 -40 -179 -35 -183 5 -5 28 -13 51 -18 l41 -10
17 68 c10 38 20 69 24 69 3 0 31 -7 61 -15 70 -19 78 -19 84 3 2 9 7 27 11 38
5 20 -1 23 -65 39 -71 17 -80 27 -64 68 4 13 16 12 81 -5 41 -10 77 -17 79
-15 2 2 5 21 7 42 l2 38 -110 28 c-60 16 -117 29 -125 29 -12 0 -26 -43 -59
-176z"/>
                <path d="M1908 4654 c-43 -15 -78 -31 -78 -38 0 -6 21 -86 47 -178 50 -181 49
-179 112 -157 35 13 36 18 16 85 -19 62 -19 61 6 67 33 9 47 -2 84 -58 l34
-53 43 10 c24 6 47 14 51 18 11 10 -58 116 -89 136 -14 10 -21 20 -16 23 36
24 41 95 8 135 -33 42 -112 46 -218 10z m136 -76 c14 -23 -1 -48 -38 -67 -34
-16 -45 -7 -53 41 -4 30 -2 37 13 41 34 9 67 2 78 -15z"/>
                <path d="M3295 4433 c-37 -87 -69 -164 -72 -171 -3 -7 13 -20 42 -33 26 -11
48 -19 50 -17 4 5 145 336 145 341 0 6 -76 37 -89 37 -5 0 -39 -71 -76 -157z"/>
                <path d="M1520 4488 c-19 -12 -45 -27 -56 -34 l-21 -13 37 -186 c20 -102 39
-188 42 -190 3 -3 26 6 51 20 44 25 45 27 36 58 -15 52 -11 61 36 86 l46 24
33 -32 34 -33 53 28 54 27 -51 46 c-28 25 -94 85 -146 134 -53 48 -99 87 -104
86 -5 0 -25 -10 -44 -21z m89 -190 c-16 -10 -31 -18 -34 -18 -4 0 -16 42 -31
105 -6 29 -5 29 44 -19 l51 -50 -30 -18z"/>
                <path d="M3575 4408 c-35 -19 -76 -62 -96 -100 -49 -95 4 -209 126 -270 62
-32 70 -31 84 8 7 19 11 37 9 38 -1 1 -24 15 -50 30 -48 27 -78 68 -78 106 0
54 57 110 111 110 27 0 105 -44 117 -66 8 -14 14 -12 43 14 l33 30 -34 32
c-72 68 -204 102 -265 68z"/>
                <path d="M3856 4083 l-125 -138 34 -32 c19 -18 39 -33 44 -33 5 0 64 61 131
135 132 147 130 142 69 185 l-27 20 -126 -137z"/>
                <path d="M987 4081 c-116 -74 -140 -210 -54 -308 35 -39 97 -72 137 -73 87 0
178 71 204 161 51 171 -136 315 -287 220z m137 -90 c60 -40 82 -104 50 -149
-38 -54 -109 -48 -164 14 -78 90 15 200 114 135z"/>
                <path d="M4057 3902 c-98 -37 -179 -71 -180 -74 -1 -4 11 -24 28 -46 l29 -39
44 18 c49 21 42 25 92 -42 19 -26 19 -26 -5 -62 -14 -20 -25 -40 -25 -44 0
-12 67 -89 73 -83 14 16 207 318 207 324 0 6 -80 117 -84 116 0 -1 -81 -31
-179 -68z m156 -45 c-33 -51 -67 -97 -73 -97 -9 0 -43 50 -38 55 4 4 111 55
116 55 2 0 -1 -6 -5 -13z"/>
                <path d="M1930 3806 c-124 -33 -226 -118 -281 -233 -28 -58 -34 -82 -37 -159
-7 -160 37 -251 207 -418 79 -79 80 -80 65 -107 -8 -15 -14 -51 -14 -78 0 -42
5 -56 31 -86 58 -65 149 -71 215 -13 l35 31 297 -295 c266 -264 302 -296 342
-306 74 -19 396 -53 426 -46 74 18 114 73 114 155 0 110 -36 371 -58 419 -9
20 -140 160 -301 322 l-285 288 22 23 c33 35 54 94 46 131 -21 103 -114 151
-211 111 l-36 -15 -106 103 c-58 57 -126 116 -151 130 -90 52 -222 70 -320 43z
m242 -139 c34 -17 88 -62 153 -127 l100 -100 -215 -215 c-118 -118 -220 -215
-226 -215 -6 0 -57 47 -112 104 -123 128 -146 178 -140 297 4 61 10 87 35 130
35 62 107 123 173 144 66 22 167 14 232 -18z m462 -244 c5 -13 -68 -92 -301
-325 -174 -174 -314 -307 -323 -306 -47 9 -18 45 281 344 167 167 311 304 320
304 9 0 20 -8 23 -17z m252 -515 c155 -156 284 -293 287 -305 l5 -23 -347 0
-346 0 -125 125 -125 125 180 180 c99 99 182 180 185 180 3 0 132 -127 286
-282z m307 -477 c13 -66 19 -208 9 -215 -9 -6 -330 28 -378 40 -11 3 -65 50
-120 105 l-99 99 291 0 292 0 5 -29z"/>
                <path d="M692 3709 c-13 -22 -21 -43 -18 -46 3 -3 51 -29 105 -57 l100 -52
-134 4 -134 4 -31 -62 c-17 -34 -30 -63 -28 -64 2 -2 53 -29 113 -61 61 -32
133 -71 162 -87 l52 -29 21 42 c12 23 18 45 13 49 -4 5 -55 33 -113 64 l-105
55 150 3 149 3 23 40 c12 22 23 45 23 51 0 7 -259 155 -320 181 -3 2 -15 -16
-28 -38z"/>
                <path d="M4303 3540 c-90 -43 -163 -81 -163 -84 0 -6 109 -227 115 -234 5 -6
75 32 75 40 0 5 -13 35 -30 68 -16 33 -30 63 -30 68 0 5 55 35 123 67 67 32
124 60 126 62 7 5 -37 93 -46 92 -5 0 -81 -36 -170 -79z"/>
                <path d="M587 3097 c-21 -14 -40 -83 -64 -227 -24 -143 -24 -482 0 -625 52
-302 163 -583 325 -826 100 -148 137 -189 172 -189 30 0 60 31 60 60 -1 8 -34
60 -75 115 -186 250 -306 533 -362 855 -27 150 -23 479 7 635 12 66 25 132 27
147 9 49 -50 85 -90 55z"/>
                <path d="M4459 3089 c-16 -16 -20 -29 -15 -53 36 -193 47 -272 52 -391 21
-447 -112 -876 -385 -1246 -39 -53 -71 -103 -71 -111 0 -28 31 -58 60 -58 35
0 72 41 172 189 239 356 361 784 345 1211 -6 142 -31 330 -57 422 -17 58 -63
75 -101 37z"/>
                <path d="M3206 1887 c-87 -92 -166 -259 -166 -350 0 -141 122 -250 262 -234
167 20 252 183 183 351 -34 84 -114 213 -153 247 -47 41 -77 38 -126 -14z
m138 -218 c66 -129 62 -195 -14 -234 -80 -41 -170 15 -170 104 0 42 29 115 74
184 45 71 46 70 110 -54z"/>
                <path d="M1245 1043 l-110 -145 40 -34 40 -34 25 32 c14 18 34 43 46 57 l21
24 52 -41 c29 -23 57 -42 61 -42 8 0 50 53 50 62 0 3 -25 22 -55 44 -30 21
-55 42 -55 45 0 4 8 17 19 30 l18 23 62 -49 62 -49 24 30 c13 16 24 32 25 35
0 8 -197 159 -207 159 -4 -1 -57 -66 -118 -147z"/>
                <path d="M3708 1159 c-92 -48 -140 -131 -113 -195 27 -65 74 -76 187 -44 93
27 87 -33 -8 -77 -30 -13 -54 -26 -54 -28 0 -3 8 -20 17 -40 19 -40 29 -40
100 1 85 49 129 132 103 195 -26 62 -92 77 -192 43 -40 -13 -52 -14 -58 -4
-13 21 0 39 49 67 25 15 48 28 49 29 9 6 -21 74 -31 74 -7 -1 -29 -10 -49 -21z"/>
                <path d="M1562 825 c-45 -91 -82 -167 -82 -170 0 -6 229 -115 240 -115 8 0 30
46 30 63 0 7 -29 27 -65 45 -36 17 -65 36 -65 42 0 6 27 65 61 131 33 67 59
122 57 124 -5 4 -87 45 -90 45 -2 0 -40 -74 -86 -165z"/>
                <path d="M3369 940 c-42 -12 -151 -73 -156 -86 -4 -12 129 -308 147 -327 9
-10 90 29 90 44 0 5 -12 33 -26 61 -25 49 -25 52 -8 65 26 19 30 18 89 -38
l53 -50 47 23 47 24 -48 53 c-26 29 -58 57 -72 62 -18 7 -22 14 -16 27 4 9 9
34 12 54 3 32 -1 42 -26 68 -32 31 -75 38 -133 20z m61 -99 c5 -11 7 -27 4
-36 -6 -15 -62 -51 -69 -43 -6 7 -35 67 -35 73 0 8 42 24 67 24 12 1 27 -8 33
-18z"/>
                <path d="M1941 656 c-56 -90 -101 -166 -101 -169 0 -6 100 -31 104 -26 1 2 13
21 25 41 20 33 26 37 49 31 80 -19 77 -16 82 -68 l5 -48 50 -13 c28 -7 52 -10
54 -8 5 4 -17 326 -25 365 -4 23 -13 29 -69 43 -35 9 -66 16 -69 16 -2 0 -50
-74 -105 -164z m158 -28 c1 -23 -3 -28 -21 -28 -48 0 -51 13 -21 74 l28 58 7
-39 c4 -21 7 -50 7 -65z"/>
                <path d="M2910 788 c-114 -32 -172 -163 -121 -273 38 -85 121 -125 216 -105
164 34 228 206 122 327 -52 59 -126 77 -217 51z m117 -101 c72 -65 41 -197
-46 -197 -102 0 -139 176 -46 216 35 15 61 10 92 -19z"/>
                <path d="M2310 746 c0 -6 26 -92 58 -191 l58 -180 64 0 64 0 72 182 71 182
-49 6 c-28 4 -53 3 -58 -1 -4 -5 -27 -67 -51 -137 -34 -100 -45 -123 -50 -105
-4 13 -19 70 -34 128 -16 58 -31 108 -34 111 -12 12 -111 17 -111 5z"/>
            </g>
        </svg>
    )
}

export function Color() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M2385 4743 c-27 -1 -69 -5 -92 -9 -42 -5 -43 -6 -43 -45 l0 -39 54 0
54 0 6 -47 c3 -27 8 -92 12 -146 6 -95 7 -99 28 -93 11 3 36 6 54 6 27 0 32 3
28 18 -2 9 -7 74 -11 143 l-7 126 51 7 51 7 0 39 0 40 -67 -2 c-38 -1 -90 -3
-118 -5z"/>
                <path d="M2688 4558 c-9 -101 -15 -184 -13 -186 1 -1 25 -5 52 -9 l50 -5 12
138 c7 77 15 163 18 192 l6 52 -55 0 -54 0 -16 -182z"/>
                <path d="M2921 4534 c-24 -98 -40 -179 -35 -183 5 -5 28 -13 51 -18 l41 -10
17 68 c10 38 20 69 24 69 3 0 31 -7 61 -15 70 -19 78 -19 84 3 2 9 7 27 11 38
5 20 -1 23 -65 39 -71 17 -80 27 -64 68 4 13 16 12 81 -5 41 -10 77 -17 79
-15 2 2 5 21 7 42 l2 38 -110 28 c-60 16 -117 29 -125 29 -12 0 -26 -43 -59
-176z"/>
                <path d="M1908 4654 c-43 -15 -78 -31 -78 -38 0 -6 21 -86 47 -178 50 -181 49
-179 112 -157 35 13 36 18 16 85 -19 62 -19 61 6 67 33 9 47 -2 84 -58 l34
-53 43 10 c24 6 47 14 51 18 11 10 -58 116 -89 136 -14 10 -21 20 -16 23 36
24 41 95 8 135 -33 42 -112 46 -218 10z m136 -76 c14 -23 -1 -48 -38 -67 -34
-16 -45 -7 -53 41 -4 30 -2 37 13 41 34 9 67 2 78 -15z"/>
                <path d="M3295 4433 c-37 -87 -69 -164 -72 -171 -3 -7 13 -20 42 -33 26 -11
48 -19 50 -17 4 5 145 336 145 341 0 6 -76 37 -89 37 -5 0 -39 -71 -76 -157z"/>
                <path d="M1520 4488 c-19 -12 -45 -27 -56 -34 l-21 -13 37 -186 c20 -102 39
-188 42 -190 3 -3 26 6 51 20 44 25 45 27 36 58 -15 52 -11 61 36 86 l46 24
33 -32 34 -33 53 28 54 27 -51 46 c-28 25 -94 85 -146 134 -53 48 -99 87 -104
86 -5 0 -25 -10 -44 -21z m89 -190 c-16 -10 -31 -18 -34 -18 -4 0 -16 42 -31
105 -6 29 -5 29 44 -19 l51 -50 -30 -18z"/>
                <path d="M3575 4408 c-35 -19 -76 -62 -96 -100 -49 -95 4 -209 126 -270 62
-32 70 -31 84 8 7 19 11 37 9 38 -1 1 -24 15 -50 30 -48 27 -78 68 -78 106 0
54 57 110 111 110 27 0 105 -44 117 -66 8 -14 14 -12 43 14 l33 30 -34 32
c-72 68 -204 102 -265 68z"/>
                <path d="M3856 4083 l-125 -138 34 -32 c19 -18 39 -33 44 -33 5 0 64 61 131
135 132 147 130 142 69 185 l-27 20 -126 -137z"/>
                <path d="M987 4081 c-116 -74 -140 -210 -54 -308 35 -39 97 -72 137 -73 87 0
178 71 204 161 51 171 -136 315 -287 220z m137 -90 c60 -40 82 -104 50 -149
-38 -54 -109 -48 -164 14 -78 90 15 200 114 135z"/>
                <path d="M4057 3902 c-98 -37 -179 -71 -180 -74 -1 -4 11 -24 28 -46 l29 -39
44 18 c49 21 42 25 92 -42 19 -26 19 -26 -5 -62 -14 -20 -25 -40 -25 -44 0
-12 67 -89 73 -83 14 16 207 318 207 324 0 6 -80 117 -84 116 0 -1 -81 -31
-179 -68z m156 -45 c-33 -51 -67 -97 -73 -97 -9 0 -43 50 -38 55 4 4 111 55
116 55 2 0 -1 -6 -5 -13z"/>
                <path d="M1930 3806 c-124 -33 -226 -118 -281 -233 -28 -58 -34 -82 -37 -159
-7 -160 37 -251 207 -418 79 -79 80 -80 65 -107 -8 -15 -14 -51 -14 -78 0 -42
5 -56 31 -86 58 -65 149 -71 215 -13 l35 31 297 -295 c266 -264 302 -296 342
-306 74 -19 396 -53 426 -46 74 18 114 73 114 155 0 110 -36 371 -58 419 -9
20 -140 160 -301 322 l-285 288 22 23 c33 35 54 94 46 131 -21 103 -114 151
-211 111 l-36 -15 -106 103 c-58 57 -126 116 -151 130 -90 52 -222 70 -320 43z
m242 -139 c34 -17 88 -62 153 -127 l100 -100 -215 -215 c-118 -118 -220 -215
-226 -215 -6 0 -57 47 -112 104 -123 128 -146 178 -140 297 4 61 10 87 35 130
35 62 107 123 173 144 66 22 167 14 232 -18z m462 -244 c5 -13 -68 -92 -301
-325 -174 -174 -314 -307 -323 -306 -47 9 -18 45 281 344 167 167 311 304 320
304 9 0 20 -8 23 -17z m252 -515 c155 -156 284 -293 287 -305 l5 -23 -347 0
-346 0 -125 125 -125 125 180 180 c99 99 182 180 185 180 3 0 132 -127 286
-282z m307 -477 c13 -66 19 -208 9 -215 -9 -6 -330 28 -378 40 -11 3 -65 50
-120 105 l-99 99 291 0 292 0 5 -29z"/>
                <path d="M692 3709 c-13 -22 -21 -43 -18 -46 3 -3 51 -29 105 -57 l100 -52
-134 4 -134 4 -31 -62 c-17 -34 -30 -63 -28 -64 2 -2 53 -29 113 -61 61 -32
133 -71 162 -87 l52 -29 21 42 c12 23 18 45 13 49 -4 5 -55 33 -113 64 l-105
55 150 3 149 3 23 40 c12 22 23 45 23 51 0 7 -259 155 -320 181 -3 2 -15 -16
-28 -38z"/>
                <path d="M4303 3540 c-90 -43 -163 -81 -163 -84 0 -6 109 -227 115 -234 5 -6
75 32 75 40 0 5 -13 35 -30 68 -16 33 -30 63 -30 68 0 5 55 35 123 67 67 32
124 60 126 62 7 5 -37 93 -46 92 -5 0 -81 -36 -170 -79z"/>
                <path d="M587 3097 c-21 -14 -40 -83 -64 -227 -24 -143 -24 -482 0 -625 52
-302 163 -583 325 -826 100 -148 137 -189 172 -189 30 0 60 31 60 60 -1 8 -34
60 -75 115 -186 250 -306 533 -362 855 -27 150 -23 479 7 635 12 66 25 132 27
147 9 49 -50 85 -90 55z"/>
                <path d="M4459 3089 c-16 -16 -20 -29 -15 -53 36 -193 47 -272 52 -391 21
-447 -112 -876 -385 -1246 -39 -53 -71 -103 -71 -111 0 -28 31 -58 60 -58 35
0 72 41 172 189 239 356 361 784 345 1211 -6 142 -31 330 -57 422 -17 58 -63
75 -101 37z"/>
                <path d="M3206 1887 c-87 -92 -166 -259 -166 -350 0 -141 122 -250 262 -234
167 20 252 183 183 351 -34 84 -114 213 -153 247 -47 41 -77 38 -126 -14z
m138 -218 c66 -129 62 -195 -14 -234 -80 -41 -170 15 -170 104 0 42 29 115 74
184 45 71 46 70 110 -54z"/>
                <path d="M3560 1052 c-91 -46 -130 -93 -130 -156 0 -28 7 -45 31 -68 29 -30
32 -31 97 -24 123 12 117 13 120 -12 3 -18 -6 -27 -50 -47 -29 -14 -59 -25
-65 -25 -16 0 -16 -27 0 -62 l12 -27 52 18 c71 24 122 66 145 118 18 41 18 49
6 81 -8 22 -27 44 -47 56 -31 18 -39 19 -109 7 -45 -8 -78 -9 -83 -4 -18 18 3
42 57 64 37 16 53 27 49 37 -3 8 -8 25 -11 38 -7 28 -26 30 -74 6z"/>
                <path d="M1455 997 c-55 -31 -97 -102 -97 -165 0 -59 18 -97 71 -145 43 -39
81 -61 122 -70 22 -6 27 -2 38 30 13 36 6 52 -25 53 -6 0 -29 16 -52 36 -37
32 -42 40 -42 79 0 55 36 101 86 111 41 8 68 -3 116 -45 l35 -30 28 29 29 30
-29 30 c-74 77 -199 103 -280 57z"/>
                <path d="M3126 840 c-38 -16 -74 -34 -79 -39 -8 -8 95 -323 113 -344 5 -6 74
14 97 27 1 1 -9 31 -22 68 l-24 66 22 6 c39 10 46 7 87 -48 l40 -55 45 16 c25
8 47 18 49 22 8 12 -84 123 -105 128 -23 6 -23 12 -4 37 23 31 18 80 -11 115
-23 28 -31 31 -82 31 -40 0 -77 -9 -126 -30z m118 -56 c10 -4 16 -18 16 -35 0
-21 -7 -32 -30 -44 -39 -20 -47 -19 -54 9 -3 13 -9 31 -12 40 -9 23 45 43 80
30z"/>
                <path d="M1845 842 c-73 -35 -115 -103 -115 -187 0 -94 63 -163 169 -186 116
-25 210 39 236 159 24 112 -67 217 -199 228 -37 4 -62 0 -91 -14z m153 -94
c31 -35 37 -72 17 -123 -20 -53 -47 -75 -93 -75 -70 0 -106 85 -67 160 32 61
103 80 143 38z"/>
                <path d="M2259 764 c0 -5 -8 -89 -18 -188 -12 -122 -14 -179 -7 -182 18 -5
248 -26 251 -22 2 2 7 22 11 44 l6 41 -82 7 -83 8 7 71 c21 222 21 217 -1 217
-11 0 -35 3 -52 6 -17 4 -31 3 -32 -2z"/>
                <path d="M2707 755 c-85 -30 -131 -104 -125 -201 3 -52 9 -70 35 -101 45 -56
85 -76 153 -76 168 2 267 152 194 294 -42 82 -158 120 -257 84z m131 -99 c47
-45 46 -148 -1 -181 -31 -21 -79 -19 -106 6 -61 57 -53 166 14 195 36 16 62
10 93 -20z"/>
            </g>
        </svg>
    )
}

export function Sugar() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M2539 4778 c0 -2 -9 -95 -18 -208 -13 -146 -15 -207 -7 -212 18 -12
135 -21 206 -16 163 12 254 122 226 272 -14 72 -90 133 -194 155 -45 9 -211
16 -213 9z m223 -114 c39 -20 58 -53 58 -99 0 -81 -57 -135 -141 -135 -27 0
-49 3 -49 8 0 35 21 236 25 243 7 10 73 0 107 -17z"/>
                <path d="M2126 4734 c-38 -7 -71 -15 -73 -18 -7 -7 -56 -436 -51 -442 3 -2 30
1 61 7 l56 12 7 53 c7 58 10 60 82 70 l48 6 19 -41 c10 -23 22 -44 27 -47 10
-6 128 16 128 25 0 14 -216 385 -225 387 -6 1 -41 -4 -79 -12z m77 -224 c-4
-4 -23 -10 -40 -13 -38 -7 -37 -9 -26 93 l6 63 34 -68 c19 -37 31 -71 26 -75z"/>
                <path d="M3100 4562 c-29 -75 -63 -165 -76 -199 l-23 -61 87 -37 c198 -84 341
-49 397 99 48 125 -32 239 -210 299 -134 45 -115 56 -175 -101z m200 -7 c89
-46 92 -164 5 -221 -28 -18 -127 -16 -147 4 -7 7 1 41 32 121 46 120 53 126
110 96z"/>
                <path d="M1453 4469 c-24 -5 -63 -20 -88 -35 -117 -68 -151 -199 -81 -317 65
-111 197 -139 309 -65 76 51 110 105 115 182 9 145 -115 259 -255 235z m73
-125 c33 -22 64 -84 64 -129 0 -27 -8 -44 -29 -66 -72 -71 -193 1 -195 116 -2
88 82 130 160 79z"/>
                <path d="M3591 4279 c-67 -93 -121 -172 -121 -174 0 -2 37 -29 83 -61 45 -32
103 -73 128 -91 l46 -33 28 41 c15 23 23 44 18 48 -6 3 -42 28 -81 55 l-71 49
20 29 c11 15 23 28 25 28 3 0 34 -20 68 -45 35 -25 67 -45 72 -45 8 0 54 63
54 73 0 2 -31 25 -69 52 -69 47 -70 48 -54 71 8 13 19 24 24 24 5 0 40 -22 79
-50 l69 -49 27 34 c14 19 24 39 22 44 -3 11 -228 171 -239 171 -4 0 -62 -77
-128 -171z"/>
                <path d="M981 4121 l-31 -39 82 -70 c46 -39 94 -80 108 -91 l25 -21 -45 15
c-25 8 -94 26 -153 39 l-108 25 -37 -41 c-42 -47 -66 -78 -61 -82 2 -1 75 -64
162 -140 l158 -138 34 39 c19 21 34 41 35 45 0 4 -49 49 -109 100 -60 51 -108
94 -107 95 1 2 45 -8 97 -21 52 -12 125 -29 163 -37 l68 -13 39 44 c21 25 37
50 35 55 -4 15 -299 274 -313 275 -6 0 -25 -18 -42 -39z"/>
                <path d="M3953 4014 c-81 -69 -152 -130 -157 -134 -10 -10 66 -112 123 -162
45 -42 100 -72 148 -83 53 -11 132 18 180 66 72 70 81 148 29 251 -24 50 -146
188 -165 188 -5 0 -76 -57 -158 -126z m229 -92 c24 -53 13 -98 -33 -137 -40
-33 -72 -40 -118 -27 -38 12 -96 63 -90 80 2 5 44 44 93 86 87 74 90 75 109
58 11 -9 29 -36 39 -60z"/>
                <path d="M2025 3614 c-110 -48 -207 -92 -216 -100 -8 -7 -75 -164 -149 -348
-128 -323 -133 -336 -117 -359 12 -19 92 -55 344 -156 180 -72 336 -131 345
-131 21 0 417 169 439 186 8 8 75 164 149 349 115 289 132 338 123 358 -9 20
-70 48 -340 156 -180 72 -339 131 -353 131 -14 -1 -115 -39 -225 -86z m466
-130 l241 -97 -123 -52 -123 -53 -46 18 c-258 102 -430 173 -427 176 2 2 210
94 234 103 2 1 111 -42 244 -95z m-333 -201 c133 -53 242 -102 242 -108 0 -11
-196 -504 -204 -512 -4 -4 -469 177 -507 198 -16 8 -8 33 78 246 52 131 99
249 104 262 7 16 14 21 27 17 9 -4 126 -50 260 -103z m527 -245 c-54 -136
-101 -238 -111 -245 -11 -7 -67 -32 -126 -57 l-107 -45 16 42 c9 23 53 131 97
240 l81 199 110 48 c61 27 115 49 122 49 6 1 -31 -103 -82 -231z"/>
                <path d="M772 3578 c-20 -26 -105 -204 -138 -292 -43 -111 -88 -280 -111 -411
-24 -140 -24 -491 0 -630 78 -446 263 -814 567 -1126 101 -105 129 -118 163
-81 31 34 21 64 -39 122 -607 594 -765 1500 -393 2254 52 105 58 135 33 160
-19 19 -68 21 -82 4z"/>
                <path d="M4266 3575 c-26 -26 -19 -57 33 -162 264 -530 266 -1158 6 -1698 -90
-188 -210 -357 -377 -531 -83 -86 -95 -116 -60 -147 37 -34 61 -22 163 83 302
310 489 681 566 1125 24 139 24 490 0 630 -38 220 -110 437 -205 620 -43 82
-50 90 -78 93 -19 2 -38 -3 -48 -13z"/>
                <path d="M3145 2656 c-182 -45 -335 -85 -342 -89 -17 -10 -253 -402 -253 -420
0 -32 163 -684 176 -704 10 -15 24 -23 44 -23 54 0 694 160 711 178 16 17 209
331 236 384 12 24 6 56 -62 340 -97 399 -93 384 -111 403 -8 8 -27 15 -42 14
-15 -1 -176 -38 -357 -83z m222 -83 c-49 -89 -124 -206 -136 -212 -21 -11
-482 -124 -486 -119 -2 2 26 53 63 113 l67 110 225 56 c221 56 275 66 267 52z
m178 -309 l58 -241 -67 -112 c-38 -62 -70 -111 -72 -109 -2 3 -30 114 -63 248
l-59 245 66 112 c37 61 70 108 73 105 4 -4 33 -115 64 -248z m-287 -132 c13
-53 42 -176 66 -274 l43 -176 -274 -67 c-151 -37 -276 -65 -277 -63 -3 2 -73
288 -122 495 l-12 52 262 64 c143 36 268 65 276 66 11 1 21 -26 38 -97z"/>
                <path d="M3515 1046 c-46 -14 -207 -114 -201 -124 52 -95 209 -362 213 -362
13 0 93 53 93 62 0 6 -16 38 -37 71 -35 57 -35 61 -18 73 27 20 59 17 81 -7
10 -12 35 -34 55 -49 l35 -29 54 31 55 31 -60 54 c-33 29 -74 58 -91 65 -32
11 -32 11 -17 45 11 27 12 42 3 67 -23 70 -87 98 -165 72z m55 -112 c19 -22 2
-64 -36 -86 l-30 -18 -24 42 -23 41 23 19 c27 22 73 23 90 2z"/>
                <path d="M1481 1025 c-16 -14 -35 -41 -41 -59 -14 -43 4 -91 61 -158 39 -47
48 -71 28 -83 -19 -13 -57 5 -106 49 l-50 45 -33 -34 -34 -35 39 -40 c115
-118 282 -120 314 -4 13 48 0 86 -54 151 -48 59 -55 80 -29 90 20 8 53 -8 98
-48 l37 -32 29 28 c17 16 30 33 30 38 0 17 -65 70 -115 93 -69 33 -134 32
-174 -1z"/>
                <path d="M1866 883 c-80 -274 -85 -316 -46 -378 42 -69 180 -112 255 -80 78
32 93 57 146 238 l37 127 -55 16 c-30 9 -57 13 -60 11 -3 -3 -20 -57 -38 -120
-40 -139 -58 -175 -90 -183 -36 -9 -73 3 -86 28 -18 33 -12 84 25 208 l33 115
-31 11 c-68 24 -84 25 -90 7z"/>
                <path d="M3023 837 l-72 -20 -5 -41 c-3 -22 -8 -125 -11 -227 l-6 -186 60 16
61 16 0 55 0 56 61 16 61 16 26 -43 c15 -25 33 -45 40 -45 8 0 38 7 68 16 l54
15 -127 190 c-70 104 -130 188 -133 188 -3 -1 -37 -11 -77 -22z m65 -152 c23
-35 38 -66 34 -69 -4 -2 -24 -7 -45 -11 l-37 -7 0 76 c0 42 2 76 4 76 2 0 22
-29 44 -65z"/>
                <path d="M2507 769 c-110 -26 -177 -108 -177 -219 0 -139 114 -219 294 -207
45 3 93 10 109 16 l27 11 0 115 0 115 -95 0 -95 0 0 -45 c0 -45 0 -45 35 -45
33 0 35 -2 35 -35 0 -35 0 -35 -45 -35 -87 0 -135 43 -135 121 0 85 55 125
162 117 35 -3 70 -8 79 -12 12 -5 18 2 27 32 17 58 17 59 -38 71 -59 13 -129
13 -183 0z"/>
            </g>
        </svg>
    )
}

export function Preservative() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M2525 4569 c29 -94 58 -175 66 -180 8 -5 35 -9 60 -9 l47 0 66 163
c36 89 66 168 66 175 0 8 -16 12 -48 12 l-48 0 -43 -126 -44 -125 -28 110
c-41 165 -33 151 -93 151 l-53 0 52 -171z"/>
                <path d="M2175 4705 c-38 -8 -74 -16 -81 -19 -15 -6 37 -341 54 -340 7 1 28 2
48 3 l36 1 -7 58 c-4 31 -8 62 -8 67 -1 6 12 10 28 10 26 0 33 -7 59 -57 28
-55 29 -56 65 -51 63 8 62 8 37 64 -12 28 -33 62 -45 75 l-23 24 21 26 c30 38
27 80 -8 115 -16 16 -38 29 -48 29 -10 0 -27 2 -38 4 -11 3 -51 -2 -90 -9z
m103 -93 c5 -30 -12 -49 -47 -54 -26 -4 -30 -1 -35 30 -7 46 -1 54 42 50 30
-2 38 -7 40 -26z"/>
                <path d="M2987 4636 c-71 -112 -157 -253 -157 -258 0 -3 16 -9 36 -12 19 -4
41 -9 47 -12 7 -2 22 14 34 35 l22 39 48 -12 c48 -12 48 -12 51 -57 l3 -46 51
-12 c28 -6 52 -10 53 -9 2 2 -3 73 -11 158 -7 85 -13 167 -14 181 0 23 -6 27
-65 41 l-66 16 -32 -52z m83 -108 c0 -50 -4 -53 -47 -42 l-22 6 27 54 c30 61
42 56 42 -18z"/>
                <path d="M1825 4608 c-60 -22 -111 -41 -114 -43 -4 -5 97 -296 110 -317 5 -8
38 0 116 27 59 21 112 41 117 46 4 4 3 23 -3 42 l-12 35 -75 -28 -75 -28 -10
24 c-5 14 -9 27 -9 28 0 2 28 14 63 26 34 13 64 25 65 26 5 3 -18 74 -24 74
-2 0 -33 -11 -68 -24 -55 -21 -65 -22 -70 -9 -12 31 -4 40 59 62 68 24 76 36
52 77 -12 21 -12 21 -122 -18z"/>
                <path d="M3301 4569 c-13 -39 -10 -45 34 -59 19 -6 35 -15 35 -20 0 -4 -22
-58 -50 -120 -27 -62 -50 -115 -50 -119 0 -8 65 -41 79 -41 4 0 32 54 61 120
29 66 55 120 57 120 2 0 22 -9 44 -21 23 -11 42 -19 44 -17 1 2 10 17 19 35
16 32 16 33 -11 47 -52 26 -233 106 -242 106 -5 0 -14 -14 -20 -31z"/>
                <path d="M1527 4475 c-60 -21 -106 -57 -127 -100 -23 -48 -17 -85 17 -117 26
-24 21 -23 162 -15 69 4 36 -51 -45 -73 -33 -10 -43 -17 -40 -29 17 -62 17
-61 44 -55 51 12 111 46 142 80 41 47 42 109 3 149 -28 27 -29 27 -116 21 -82
-6 -88 -5 -85 11 2 10 26 29 55 43 47 22 52 28 46 50 -11 47 -15 49 -56 35z"/>
                <path d="M3601 4269 c-98 -149 -98 -149 -42 -182 l30 -18 24 39 c14 21 54 84
91 140 36 56 66 107 66 111 0 9 -61 51 -73 51 -3 0 -46 -63 -96 -141z"/>
                <path d="M3877 4283 c-3 -5 -35 -79 -71 -167 l-67 -159 28 -29 c52 -54 54 -54
171 8 59 31 131 70 160 86 l53 30 -23 25 c-44 47 -48 46 -163 -22 -60 -36
-110 -65 -111 -65 -2 0 22 52 52 116 l55 117 -32 33 c-33 35 -44 40 -52 27z"/>
                <path d="M1136 4204 c-48 -42 -86 -80 -84 -85 2 -4 52 -64 111 -134 l109 -125
50 41 c27 23 70 61 96 84 l46 42 -23 32 -23 31 -60 -51 -59 -52 -21 21 -21 20
38 34 c21 18 45 38 53 44 13 10 13 15 -7 43 -12 17 -25 31 -28 31 -3 0 -28
-20 -55 -45 -49 -44 -51 -45 -70 -28 -20 18 -19 19 38 70 l58 52 -24 26 c-13
14 -27 25 -30 25 -4 0 -46 -34 -94 -76z"/>
                <path d="M900 3956 c-31 -21 -113 -124 -122 -153 -4 -13 251 -215 265 -210 6
2 21 17 34 34 l24 32 -50 38 c-27 21 -50 41 -50 45 -1 4 10 14 23 23 21 14 28
14 81 -7 l57 -22 29 35 c16 19 29 37 29 40 0 13 -100 49 -135 49 -23 0 -35 5
-34 13 4 35 -3 60 -26 82 -31 32 -79 33 -125 1z m68 -78 c17 -17 15 -44 -4
-73 l-16 -25 -34 27 -35 26 27 29 c30 31 44 34 62 16z"/>
                <path d="M4102 3851 c-73 -54 -135 -101 -139 -104 -6 -7 137 -207 148 -207 4
0 20 10 34 23 l26 22 -47 63 c-44 60 -46 64 -28 77 28 21 30 20 71 -35 20 -27
42 -50 48 -50 5 0 21 9 34 19 l25 19 -43 57 c-40 53 -42 57 -24 70 10 8 21 15
24 15 3 0 23 -25 45 -55 21 -30 43 -55 47 -55 12 0 57 31 57 40 0 7 -139 200
-143 200 -1 0 -62 -44 -135 -99z"/>
                <path d="M2211 3802 c-59 -29 -76 -63 -76 -147 0 -85 17 -118 78 -148 l37 -19
0 -337 0 -337 -292 -464 c-304 -484 -337 -547 -338 -645 0 -63 24 -157 52
-204 53 -89 146 -160 243 -186 88 -23 1202 -23 1290 0 220 58 346 292 272 505
-15 43 -121 221 -315 530 l-292 465 0 336 0 337 37 19 c61 30 78 63 78 148 0
84 -17 118 -76 147 -30 16 -69 18 -349 18 -280 0 -319 -2 -349 -18z m654 -147
l0 -40 -305 0 -305 0 -3 28 c-7 60 -20 58 310 55 l303 -3 0 -40z m-115 -515
c0 -292 3 -356 15 -380 8 -16 67 -111 130 -212 l115 -183 -225 -3 c-124 -1
-326 -1 -450 0 l-225 3 115 183 c63 101 122 196 130 212 12 24 15 88 15 380
l0 350 190 0 190 0 0 -350z m469 -1109 c136 -216 161 -268 161 -330 0 -116
-85 -234 -190 -265 -80 -24 -1180 -24 -1260 0 -136 40 -224 212 -176 345 7 20
72 131 145 247 l133 212 528 0 528 0 131 -209z"/>
                <path d="M2494 2156 c-108 -47 -130 -182 -45 -267 65 -65 157 -65 222 0 46 46
62 99 48 156 -25 98 -133 151 -225 111z m100 -122 c9 -8 16 -19 16 -22 0 -18
-33 -52 -50 -52 -17 0 -50 34 -50 52 0 14 32 38 50 38 10 0 26 -7 34 -16z"/>
                <path d="M2175 1866 c-112 -51 -129 -206 -31 -281 137 -104 324 52 246 205
-11 21 -34 49 -51 61 -39 28 -121 35 -164 15z m103 -118 c7 -7 12 -21 12 -33
0 -12 -5 -26 -12 -33 -7 -7 -21 -12 -33 -12 -12 0 -26 5 -33 12 -7 7 -12 21
-12 33 0 12 5 26 12 33 7 7 21 12 33 12 12 0 26 -5 33 -12z"/>
                <path d="M2820 1873 c-36 -15 -72 -48 -90 -83 -59 -115 33 -249 162 -237 153
13 201 207 75 301 -26 20 -117 32 -147 19z m88 -125 c28 -28 7 -78 -33 -78
-27 0 -45 18 -45 45 0 27 18 45 45 45 12 0 26 -5 33 -12z"/>
                <path d="M705 3640 c-11 -4 -32 -21 -47 -36 -34 -37 -94 -163 -82 -174 15 -14
280 -150 292 -150 6 0 21 18 32 40 l20 40 -50 25 c-27 14 -50 29 -50 33 1 4 7
18 15 32 20 35 19 120 -1 149 -16 23 -71 52 -96 50 -7 0 -22 -4 -33 -9z m53
-102 c16 -16 15 -50 -1 -72 -12 -17 -15 -17 -50 0 -20 10 -37 21 -37 25 0 3 9
18 21 33 22 28 48 33 67 14z"/>
                <path d="M4339 3564 c-21 -23 -24 -35 -24 -107 0 -70 -3 -82 -18 -85 -19 -4
-56 65 -57 106 0 23 -15 27 -59 15 -21 -5 -22 -10 -17 -45 13 -81 76 -163 132
-174 37 -7 88 16 105 47 6 12 10 51 9 87 -3 89 -2 92 15 92 17 0 39 -40 50
-87 6 -30 9 -32 38 -26 18 3 35 9 40 12 15 14 -24 111 -62 151 -33 35 -43 40
-83 40 -35 0 -50 -6 -69 -26z"/>
                <path d="M596 3145 c-18 -18 -29 -55 -56 -185 -135 -656 79 -1366 555 -1845
135 -137 248 -225 285 -225 33 0 64 36 58 66 -2 10 -57 63 -123 119 -253 214
-438 472 -558 775 -139 350 -173 740 -96 1100 32 147 34 174 14 193 -21 21
-59 22 -79 2z"/>
                <path d="M4445 3143 c-20 -19 -18 -46 14 -193 115 -539 -15 -1110 -356 -1564
-78 -104 -218 -248 -335 -345 -49 -40 -88 -80 -88 -89 0 -32 29 -62 60 -62 37
0 150 89 285 225 477 480 689 1186 555 1845 -27 129 -38 167 -56 185 -20 20
-58 19 -79 -2z"/>
                <path d="M1812 912 c-3 -9 -36 -100 -73 -202 l-67 -185 47 -17 c25 -10 51 -18
57 -18 6 0 22 28 34 63 13 34 27 66 31 71 7 8 45 -3 147 -41 13 -6 46 62 39
81 -3 6 -37 22 -76 36 -73 25 -78 31 -62 73 l10 25 63 -23 c113 -40 105 -40
123 1 8 20 15 40 15 44 0 9 -2 10 -158 66 -111 40 -123 42 -130 26z"/>
                <path d="M3161 880 c-74 -27 -135 -51 -137 -53 -3 -3 46 -144 130 -373 l11
-32 140 50 c77 28 144 53 148 58 4 4 0 26 -9 49 -13 32 -21 41 -33 36 -33 -14
-155 -55 -162 -55 -7 0 -32 61 -27 65 2 1 32 12 68 24 94 31 94 31 79 80 -7
22 -15 41 -18 41 -3 0 -41 -12 -84 -26 -43 -14 -80 -24 -82 -23 -2 2 -6 16
-10 30 -6 25 -3 28 72 54 43 16 81 31 85 35 8 6 -21 90 -31 89 -3 0 -66 -22
-140 -49z"/>
                <path d="M2780 789 c-74 -9 -140 -18 -146 -18 -10 -1 33 -410 45 -422 6 -7
300 26 308 34 2 2 1 23 -3 47 l-7 42 -86 -7 c-47 -4 -90 -8 -96 -8 -5 -1 -11
14 -13 35 l-3 36 74 7 c41 4 79 10 85 14 6 4 8 23 5 49 l-6 43 -76 -6 c-42 -4
-80 -8 -86 -9 -5 0 -11 13 -13 30 l-3 31 87 11 c48 7 89 14 91 16 7 6 -8 96
-16 95 -3 -1 -67 -10 -141 -20z"/>
                <path d="M2190 799 c-23 -186 -47 -415 -44 -419 3 -2 29 -7 59 -10 l54 -6 6
56 c10 92 13 100 39 100 34 0 52 -27 66 -101 12 -66 12 -67 48 -73 21 -3 49
-6 63 -6 l26 0 -15 77 c-8 43 -23 90 -34 105 -11 16 -15 28 -9 28 5 0 23 14
40 31 25 25 31 39 31 75 0 25 -7 55 -15 68 -26 40 -102 65 -214 72 -56 4 -101
5 -101 3z m198 -112 c40 -32 -11 -94 -68 -82 -31 7 -38 29 -24 83 6 20 11 22
43 16 20 -4 42 -11 49 -17z"/>
            </g>
        </svg>
    )
}