import React from 'react'

export default function Mountains() {

	const mountains = {
		position: 'sticky', overflow: 'hidden', bottom: 0, zIndex: 99, width: '100%', maxHeight: '10rem'
	}


	return (
		<div style={mountains}>
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 595.3 74.6" style={{ marginBottom: '-5px' }}>
				<g>
					<g>
						<polygon fill="#b7b7b7" points="-16.2,55.9 -13.1,49.3 -7.2,44.2 -3.7,38.1 1.6,33.1 5.2,27.9 13.6,22.7 20.9,25 23.8,23.4 
					34.7,28.7 36.2,31.6 46.3,33.2 50.3,35.2 56.9,31.3 60.2,28.4 68.3,23.1 73.6,25.2 74.7,25 83.2,28 83.9,27.5 91,30.8 
					95.4,30.1 101.1,31.4 112.7,27.1 116.3,23.6 125.2,21 134.3,25.7 142.1,21 142.6,21 152.4,14.2 164.6,6.7 174.1,11.9 
					180.3,18.3 192,20.6 202.4,24.9 216.6,25.4 229.3,22.2 237.3,16.6 243.5,19.3 253.5,24.6 260.6,21.3 266.6,24.4 270.2,23.7 
					282.2,31.3 289.3,35.4 294,32.4 301.1,36.1 304.9,41.5 307.9,45.3 312,55.7 				" />
						<polygon fill="#b7b7b7" points="287.9,55.9 291,49.3 297,44.2 300.4,38.1 305.7,33.1 309.3,27.9 317.7,22.7 325,25 327.9,23.4 
					338.8,28.7 340.4,31.6 350.4,33.2 354.4,35.2 361,31.3 364.3,28.4 372.4,23.1 377.8,25.2 378.8,25 387.3,28 388.1,27.5 
					395.1,30.8 399.5,30.1 405.2,31.4 416.8,27.1 420.4,23.6 429.3,21 438.5,25.7 446.2,21 446.7,21 456.5,14.2 468.7,6.7 
					478.2,11.9 484.4,18.3 496.2,20.6 506.6,24.9 520.7,25.4 533.5,22.2 541.4,16.6 547.6,19.3 557.6,24.6 564.7,21.3 570.7,24.4 
					574.4,23.7 586.3,31.3 593.5,35.4 598.1,32.4 605.2,36.1 609.1,41.5 612.1,45.3 616.2,55.7 				" />
						<polygon fill="#757576" points="-15.5,55.7 -11.8,50.9 -6.5,47.9 -4,41.9 0.6,37.7 8.5,34.3 15,29.5 21.2,30.2 29.1,24.6 
					35.1,30.1 37.1,29.4 45.1,33.8 48.4,33.6 58.6,38.3 64,39 73.9,43.4 82.2,39.3 87.5,38 94.1,33.3 102,29.3 108.4,23.8 
					113.6,26.7 117.9,25.1 123.4,20.6 127.7,17.4 133.8,22.2 135.8,21.1 140.5,25.3 145.4,29.4 149.7,33 157.1,36 171.2,39.6 
					177.3,38.9 187.7,32 190.5,31.4 198.9,24.7 206.9,19.3 212.6,22.5 220.1,17.4 227.1,24.1 232.3,29.4 241.7,32.7 250,37.6 
					257,39.6 262.2,43.8 269.4,40.6 272.4,38.3 276.9,35.6 279.3,32.9 284.6,27.8 292.2,34.2 295.1,38 301.6,42.6 303.5,49 
					311.6,55.9 -15.5,55.9 				" />
						<polygon fill="#757576" points="288.6,55.7 292.4,50.9 297.6,47.9 300.1,41.9 304.7,37.7 312.6,34.3 319.2,29.5 325.3,30.2 
					333.2,24.6 339.2,30.1 341.2,29.4 349.2,33.8 352.6,33.6 362.7,38.3 368.1,39 378.1,43.4 386.3,39.3 391.6,38 398.3,33.3 
					406.2,29.3 412.6,23.8 417.7,26.7 422.1,25.1 427.5,20.6 431.8,17.4 437.9,22.2 439.9,21.1 444.6,25.3 449.5,29.4 453.8,33 
					461.2,36 475.4,39.6 481.4,38.9 491.8,32 494.6,31.4 503,24.7 511,19.3 516.7,22.5 524.2,17.4 531.3,24.1 536.4,29.4 
					545.8,32.7 554.1,37.6 561.1,39.6 566.3,43.8 573.5,40.6 576.5,38.3 581,35.6 583.4,32.9 588.8,27.8 596.4,34.2 599.3,38 
					605.7,42.6 607.6,49 615.8,55.9 288.6,55.9 				" />
					</g>
					<g>
						<polygon points="93.9,36.4 93.5,36.4 93.5,32.5 93.5,31.3 93.5,30.9 93.5,30 93.9,30 93.9,29.5 93.5,29.5 93.5,28.7 93.9,28.7 
			93.9,28.4 66.3,28.4 66.3,28.7 66.7,28.7 66.7,29.5 66.3,29.5 66.3,30 66.7,30 66.7,30.9 66.7,31.3 66.7,32.5 66.7,36.4 
			66.4,36.4 55.4,41.8 104.9,41.8 		" />
						<polygon points="90.9,24.2 90.4,24.2 90.4,21.2 90.4,20.3 90.4,20 90.4,19.3 90.7,19.3 90.7,18.9 90.4,18.9 90.4,18.3 90.7,18.3 
			90.7,18.1 69.5,18.1 69.5,18.3 69.8,18.3 69.8,18.9 69.5,18.9 69.5,19.3 69.8,19.3 69.8,20 69.8,20.3 69.8,21.2 69.8,24.2 
			69.3,24.2 60.7,28.4 99.5,28.4 		" />
						<polygon points="88.4,14.8 86.5,14.8 86.5,14.6 84,14.6 84,13 82.6,13 80.1,0 77.6,13 76.3,13 76.3,14.6 73.8,14.6 73.8,14.8 
			71.8,14.8 65.2,18.1 95.1,18.1 		" />
						<g>
							<path
								d="M69.9,61.8c-0.1-0.2-0.4-0.2-0.6-0.2s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.6s-0.1,0.6-0.1,0.9s0,0.7,0.1,0.9s0.1,0.5,0.3,0.6
				c0.2,0.2,0.4,0.2,0.6,0.2s0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.3-0.6s0.1-0.6,0.1-0.9s0-0.7-0.1-0.9S70.1,61.9,69.9,61.8z" />
							<path
								d="M74.2,61.8c-0.1-0.2-0.4-0.2-0.6-0.2s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.6s-0.1,0.6-0.1,0.9s0,0.7,0.1,0.9s0.1,0.5,0.3,0.6
				c0.2,0.2,0.4,0.2,0.6,0.2s0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.3-0.6s0.1-0.6,0.1-0.9s0-0.7-0.1-0.9S74.4,61.9,74.2,61.8z" />
							<path d="M430.9,59c-0.1-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.4-0.1,0.6s0,0.4,0.1,0.6
				c0.1,0.2,0.2,0.3,0.4,0.4c0.1,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.4,0.1-0.6s0-0.4-0.1-0.6
				S431.1,59.1,430.9,59L430.9,59z" />
							<path d="M411.9,58.9c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.5,0.1-0.6,0.2c-0.2,0.1-0.3,0.4-0.3,0.6h1.8c0-0.2,0-0.3-0.1-0.5
				S412.1,59,411.9,58.9L411.9,58.9z" />
							<path d="M395,58.9c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.5,0.1-0.6,0.2c-0.2,0.1-0.3,0.4-0.3,0.6h1.8c0-0.2,0-0.3-0.1-0.5
				S395.2,59,395,58.9L395,58.9z" />
							<path
								d="M473.7,61.2h-0.9v1.6h0.9c0.3,0,0.5-0.1,0.7-0.2s0.2-0.3,0.2-0.6C474.6,61.4,474.3,61.2,473.7,61.2L473.7,61.2z" />
							<path
								d="M480.6,62.3c-0.3,0-0.5,0.1-0.7,0.2s-0.3,0.4-0.4,0.7h2.1c0-0.3-0.1-0.5-0.3-0.7S480.9,62.3,480.6,62.3z" />
							<path d="M418.6,59c-0.1-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.4s-0.1,0.4-0.1,0.6s0,0.4,0.1,0.6
				s0.2,0.3,0.3,0.4c0.1,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.4-0.4s0.1-0.4,0.1-0.6s0-0.4-0.1-0.6
				C418.8,59.2,418.7,59.1,418.6,59L418.6,59z" />
							<path d="M384.6,65.5c-0.1-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.4s-0.1,0.4-0.1,0.6s0,0.4,0.1,0.6
				s0.2,0.3,0.3,0.4c0.1,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.4-0.4s0.1-0.4,0.1-0.6s0-0.4-0.1-0.6
				C384.9,65.8,384.8,65.6,384.6,65.5L384.6,65.5z" />
							<path d="M361.1,65.6c-0.1-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1s-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.4-0.1,0.6s0,0.4,0.1,0.6
				s0.2,0.3,0.4,0.4c0.1,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.4,0.1-0.6s0-0.4-0.1-0.6
				S361.3,65.6,361.1,65.6L361.1,65.6z" />
							<path d="M363,60.9c0.1,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.4,0.1-0.6s0-0.4-0.1-0.6
				s-0.2-0.3-0.4-0.4s-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.4-0.1,0.6s0,0.4,0.1,0.6
				C362.8,60.7,362.9,60.8,363,60.9L363,60.9z" />
							<path d="M374.9,59c-0.1-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.4s-0.1,0.4-0.1,0.6s0,0.4,0.1,0.6
				s0.2,0.3,0.3,0.4c0.1,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.4-0.4s0.1-0.4,0.1-0.6s0-0.4-0.1-0.6
				C375.1,59.2,375,59.1,374.9,59L374.9,59z" />
							<path d="M359.6,60.9c0.1,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.4,0.1-0.6s0-0.4-0.1-0.6
				s-0.2-0.3-0.4-0.4c-0.1-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1s-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.4-0.1,0.6s0,0.4,0.1,0.6
				S359.5,60.8,359.6,60.9L359.6,60.9z" />
							<path d="M381.6,59c-0.1-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.4-0.1,0.6s0,0.4,0.1,0.6
				c0.1,0.2,0.2,0.3,0.4,0.4c0.1,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.4,0.1-0.6s0-0.4-0.1-0.6
				S381.8,59.1,381.6,59L381.6,59z" />
							<path d="M528.8,62.3c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.6-0.3,1s0.1,0.8,0.3,1c0.2,0.2,0.5,0.3,0.8,0.3
				c0.2,0,0.4-0.1,0.6-0.1s0.3-0.2,0.4-0.4c0.1-0.2,0.2-0.4,0.2-0.7c0-0.4-0.1-0.8-0.3-1C529.5,62.4,529.2,62.3,528.8,62.3
				L528.8,62.3z" />
							<path d="M539.8,61.4c-0.2-0.1-0.4-0.2-0.7-0.2h-0.9v1.6h0.9c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.2-0.4,0.2-0.6S540,61.5,539.8,61.4
				L539.8,61.4z" />
							<path d="M189.6,62.5c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.1c-0.2,0.1-0.3,0.2-0.4,0.4s-0.2,0.4-0.2,0.7s0.1,0.5,0.2,0.7
				s0.2,0.4,0.4,0.5s0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.2-0.4,0.2-0.7s-0.1-0.5-0.2-0.7
				S189.8,62.6,189.6,62.5L189.6,62.5z" />
							<path
								d="M593.3,29.5v-0.6h0.1v-0.3h-0.1v-0.3h0.1V28h-0.2v-0.2c0.3-0.1,0.5-0.4,0.5-0.7c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.4-0.8,0.8
				c0,0.4,0.2,0.6,0.6,0.8V28h-0.2v0.3h0.1v0.3h-0.1v0.3h0.1v0.6c-2.7,0.1-5.4,0.2-8.1,0.3v-0.9h0.1v-0.3h-0.1v-0.3h0.1V28h-0.2
				v-0.2c0.3-0.1,0.5-0.4,0.5-0.7c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.4-0.8,0.8c0,0.4,0.2,0.6,0.6,0.8V28h-0.2v0.3h0.1v0.3h-0.1v0.3h0.1
				v0.9c-1,0-1.9,0-2.9,0v-1.4h0.2v-0.5H581v-0.4h0.2V27h-0.3v-0.3c0.5-0.1,0.8-0.6,0.8-1.1c0-0.6-0.5-1.1-1.1-1.1s-1.1,0.5-1.1,1.1
				c0,0.5,0.3,1,0.8,1.1V27h-0.3v0.5h0.2v0.4h-0.2v0.5h0.2v1.4c-1,0-1.9,0-2.9,0v-0.9h0.1v-0.3h-0.1v-0.3h0.1V28h-0.2v-0.2
				c0.3-0.1,0.5-0.4,0.5-0.7c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.4-0.8,0.8c0,0.4,0.2,0.6,0.6,0.8V28h-0.2v0.3h0.1v0.3h-0.1v0.3h0.1v0.9
				c-2.7-0.1-5.4-0.1-8.1-0.3v-0.6h0.1v-0.3h-0.1v-0.3h0.1V28h-0.2v-0.2c0.3-0.1,0.5-0.4,0.5-0.7c0-0.4-0.3-0.8-0.8-0.8
				s-0.8,0.4-0.8,0.8c0,0.4,0.2,0.6,0.6,0.8V28h-0.2v0.3h0.1v0.3h-0.1v0.3h0.1v0.6c-0.7,0-1.3-0.1-2-0.1v1.9c0.5,0,1,0,1.5,0.1
				c-0.1,0-0.2,0-0.3,0v0.6c0.8,0,1.6,0.1,2.4,0.1c-0.5,0-0.9,0-1.3,0c0,0.3,0,0.6,0,0.9c0.6,0,1.1,0,1.7,0c-0.5,0-1,0-1.5,0
				c0,0.1,0,0.2,0,0.4c0.6,0,1.2,0,1.9,0c-0.5,0-1,0-1.5,0c0,0.1,0,0.2,0,0.4c0.6,0,1.2,0,1.8,0c-0.5,0-1,0-1.5,0c0,0.1,0,0.2,0,0.4
				c0.6,0,1.2,0,1.9,0c-0.5,0-1.1,0-1.6,0c0,0.1,0,0.2,0,0.4c1.3,0,2.6,0,3.9,0c-1.2,0-2.5,0-3.7,0c0,0.1,0,0.2,0,0.4
				c-0.4,0-0.8,0-1.3,0c0,0.2,0,0.5,0,0.8h1.3V36h0.2v13.4h-0.1v0.8h-0.2V52h-6.3v-1.7h-1.4v-1.7h-1.3v-8.8h0.5v-0.6h0.4v-0.6h-0.8
				v-0.1l3.2-2.7h0.5v-1.2l-1.3-0.8l-0.1-0.1l-2.7-1.7l-0.2-0.1l0,0l-0.8-0.5l-0.6-0.4l0,0l-0.8-0.5l0,0l-0.6-0.4l-0.8-0.5l0,0
				l-0.6-0.4l0,0l-0.8-0.5l0,0l-0.6-0.4l0,0l-0.7-0.5h-0.4v-0.5l1.8-1.6h1.8v-0.7l-0.5-0.3l0,0l-0.4-0.2l-0.5-0.3l0,0l-0.4-0.2l0,0
				l-0.5-0.3l0,0l-0.4-0.2l0,0l-0.5-0.3l0,0l-0.4-0.2l0,0l-0.5-0.3l0,0l-0.4-0.2l0,0l-0.5-0.3l-0.4-0.2l0,0l-0.5-0.3l0,0l-0.4-0.2
				l0,0l-0.5-0.3h-2.2v-0.7h-1.5v-0.9h-0.8l-1.6-8.1l-1.6,8.1h-0.8v0.9h-1.5v0.7h-2.2l-0.6,0.3l-0.3,0.2l0,0l-1.4,0.8l0,0l-0.4,0.2
				l0,0l-0.5,0.3l0,0l-0.4,0.2l0,0l-0.5,0.3l0,0l-0.4,0.2l0,0l-0.5,0.3l0,0l-0.4,0.2l0,0l-0.5,0.3l-0.4,0.2l0,0l-0.5,0.3v0.7h1.8
				l1.8,1.6v0.5h-0.4l-0.7,0.5l-0.6,0.4l0,0l-0.8,0.5l0,0l-0.6,0.4l0,0l-0.8,0.5l-0.6,0.4l0,0l-0.8,0.5l0,0l-0.6,0.4l-3.8,2.3l0,0
				l-1.3,0.8v1.2h0.5l3.2,2.7v0.1h-0.8v0.6h0.4v0.6h0.5v8.8h-1.3v1.7h-1.4V52h-4.3v-6.1h3.4l-2.9-2h-0.5v-6.1h3.4l-2.9-2h-0.5v-0.4
				h0.1V35h-0.1v-0.8h0.1v-0.4h-0.1V28h0.1v-0.4h0.2v-0.8h-0.2v-2.1h0.1v-0.4h0.2v-0.8h-4.2v-4.6h2.6l-2.2-1.5h-2v-1.5h0v-0.4h0.1
				v-0.7h-20.8v0.7h0.1v0.4h0v1.5h-2l-2.2,1.5h2.6v4.6h-4.2v0.8h0.2v0.4h0.1v2.1h-0.2v0.8h0.2V28h0.1v5.9h-0.1v0.4h0.1V35h-0.1v0.4
				h0.1v0.4h-0.5l-2.8,1.9v-0.4h-1.2v-3.5h2.6v-1l-0.9-1.3l-0.1-0.1l-1.3-1.9h-1.6v-3h2.3v-0.9l-0.5-0.7l0,0l-0.2-0.4l-1.2-1.8H481
				v-2.5h1.9v-0.8l-0.4-0.6l0,0l-0.3-0.4l0,0l-0.3-0.4l-0.3-0.4l0,0l-0.3-0.4l0,0l0-0.1h-1.7v-2h1.5v-0.6l-0.3-0.5l0,0l-0.2-0.3l0,0
				l-0.7-1l0,0l0,0h-1.5v-1.5h1.1v-0.4l-0.2-0.4l0,0l-0.1-0.1l-0.4-0.5l0-0.1l0,0l-0.2-0.3h-0.8V9.1h-0.7V8.7h-1.5L475,7.5h-0.2
				l-0.4-1.3l-0.4,1.3h-0.2l-0.6,1.2h-1.5v0.4h-0.7v0.3h-0.8l-0.6,0.9l-0.1,0.2l0,0l-0.2,0.4v0.4h1.1v1.5H469l0,0l0,0l-0.3,0.4
				l-0.2,0.3l0,0l-0.2,0.4l0,0l-0.2,0.3l-0.3,0.5v0.6h1.5v2h-1.7l0,0.1l0,0l-0.9,1.3l0,0l-0.1,0.1l-0.6,0.9v0.8h1.9v2.5h-1.6l-2,2.8
				v0.9h2.3v3h-1.6l-2,2.8l-0.2-0.2l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5
				l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5l-0.6-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5l-0.3,0.4
				c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5l-0.6-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1
				l0.3-0.4l-0.6-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5l-0.5-0.5
				l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1
				l0.3-0.4l-0.5-0.5l-0.5-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5
				l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5l-0.5-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5l-0.3,0.4
				c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5l-0.5-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1
				l0.3-0.4l-0.5-0.5l-0.4,0.4c0,0-0.1-0.1-0.1-0.1l0.4-0.4l-0.5-0.5l-0.4,0.4c0,0-0.1-0.1-0.1-0.1l0.4-0.4l-0.5-0.5l-0.5-0.5
				l-0.4,0.4c0,0-0.1-0.1-0.1-0.1l0.4-0.4l-0.5-0.5l-0.4,0.4c0,0-0.1-0.1-0.1-0.1l0.4-0.4l-0.5-0.5l-0.4,0.4c0,0-0.1-0.1-0.1-0.1
				l0.4-0.4l-0.5-0.5l-0.2,0.2v-1.2h-0.1v-0.3h0.1v-0.3h-0.1v-1.3h0.1V9.9h-0.1V9.6h0.1V9.2h-1l-1.8-6.3l-1.8,6.3h-1v0.4h0.1v0.4
				h-0.1v0.4h0.1v1.3h-0.1v0.3h0.1v0.3h-0.1v1.2l-0.2-0.2l-0.5,0.5l0.4,0.4c0,0-0.1,0.1-0.1,0.1l-0.4-0.4l-0.5,0.5l0.4,0.4
				c0,0-0.1,0.1-0.1,0.1l-0.4-0.4l-0.5,0.5l0.4,0.4c0,0-0.1,0.1-0.1,0.1l-0.4-0.4l-0.5,0.5l-0.5,0.5l0.4,0.4c0,0-0.1,0.1-0.1,0.1
				l-0.4-0.4l-0.5,0.5l0.4,0.4c0,0-0.1,0.1-0.1,0.1l-0.4-0.4l-0.5,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.5,0.5l-0.5,0.5
				l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.5,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.5,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1
				l-0.3-0.4l-0.5,0.5l-0.5,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.5,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.5,0.5
				l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.5,0.5l-0.5,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.5,0.5l0.3,0.4
				c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.5,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.5,0.5l-0.6,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1
				l-0.3-0.4l-0.6,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.6,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.6,0.5l-0.6,0.5
				l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.6,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.6,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1
				l-0.3-0.4l-0.6,0.5l-0.6,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.2,0.2l0,0l-0.1-0.1l-0.4-0.3l0,0l-0.2-0.2l0,0l-0.4-0.3
				l0,0l-0.2-0.1l-0.4-0.3h-1.2v-0.3h-0.6v-0.4h-0.3l-0.6-3.1l-0.6,3.1h-0.3v0.4h-0.6v0.3H410l-0.5,0.4l-0.1,0.1l0,0l-0.4,0.3l0,0
				l-0.2,0.2l0,0l-0.4,0.3l0,0l-0.1,0.1l-0.5,0.4l0,0l-0.2,0.2l0,0l-0.4,0.3l0,0l-0.1,0.1l-0.3,0.2v0.7h1l2,1.9v0.2h-0.8l-0.5,0.4
				l-0.3,0.2l0,0l-0.6,0.4l0,0l-0.1,0.1l-0.8,0.6l-0.1,0.1l-0.8,0.6l0,0l-0.3,0.2l-0.7,0.5l-0.2,0.2l0,0l-0.3,0.2v1h1.9l0.1,0.1
				l-0.5,0.4l0.3,0.4c0,0-0.1,0.1-0.2,0.1l-0.3-0.4l-0.6,0.4l0.3,0.4c0,0-0.1,0.1-0.2,0.1l-0.3-0.4l-0.6,0.4l-0.6,0.4l0.3,0.4
				c-0.1,0-0.1,0.1-0.2,0.1l-0.3-0.4l-0.6,0.4l0.3,0.4c-0.1,0-0.1,0.1-0.2,0.1l-0.3-0.4l-0.6,0.4l0.3,0.4c-0.1,0-0.1,0.1-0.2,0.1
				l-0.3-0.4l-0.6,0.4l-0.6,0.4l0.2,0.4c-0.1,0-0.1,0.1-0.2,0.1l-0.2-0.4l-0.6,0.4l0.2,0.4c-0.1,0-0.1,0.1-0.2,0.1l-0.2-0.4
				l-0.6,0.4l0.2,0.4c-0.1,0-0.1,0.1-0.2,0.1l-0.2-0.4l-0.6,0.4l0.3,0.5h-1.9v0.3l0,0c0,0-0.6,0.4-1.3,1.1v-3h0.5v-0.6h-0.5v-1.1
				h0.5v-0.3h-35.2v0.3h0.5v1.1h-0.5v0.6h0.5V52H354v-0.7h-0.7v-0.7h-0.6v-0.7h-0.6v-0.7h-0.5v-3.7h0.2v-0.4h-0.2v-2.7h0.2v-0.4
				h-0.5v-0.8h0.1v-0.4h-0.6l0.4-1.3l-1.1-1.5l0,0v-0.2H350v-0.4h0.1v-0.2H350V37h-0.2v-0.4h-0.1v-0.4h-0.2v0.4h-0.1V37h-0.2v0.4
				h-0.1v0.2h0.1v0.4h-0.1v0.2l0,0l-1.1,1.5l0.4,1.3h-0.6v0.4h0.1v0.8h-0.5v0.4h0.2v2.7h-0.2v0.4h0.2v3.7h-1.4v-2.6h0.1v-0.3h-0.1
				v-1.9h0.1v-0.3h-0.4v-0.5h0.1v-0.3h-0.5l0.3-0.9l-0.8-1l0,0v-0.1h-0.1V41h0.1v-0.1h-0.1v-0.3h-0.1v-0.3h-0.1v-0.3l0,0v-1.4h0.6
				V38h0.3v-0.6h-6.8v-0.6h0.9v0.6h0.9v-0.6h3.1v0.6h0.9v-2.6h0.2v-0.4h0.2v-0.4h-1.7v0.2h-2.4v-0.2h-1.7v0.2h-0.5v-4.7h0.5v-1.3
				h-1.4V26h0.4v-1.3h-0.2c0,0,0.5-5.7-3.9-7.9v-0.4H334v-1.2h0.2v-0.4H334v-1.2h-0.6v-1.2h-0.3v-1.2h-0.5v1.2h-0.3v1.2h-0.6v1.2
				h-0.2v0.4h0.2v1.2h-0.2v0.4c-4.3,2.2-3.9,7.9-3.9,7.9h-0.2V26h0.4v2.2h-1.4v1.3h0.5v4.7h-0.5v-0.2h-1.7v0.2h-2.4v-0.2h-1.7v0.4
				h0.2v0.4h0.2v2.6h0.9v-2.6h3.1v1.4h-3v0.6h3v0.6h0.9v-0.6h0.9v0.6h-6.8V38h0.3v0.6h0.6v1.4l0,0v0.3h-0.1v0.3h-0.1v0.3h-0.1V41
				h0.1v0.3h-0.1v0.1l0,0l-0.8,1l0.3,0.9h-0.5v0.3h0.1v0.5h-0.4v0.3h0.1v1.9h-0.1v0.3h0.1v2.6h-1.4v-3.7h0.2v-0.4h-0.2v-2.7h0.2V42
				H318v-0.8h0.1v-0.4h-0.6l0.4-1.3l-1.1-1.5l0,0v-0.2h-0.1v-0.4h0.1v-0.2h-0.1V37h-0.2v-0.4h-0.1v-0.4h-0.2v0.4h-0.1V37h-0.2v0.4
				h-0.1v0.2h0.1v0.4h-0.1v0.2l0,0l-1.1,1.5l0.4,1.3h-0.6v0.4h0.1V42H314v0.4h0.2v2.7H314v0.4h0.2v3.7h-0.5v0.7h-0.6v0.7h-0.6v0.7
				h-0.7V52H310L307.6,29l0,0v-0.6h0.1V28h0.2v-0.3h0.2v-0.2h-0.5v-2h0.9v-0.3h0.1v-0.3h-1.2c0-1.9-1.7-2.7-1.9-2.8
				c-0.2-0.1-0.6-1.6-0.6-1.6h-0.5c0,0-0.4,1.5-0.6,1.6c-0.2,0.1-1.9,0.8-1.9,2.8h-1.2v0.3h0.1v0.3h0.9v2h-0.5v0.2h0.2V28h0.2v0.4
				h0.1V29l0,0L299.3,52h-5v-1.8h-0.2v-0.8H294V36h0.2v-0.5h1.3c0-0.2,0-0.5,0-0.8c-0.4,0-0.8,0-1.3,0c0-0.1,0-0.2,0-0.4
				c-1.2,0-2.5,0-3.7,0c1.3,0,2.6,0,3.9,0c0-0.1,0-0.2,0-0.4c-0.5,0-1.1,0-1.6,0c0.6,0,1.2,0,1.9,0c0-0.1,0-0.2,0-0.4
				c-0.5,0-1,0-1.5,0c0.6,0,1.2,0,1.8,0c0-0.1,0-0.2,0-0.4c-0.5,0-1,0-1.5,0c0.6,0,1.2,0,1.9,0c0-0.1,0-0.2,0-0.4c-0.5,0-1,0-1.4,0
				c0.5,0,1.1,0,1.7,0c0-0.3,0-0.6,0-0.9c-0.5,0-0.9,0-1.4,0c0.8,0,1.6,0,2.4-0.1c0-0.2,0-0.4,0-0.6c-0.1,0-0.2,0-0.3,0
				c0.5,0,1,0,1.5-0.1v-1.9c-0.7,0-1.3,0.1-2,0.1V29h0.1v-0.3h-0.1v-0.3h0.1v-0.3h-0.2v-0.2c0.3-0.1,0.5-0.4,0.5-0.7
				c0-0.4-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8c0,0.4,0.2,0.6,0.6,0.8v0.2h-0.2v0.3h0.1v0.3h-0.1V29h0.1v0.6c-2.7,0.1-5.4,0.2-8.1,0.3
				V29h0.1v-0.3h-0.1v-0.3h0.1v-0.3h-0.2v-0.2c0.3-0.1,0.5-0.4,0.5-0.7c0-0.4-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8
				c0,0.4,0.2,0.6,0.6,0.8v0.2h-0.2v0.3h0.1v0.3h-0.1V29h0.1v0.9c-1,0-1.9,0-2.9,0v-1.4h0.2V28h-0.2v-0.4h0.2v-0.5h-0.3v-0.3
				c0.5-0.1,0.8-0.6,0.8-1.1c0-0.6-0.5-1.1-1.1-1.1s-1.1,0.5-1.1,1.1c0,0.5,0.4,1,0.8,1.1v0.3h-0.3v0.5h0.2V28h-0.2v0.5h0.2v1.4
				c-1,0-1.9,0-2.9,0V29h0.1v-0.3h-0.1v-0.3h0.1v-0.3h-0.2v-0.2c0.3-0.1,0.5-0.4,0.5-0.7c0-0.4-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8
				c0,0.4,0.2,0.6,0.6,0.8v0.2h-0.2v0.3h0.1v0.3h-0.1V29h0.1v0.9c-2.7-0.1-5.4-0.1-8.1-0.3V29h0.1v-0.3h-0.1v-0.3h0.1v-0.3h-0.2
				v-0.2c0.3-0.1,0.5-0.4,0.5-0.7c0-0.4-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8c0,0.4,0.2,0.6,0.6,0.8v0.2h-0.2v0.3h0.1v0.3h-0.1V29h0.1
				v0.6c-0.7,0-1.3-0.1-2-0.1v1.9c0.5,0,1,0,1.5,0.1c-0.1,0-0.2,0-0.3,0V32c0.8,0,1.6,0.1,2.4,0.1c-0.5,0-0.9,0-1.4,0
				c0,0.3,0,0.6,0,0.9c0.6,0,1.1,0,1.7,0c-0.5,0-1,0-1.5,0c0,0.1,0,0.2,0,0.4c0.6,0,1.2,0,1.9,0c-0.5,0-1,0-1.5,0c0,0.1,0,0.2,0,0.4
				c0.6,0,1.2,0,1.8,0c-0.5,0-1,0-1.5,0c0,0.1,0,0.2,0,0.4c0.6,0,1.2,0,1.9,0c-0.5,0-1.1,0-1.6,0c0,0.1,0,0.2,0,0.4
				c1.3,0,2.6,0,3.9,0c-1.2,0-2.5,0-3.7,0c0,0.1,0,0.2,0,0.4c-0.4,0-0.8,0-1.3,0c0,0.2,0,0.5,0,0.8h1.3v0.5h0.2v13.4h-0.1v0.8h-0.2
				v1.8h-6.3v-1.7h-1.4v-1.7h-1.3v-8.8h0.5v-0.6h0.4v-0.6h-0.9v-0.1l3.2-2.7h0.5v-1.2l-1.3-0.8l-0.1-0.1l-2.7-1.7l-0.2-0.1l0,0
				l-0.8-0.5l-0.6-0.4l0,0l-0.8-0.5l0,0l-0.6-0.4l-0.8-0.5l0,0l-0.6-0.4l0,0l-0.8-0.5l0,0l-0.6-0.4l0,0l-0.7-0.5H255v-0.5l1.8-1.6
				h1.8v-0.7l-0.5-0.3l0,0l-0.4-0.2l-0.5-0.3l0,0l-0.4-0.2l0,0l-0.5-0.3l0,0l-0.4-0.2l0,0l-0.5-0.3l0,0l-0.4-0.2l0,0l-0.5-0.3l0,0
				l-0.4-0.2l0,0l-0.5-0.3l-0.4-0.2l0,0l-0.5-0.3l0,0l-0.4-0.2l0,0l-0.5-0.3h-2.2v-0.7h-1.5v-0.9h-0.8l-1.6-8.1l-1.6,8.1h-0.8v0.9
				h-1.5v0.7h-2.2l-0.6,0.3l-0.3,0.2l0,0l-1.4,0.8l0,0l-0.4,0.2l0,0l-0.5,0.3l0,0l-0.4,0.2l0,0l-0.5,0.3l0,0l-0.4,0.2l0,0l-0.5,0.3
				l0,0l-0.4,0.2l0,0l-0.5,0.3l-0.4,0.2l0,0l-0.5,0.3v0.7h1.8l1.8,1.6V28H236l-0.7,0.5l-0.6,0.4l0,0l-0.8,0.5l0,0l-0.6,0.4l0,0
				l-0.8,0.5l-0.6,0.4l0,0l-0.8,0.5l0,0l-0.6,0.4l-3.8,2.3l0,0l-1.4,0.8v1.2h0.5l3.2,2.7v0.1H228v0.6h0.4v0.6h0.5v8.8h-1.3v1.7h-1.4
				v1.7h-4.3V46h3.4l-2.9-2h-0.5v-6.1h3.4l-2.9-2h-0.5v-0.4h0.1v-0.4h-0.1v-0.8h0.1v-0.4h-0.1V28h0.1v-0.4h0.2v-0.8h-0.2v-2.1h0.1
				v-0.4h0.2v-0.8H218V19h2.6l-2.2-1.5h-2V16h0.1v-0.4h0.1v-0.7h-20.8v0.7h0.1V16h0.1v1.5h-2l-2.2,1.5h2.6v4.6h-4.2v0.8h0.2v0.4h0.1
				v2.1h-0.2v0.8h0.2V28h0.1v5.9h-0.1v0.4h0.1v0.8h-0.1v0.4h0.1v0.4h-0.5l-2.8,1.9v-0.4h-1.2v-3.5h2.6v-1l-0.9-1.3l-0.1-0.1
				l-1.3-1.9h-1.6v-3h2.3v-0.9l-0.5-0.7l0,0l-0.2-0.4l-1.2-1.8h-1.6v-2.5h1.9v-0.8l-0.4-0.6l0,0l-0.3-0.4l0,0l-0.3-0.4l-0.3-0.4l0,0
				l-0.3-0.4l0,0l0-0.1H182v-2h1.5v-0.6l-0.3-0.5l0,0l-0.2-0.3l0,0l-0.7-1l0,0l0,0h-1.5v-1.5h1.1v-0.4l-0.2-0.4l0,0l-0.1-0.1
				l-0.4-0.5l-0.1-0.1l0,0l-0.2-0.3h-0.8V9.2h-0.7V8.8H178l-0.6-1.2h-0.2l-0.4-1.3l-0.4,1.3h-0.2l-0.6,1.2h-1.5v0.4h-0.7v0.3h-0.8
				l-0.6,0.9l-0.1,0.2l0,0l-0.2,0.4v0.4h1.1v1.5h-1.5l0,0l0,0l-0.3,0.4l-0.2,0.3l0,0l-0.2,0.4l0,0l-0.2,0.3l-0.3,0.5v0.6h1.5v2h-1.7
				l0,0.1l0,0l-0.9,1.3l0,0l-0.1,0.1l-0.6,0.9v0.8h1.9v2.5h-1.6l-2,2.8v0.9h2.3v3h-1.6l-2,2.8l-0.2-0.2l-0.3,0.4
				c-0.1,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5L164,32c-0.1,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5l-0.3,0.4c-0.1,0-0.1-0.1-0.1-0.1
				l0.3-0.4l-0.6-0.5l-0.6-0.5l-0.3,0.4c-0.1,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5l-0.3,0.4c-0.1,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5
				l-0.3,0.4c-0.1,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5l-0.6-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5l-0.3,0.4
				c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5l-0.6-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1
				l0.3-0.4l-0.6-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5l-0.6-0.5
				l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1
				l0.3-0.4l-0.5-0.5l-0.5-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5
				l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5l-0.5-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5l-0.4,0.4
				c0,0-0.1-0.1-0.1-0.1l0.4-0.4l-0.5-0.5l-0.4,0.4c0,0-0.1-0.1-0.1-0.1l0.4-0.4l-0.5-0.5l-0.5-0.5l-0.4,0.4c0,0-0.1-0.1-0.1-0.1
				l0.4-0.4l-0.5-0.5l-0.4,0.4c0,0-0.1-0.1-0.1-0.1l0.4-0.4l-0.5-0.5l-0.4,0.4c0,0-0.1-0.1-0.1-0.1l0.4-0.4l-0.5-0.5l-0.2,0.2v-1.2
				h-0.1v-0.3h0.1v-0.3h-0.1v-1.3h0.1V10h-0.1V9.7h0.1V9.3h-1L141.7,3l-1.8,6.3h-1v0.4h0.1V10h-0.1v0.4h0.1v1.3h-0.1v0.3h0.1v0.3
				h-0.1v1.2l-0.2-0.2l-0.5,0.5l0.4,0.4c0,0-0.1,0.1-0.1,0.1l-0.4-0.4l-0.5,0.5l0.4,0.4c0,0-0.1,0.1-0.1,0.1l-0.4-0.4l-0.5,0.5
				l0.4,0.4c0,0-0.1,0.1-0.1,0.1l-0.4-0.4l-0.5,0.5l-0.5,0.5l0.4,0.4c0,0-0.1,0.1-0.1,0.1l-0.4-0.4l-0.5,0.5l0.4,0.4
				c0,0-0.1,0.1-0.1,0.1l-0.4-0.4l-0.5,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.5,0.5l-0.5,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1
				L133,19l-0.5,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.5,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.5,0.5l-0.5,0.5
				l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.6,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.6,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1
				l-0.3-0.4l-0.6,0.5l-0.6,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.6,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.6,0.5
				l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.6,0.5l-0.6,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.6,0.5l0.3,0.4
				c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.6,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.6,0.5l-0.6,0.5l0.3,0.4c-0.1,0-0.1,0.1-0.1,0.1
				l-0.3-0.4l-0.6,0.5l0.3,0.4c-0.1,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.6,0.5l0.3,0.4c-0.1,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.6,0.5
				l-0.6,0.5l0.3,0.4c-0.1,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.2,0.2l0,0l-0.1-0.1l-0.4-0.3l0,0l-0.2-0.2l0,0l-0.4-0.3l0,0l-0.2-0.1
				l-0.4-0.3h-1.2v-0.3H116v-0.4h-0.3l-0.6-3.1l-0.6,3.1h-0.3v0.4h-0.6v0.3h-1.2l-0.5,0.4l-0.1,0.1l0,0l-0.4,0.3l0,0l-0.2,0.2l0,0
				l-0.4,0.3l0,0l-0.1,0.1l-0.5,0.4l0,0l-0.2,0.2l0,0l-0.4,0.3l0,0l-0.2,0.1l-0.3,0.2v0.7h1l1.9,1.9v0.2h-0.8l-0.6,0.4l-0.3,0.2l0,0
				l-0.6,0.4l0,0l-0.1,0.1l-0.8,0.6l-0.1,0.1l-0.8,0.6l0,0l-0.3,0.2l-0.7,0.5l-0.2,0.2l0,0l-0.3,0.2v1h1.9l0.1,0.1l-0.6,0.4l0.3,0.4
				c-0.1,0-0.1,0.1-0.2,0.1l-0.3-0.4l-0.6,0.4l0.3,0.4c-0.1,0-0.1,0.1-0.2,0.1l-0.3-0.4l-0.6,0.4l-0.6,0.4l0.3,0.4
				c-0.1,0-0.1,0.1-0.2,0.1l-0.3-0.4l-0.6,0.4l0.3,0.4c-0.1,0-0.1,0.1-0.2,0.1l-0.3-0.4l-0.6,0.4l0.3,0.4c-0.1,0-0.1,0.1-0.2,0.1
				l-0.3-0.4l-0.6,0.4l-0.6,0.4l0.2,0.4c-0.1,0-0.1,0.1-0.2,0.1l-0.2-0.4l-0.6,0.4l0.2,0.4c-0.1,0-0.1,0.1-0.2,0.1l-0.2-0.4
				l-0.7,0.4l0.2,0.4c-0.1,0-0.1,0.1-0.2,0.1l-0.2-0.4l-0.7,0.4l0.3,0.5h-1.9v0.3l0,0c0,0-0.6,0.4-1.3,1.1v-3h0.5v-0.6h-0.5v-1.1
				h0.5v-0.3H62.6v0.3h0.5v1.1h-0.5v0.6h0.5v8.2h-6.7v-0.7h-0.7v-0.7h-0.6v-0.7h-0.6v-0.7h-0.5v-3.7h0.2v-0.4h-0.2v-2.7h0.2v-0.4
				h-0.5v-0.8h0.1v-0.4h-0.7l0.4-1.3l-1.1-1.5l0,0V38h-0.1v-0.4h0.1v-0.2h-0.1V37h-0.2v-0.4h-0.1v-0.4h-0.2v0.4h-0.1V37h-0.2v0.4
				h-0.1v0.2h0.1V38h-0.1v0.2l0,0l-1.1,1.5l0.4,1.3h-0.7v0.4h0.1v0.8h-0.5v0.4h0.2v2.7h-0.2v0.4h0.2v3.7h-1.4v-2.6h0.1v-0.3h-0.1
				v-1.9h0.1v-0.3h-0.3v-0.5h0.1v-0.3h-0.5l0.3-0.9l-0.8-1l0,0v-0.1h-0.1v-0.3h0.1V41h-0.1v-0.3h-0.1v-0.3h-0.1v-0.3l0,0v-1.4h0.6
				v-0.6H48v-0.6h-6.8v-0.6h0.9v0.6h0.9v-0.6h3.1v0.6h0.9v-2.6h0.2v-0.4h0.2v-0.4h-1.7v0.2h-2.4v-0.2h-1.7v0.2h-0.5v-4.7h0.5v-1.3
				h-1.4v-2.2h0.4v-1.3h-0.2c0,0,0.4-5.7-3.9-7.9v-0.4h-0.2v-1.2h0.2v-0.4h-0.2v-1.2h-0.6v-1.2h-0.3v-1.2h-0.5v1.2h-0.3v1.2h-0.6
				v1.2H34v0.4h0.2v1.2H34v0.4c-4.3,2.2-3.9,7.9-3.9,7.9H30v1.3h0.4v2.2h-1.4v1.3h0.5v4.7h-0.5v-0.2h-1.7v0.2h-2.4v-0.2h-1.7v0.4
				h0.2v0.4h0.2v2.6h0.9v-2.6h3.1v1.4h-3v0.6h3v0.6h0.9v-0.6h0.9v0.6h-6.8v0.6h0.3v0.6h0.6v1.4l0,0v0.3h-0.1v0.3h-0.1V41h-0.1v0.1
				h0.1v0.3h-0.1v0.1l0,0l-0.8,1l0.3,0.9h-0.5v0.3h0.1v0.5H22v0.3h0.1v1.9H22v0.3h0.1v2.6h-1.4v-3.7h0.2v-0.4h-0.2v-2.7h0.2v-0.4
				h-0.5v-0.8h0.1v-0.4h-0.7l0.4-1.3l-1.1-1.5l0,0V38H19v-0.4h0.1v-0.2H19V37h-0.2v-0.4h-0.1v-0.4h-0.2v0.4h-0.1V37h-0.2v0.4h-0.1
				v0.2h0.1V38h-0.1v0.2l0,0l-1.1,1.5l0.4,1.3h-0.7v0.4h0.1v0.8h-0.5v0.4h0.2v2.7h-0.2v0.4h0.2v3.7h-0.5v0.7h-0.6v0.7h-0.6v0.7h-0.7
				v0.7h-1.9L10,29.1l0,0v-0.6h0.1v-0.4h0.2v-0.3h0.2v-0.2H10v-2h0.9v-0.3H11V25H9.8c0-1.9-1.7-2.7-1.9-2.8
				c-0.2-0.1-0.6-1.6-0.6-1.6H6.8c0,0-0.4,1.5-0.6,1.6C5.9,22.3,4.3,23,4.3,25H3.1v0.3h0.1v0.3h0.9v2H3.6v0.2h0.2v0.3H4v0.4h0.1v0.6
				l0,0L1.7,52.1H0v22.6h595.3V52.1H592v-1.8h-0.2v-0.8h-0.1V36.1h0.2v-0.5h1.3c0-0.2,0-0.5,0-0.8c-0.4,0-0.8,0-1.3,0
				c0-0.1,0-0.2,0-0.4c-1.2,0-2.5,0-3.7,0c1.3,0,2.6,0,3.9,0c0-0.1,0-0.2,0-0.4c-0.5,0-1.1,0-1.6,0c0.6,0,1.2,0,1.9,0
				c0-0.1,0-0.2,0-0.4c-0.5,0-1,0-1.5,0c0.6,0,1.2,0,1.8,0c0-0.1,0-0.2,0-0.4c-0.5,0-1,0-1.5,0c0.6,0,1.2,0,1.9,0c0-0.1,0-0.2,0-0.4
				c-0.5,0-1,0-1.4,0c0.5,0,1.1,0,1.7,0c0-0.3,0-0.6,0-0.9c-0.5,0-0.9,0-1.3,0c0.8,0,1.6,0,2.4-0.1c0-0.2,0-0.4,0-0.6
				c-0.1,0-0.2,0-0.3,0c0.5,0,1,0,1.5-0.1v-1.9c-0.7,0-1.3,0.1-2,0.1L593.3,29.5z M571.2,32L571.2,32c-0.2,0-0.3,0-0.5,0
				c0.7,0,1.3,0,2,0C572.3,32,571.7,32,571.2,32z M560.1,35.8h2l-2,1.7V35.8z M552.7,25.9h1.1l-1.1,1V25.9z M340.7,34.8h3.1v1.4
				h-3.1V34.8z M338.8,34.8h0.9v1.4h-0.9V34.8z M292.7,32c-0.1,0-0.3,0-0.5,0l0,0c-0.5,0-1,0-1.6,0C291.4,32,292,32,292.7,32
				L292.7,32z M273.6,32L273.6,32c-0.1,0-0.3,0-0.5,0c0.7,0,1.4,0,2,0C274.6,32,274.1,32,273.6,32L273.6,32z M262.5,35.8h2l-2,1.7
				V35.8z M255,25.8h1.1l-1.1,1V25.8z M43,34.7h3.1v1.4H43V34.7z M41.2,34.7h0.9v1.4h-0.9V34.7z M27.2,49.3h-2.3v-0.8h2.3V49.3z
				 M27.5,48h-2.6v-1.3h0.1v-0.1h2.4L27.5,48L27.5,48z M27.5,46h-2.6v-1.5h0.1v-0.3h-0.3v-0.5h0.1v-0.3h-0.4v-0.1l0.2-0.8l-0.2-0.3
				v-3.4h3.1L27.5,46L27.5,46z M29.3,49.3h-0.6v-0.8h0.6V49.3z M29.3,48h-0.9v-1.4h0.9V48z M29.3,46h-0.9v-7.4h0.9V46z M29.3,36.1
				h-0.9v-1.4h0.9V36.1z M41.8,49.3h-0.6v-0.8h0.6V49.3z M42.1,48h-0.9v-1.4h0.9V48z M42.1,46h-0.9v-7.4h0.9V46z M45.6,49.3h-2.3
				v-0.8h2.3V49.3z M45.6,48H43v-1.4h2.4v0.1h0.1L45.6,48L45.6,48z M46.2,42.1l-0.2,0.3l0.2,0.8v0.1h-0.4v0.3h0.1v0.5h-0.3v0.3h0.1
				V46H43v-7.4h3.1L46.2,42.1L46.2,42.1z M66.5,65c-0.1,0.3-0.3,0.5-0.6,0.6s-0.6,0.2-0.9,0.2c-0.5,0-0.9-0.1-1.2-0.4
				s-0.5-0.6-0.5-1h0.8c0.1,0.2,0.2,0.4,0.3,0.5s0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.7-0.3c0.2-0.2,0.2-0.4,0.2-0.8s-0.1-0.6-0.2-0.7
				c-0.2-0.2-0.4-0.3-0.7-0.3c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.3-0.3,0.4h-0.7v-2.8h3v0.7h-2.2V63c0.1-0.1,0.2-0.2,0.4-0.3
				c0.2-0.1,0.4-0.1,0.6-0.1c0.4,0,0.7,0.1,0.9,0.2s0.4,0.4,0.5,0.6s0.2,0.5,0.2,0.8C66.7,64.5,66.7,64.8,66.5,65L66.5,65z
				 M70.6,65.2c-0.3,0.4-0.7,0.7-1.4,0.7s-1.1-0.2-1.4-0.7c-0.3-0.4-0.4-1.1-0.4-1.8s0.1-1.4,0.4-1.8s0.7-0.7,1.4-0.7
				s1.1,0.2,1.4,0.7c0.3,0.4,0.4,1,0.4,1.8S70.9,64.7,70.6,65.2z M74.9,65.2c-0.3,0.4-0.7,0.7-1.4,0.7s-1.1-0.2-1.4-0.7
				c-0.3-0.4-0.4-1.1-0.4-1.8s0.1-1.4,0.4-1.8s0.7-0.7,1.4-0.7s1.1,0.2,1.4,0.7c0.3,0.4,0.4,1,0.4,1.8S75.2,64.7,74.9,65.2z
				 M82.3,65.9h-0.8v-2.1c0-0.3-0.1-0.6-0.2-0.8c-0.2-0.2-0.4-0.3-0.7-0.3s-0.5,0.1-0.7,0.3c-0.2,0.2-0.2,0.4-0.2,0.8v2.1h-0.8v-2.1
				c0-0.3-0.1-0.6-0.2-0.8c-0.2-0.2-0.4-0.3-0.7-0.3s-0.5,0.1-0.7,0.3c-0.2,0.2-0.2,0.4-0.2,0.8v2.1h-0.8v-3.7H77v0.4
				c0.1-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.1,0.6-0.1c0.3,0,0.6,0.1,0.8,0.2s0.4,0.3,0.5,0.6c0.1-0.2,0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.8-0.2c0.3,0,0.6,0.1,0.8,0.2s0.4,0.3,0.6,0.5c0.1,0.2,0.2,0.5,0.2,0.9L82.3,65.9L82.3,65.9z M84,65.9h-0.8v-5
				H84V65.9z M108.7,40.1C108.7,40.1,108.7,40.1,108.7,40.1L108.7,40.1L108.7,40.1L108.7,40.1z M101.2,45.4l-0.2-0.3
				c0.1,0,0.1-0.1,0.2-0.1l0.2,0.4L101.2,45.4L101.2,45.4z M110.8,48.2c-0.4,0.4-0.8,0.9-1,1.4h-0.7v1.2h-1.4V52h-0.3v-0.2h-0.2
				v-0.2h-0.2v-0.2h-0.2v-0.2h-0.2v-1.1h-0.2v-0.3h-0.1v-0.4h-0.1c0-0.1,0-0.3-0.1-0.4c0.2-0.1,0.3-0.2,0.5-0.3l-0.3-0.4
				c0.1,0,0.1-0.1,0.2-0.1l0.3,0.4l0.6-0.4l-0.3-0.4c0.1,0,0.1-0.1,0.2-0.1l0.3,0.4l0.6-0.4l-0.3-0.4c0.1,0,0.1-0.1,0.2-0.1l0.3,0.4
				L109,47l0.6-0.4l-0.3-0.4c0.1,0,0.1-0.1,0.2-0.1l0.3,0.4l0.6-0.4l-0.3-0.4c0.1,0,0.1-0.1,0.2-0.1l0.3,0.4l0.3-0.2L110.8,48.2
				L110.8,48.2z M110.8,45.1L110.8,45.1C110.8,45.1,110.8,45.1,110.8,45.1L110.8,45.1L110.8,45.1z M110.8,42.5h-0.6v0.3h0.6v0.4
				h-0.6v0.3h0.6v0.6l-0.2,0.2l0.2,0.3v0.1c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.6,0.4l0.3,0.4c-0.1,0-0.1,0.1-0.2,0.1l-0.3-0.4
				l-0.6,0.4l0.3,0.4c-0.1,0-0.1,0.1-0.2,0.1l-0.3-0.4l-0.6,0.4l-0.6,0.4l0.3,0.4c-0.1,0-0.1,0.1-0.2,0.1l-0.3-0.4l-0.6,0.4l0.3,0.4
				c-0.1,0-0.1,0.1-0.2,0.1l-0.3-0.4l-0.6,0.4l0.3,0.4c-0.1,0-0.1,0.1-0.2,0.1l-0.3-0.4l-0.4,0.3c-0.8-1.2-2.3-2.3-2.3-2.3l0,0v-0.3
				h-1.8l0.6-0.3l-0.2-0.4c0.1,0,0.1-0.1,0.2-0.1l0.2,0.4l0.7-0.4l-0.2-0.4c0.1,0,0.1-0.1,0.2-0.1l0.3,0.4l0.7-0.4l0.6-0.4l-0.3-0.4
				c0.1,0,0.1-0.1,0.2-0.1l0.3,0.4l0.6-0.4l-0.3-0.4c0.1,0,0.1-0.1,0.2-0.1l0.3,0.4l0.6-0.4l-0.3-0.4c0.1,0,0.1-0.1,0.2-0.1l0.3,0.4
				l0.6-0.4l0.6-0.4l-0.3-0.4c0.1,0,0.1-0.1,0.2-0.1l0.3,0.4l0.6-0.4l-0.3-0.4c0.1,0,0.1-0.1,0.2-0.1l0.3,0.4l0.6-0.4l-0.3-0.4
				c0,0,0,0,0,0l2.1,2L110.8,42.5L110.8,42.5z M110.8,41.3l-1.2-1.1l0.2-0.1l0.6-0.4l0,0h0.4C110.8,39.6,110.8,41.3,110.8,41.3z
				 M111.9,34.3l-1.3-1.3h1.3V34.3z M138.8,14.7l0.1,0.1c0,0.1-0.1,0.1-0.1,0.1l-0.1-0.1L138.8,14.7L138.8,14.7z M138.7,14.1
				L138.7,14.1l0,0.2l-0.1-0.1C138.5,14.2,138.6,14.1,138.7,14.1L138.7,14.1z M138,14.8l0.2,0.2l-0.1,0.2l-0.2-0.2
				C137.9,14.9,138,14.8,138,14.8L138,14.8z M138.3,15.5c0,0.1-0.1,0.1-0.1,0.1l-0.2-0.2l0.1-0.1L138.3,15.5L138.3,15.5z
				 M137.3,15.4l0.2,0.2l-0.1,0.2l-0.3-0.3C137.2,15.6,137.3,15.5,137.3,15.4L137.3,15.4z M118.2,33.6l0.2-0.2l0.5-0.4h0.6l-1.3,1.3
				L118.2,33.6L118.2,33.6z M118.9,35.2h-0.7v-0.2l1.9-1.9h1v-0.7l-0.4-0.3l-0.1-0.1l0,0l-0.3-0.2l0,0l-0.3-0.4
				c0.1,0,0.1-0.1,0.1-0.1l0.3,0.4l0.6-0.5l0.6-0.5l-0.3-0.4c0.1,0,0.1-0.1,0.1-0.1l0.3,0.4l0.6-0.5l-0.3-0.4c0.1,0,0.1-0.1,0.1-0.1
				l0.3,0.4l0.6-0.5l-0.3-0.4c0.1,0,0.1-0.1,0.1-0.1l0.3,0.4l0.6-0.5l0.6-0.5l-0.3-0.4c0,0,0.1-0.1,0.1-0.1l0.3,0.4l0.6-0.5
				l-0.3-0.4c0,0,0.1-0.1,0.1-0.1l0.3,0.4l0.6-0.5l-0.3-0.4c0,0,0.1-0.1,0.1-0.1l0.3,0.4l0.6-0.5l0.6-0.5l-0.3-0.4
				c0,0,0.1-0.1,0.1-0.1l0.3,0.4l0.6-0.5l-0.3-0.4c0,0,0.1-0.1,0.1-0.1l0.3,0.4l0.6-0.5l-0.3-0.4c0,0,0.1-0.1,0.1-0.1l0.3,0.4
				l0.6-0.5l0.6-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.6-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.6-0.5l-0.4-0.4
				c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.6-0.5l0.5-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1
				l0.4,0.4l0.5-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.5l0.5-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.5l-0.4-0.4
				c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.4-0.4l0.4,0.3c0,0.1-0.1,0.1-0.1,0.1l-0.4-0.3
				l-0.4,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.1l-0.4-0.3l-0.4,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.1l-0.4-0.3l-0.4,0.6l-0.5,0.6l0.4,0.3
				c0,0.1-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6L134,21c0,0.1-0.1,0.1-0.1,0.1
				l-0.4-0.3l-0.5,0.6l-0.5,0.6l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6
				l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6l-0.5,0.6l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6l0.4,0.3
				c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6l-0.5,0.6l0.4,0.3c0,0-0.1,0.1-0.1,0.1
				l-0.4-0.3l-0.5,0.6l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.5l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.5l-0.5,0.5
				l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.5l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.5l0.4,0.3c0,0-0.1,0.1-0.1,0.1
				l-0.4-0.3l-0.5,0.5l-0.5,0.5l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.5l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.5
				l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.5l-0.5,0.5l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.5l0.4,0.3
				c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.1,0.1l-0.2-0.1l0,0l-0.8-0.6L118.9,35.2L118.9,35.2z M121.5,40.8c0,0-0.1,0.1-0.1,0.1l-0.3-0.4
				l-0.5,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.6,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.2,0.1l2.4-2.4h0.1l-0.3,0.3
				l-0.5,0.5L121.5,40.8L121.5,40.8z M119.3,41.3v-1.7h1.7L119.3,41.3z M133.8,26.9v0.7h-0.1l-0.3,1.5h0.4v3.7h-0.5v0.8h0
				c0,0-9.9,6.2-9.9,10.9h-0.4v1.1h-0.5v0.8h-0.6v3.1h-0.5v0.6H121v-0.6h-1.7v-5.9c0,0,0,0,0.1-0.1h0.5v-0.3h-0.1
				c0,0,0.1-0.1,0.1-0.1l0.6-0.5l-0.3-0.4c0,0,0.1-0.1,0.1-0.1l0.3,0.4l0.6-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.6-0.5
				l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.5l0.5-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.4-0.4h0.2v-0.4l0.1,0.1l0.5-0.6
				l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l-0.4-0.3
				c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2
				l0.4,0.3l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2l0.4,0.3l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2l0.4,0.3l0.5-0.6l0.5-0.6
				l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2l0.4,0.3l0.4-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2l0.4,0.3l0.4-0.6l-0.4-0.3
				c0-0.1,0.1-0.1,0.1-0.2l0.4,0.3l0.4-0.6l0.4-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2l0.4,0.3l0.4-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2
				l0.4,0.3l0.4-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2l0.4,0.3l0.3-0.5L133.8,26.9L133.8,26.9z M122.5,39.6h0.2
				C122.6,39.6,122.6,39.7,122.5,39.6L122.5,39.6z M135.4,25.1h-0.6v1.4h-1l0.1-0.1l0.4-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2l0.4,0.3
				l0.4-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2l0.4,0.3l0.4-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2l0.4,0.2L135.4,25.1L135.4,25.1z
				 M136.1,23.7h-0.4l0.2-0.4l0.2-0.3V23.7L136.1,23.7z M136.1,22.5L136.1,22.5l-0.2-0.1c0-0.1,0.1-0.1,0.1-0.2l0.1,0.1L136.1,22.5
				L136.1,22.5z M136.8,22.3h-0.3l0.3-0.4l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2l0.4,0.2L136.8,22.3L136.8,22.3z M137.4,20.9h-0.3
				l-0.2-0.1c0-0.1,0.1-0.1,0.1-0.2L137.4,20.9L137.4,20.9L137.4,20.9L137.4,20.9z M137.9,19.4l-0.2-0.1c0-0.1,0.1-0.1,0.1-0.2
				l0.1,0.1L137.9,19.4L137.9,19.4z M138.6,16.5l-0.4,0.7l0.2,0.1v0.2l-0.3-0.2l-0.4,0.7l0.2,0.1h-0.1v0.2l-0.3-0.1l-0.4,0.7
				l0.4,0.2c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.2l-0.4,0.6l-0.4,0.6l0.4,0.2c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.2l-0.4,0.6l0.4,0.3
				c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.4,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.4,0.6l-0.4,0.6l0.4,0.3
				c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.4,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.4,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2
				l-0.4-0.3l-0.4,0.6l-0.4,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.4,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.4,0.6
				l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.4,0.6l-0.4,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.4,0.6l0.4,0.3
				c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.4,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.4,0.6l-0.5,0.6l0.4,0.3
				c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.5,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.5,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2
				L127,34l-0.5,0.6l-0.5,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6
				l0.4,0.3c0,0.1-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6l-0.5,0.6l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.3,0.3l-0.9-0.6l-0.1-0.1
				l0,0l-1.5-1.1l-0.2-0.2c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.5l0.5-0.5l-0.4-0.4
				c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.5l-0.4-0.3c0,0,0.1-0.1,0.1-0.1l0.4,0.3
				l0.5-0.5l0.5-0.5l-0.4-0.3c0,0,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.5l-0.4-0.3c0,0,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.5l-0.4-0.3
				c0,0,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.5l0.5-0.6l-0.4-0.3c0,0,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l-0.4-0.3c0,0,0.1-0.1,0.1-0.1
				l0.4,0.3l0.5-0.6l-0.4-0.3c0,0,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l0.5-0.6l-0.4-0.3c0,0,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l-0.4-0.3
				c0,0,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l-0.4-0.3c0,0,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l0.5-0.6l-0.4-0.3c0,0,0.1-0.1,0.1-0.1
				l0.4,0.3l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l0.5-0.6
				l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l-0.4-0.3
				c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.4-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1
				l0.4,0.3l0.4-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.4-0.6l0.4-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.1-0.1
				L138.6,16.5L138.6,16.5z M139.3,16l-0.4-0.2l0.1-0.2l0.2,0.1L139.3,16L139.3,16z M139.2,15.3l-0.3-0.2c0-0.1,0.1-0.1,0.1-0.2
				l0.3,0.2L139.2,15.3L139.2,15.3z M139.3,14.2l0.1-0.1l0,0C139.5,14.1,139.4,14.2,139.3,14.2L139.3,14.2L139.3,14.2z M139.6,14.1
				l0.2,0.1l-0.1,0.2l-0.2-0.1C139.5,14.2,139.5,14.2,139.6,14.1L139.6,14.1z M139.8,15.2l-0.3-0.2l0.1-0.2l0.3,0.1
				C139.9,15.1,139.8,15.1,139.8,15.2L139.8,15.2z M140.9,15.3H140l-0.1-0.1c0-0.1,0.1-0.1,0.1-0.2l0.4,0.2l0.4-0.7l-0.4-0.2
				c0-0.1,0.1-0.1,0.1-0.2l0.4,0.2c0,0,0-0.1,0-0.1L140.9,15.3L140.9,15.3z M140.1,14.3l0.1-0.2l0.1,0.1c0,0.1-0.1,0.1-0.1,0.2
				L140.1,14.3L140.1,14.3z M140.4,9.2h-0.2l1.5-5.5l1.5,5.5H143l-1.3-4.7L140.4,9.2z M143.2,14.3l-0.1,0.1c0-0.1-0.1-0.1-0.1-0.2
				l0.1-0.1L143.2,14.3L143.2,14.3z M143.3,15.3h-0.9v-1c0,0,0,0.1,0,0.1l0.4-0.2c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.2l0.4,0.7l0.4-0.2
				c0,0.1,0.1,0.1,0.1,0.2L143.3,15.3L143.3,15.3z M166.9,33.8h0.3l-0.3,0.4c-0.1,0-0.1-0.1-0.2-0.1L166.9,33.8L166.9,33.8z
				 M146,15.4c0,0,0.1,0.1,0.1,0.1l-0.3,0.3l-0.1-0.2L146,15.4L146,15.4z M145.3,14.8c0,0,0.1,0.1,0.1,0.1l-0.2,0.2l-0.1-0.2
				L145.3,14.8L145.3,14.8z M145.2,15.4l0.1,0.1l-0.2,0.2c0-0.1-0.1-0.1-0.1-0.1L145.2,15.4L145.2,15.4z M144.6,14.1
				c0,0,0.1,0.1,0.1,0.1l-0.1,0.1L144.6,14.1L144.6,14.1L144.6,14.1z M144.5,14.7l0.1,0.1l-0.1,0.1c0-0.1-0.1-0.1-0.1-0.1
				L144.5,14.7L144.5,14.7z M143.5,15.2c0-0.1-0.1-0.1-0.1-0.2l0.3-0.1l0.1,0.2L143.5,15.2L143.5,15.2z M143.7,14.4l-0.1-0.2
				l0.2-0.1c0,0,0.1,0.1,0.1,0.2L143.7,14.4L143.7,14.4z M144,14.2c0-0.1-0.1-0.1-0.1-0.2l0,0L144,14.2L144,14.2L144,14.2z
				 M144.3,14.9c0,0.1,0.1,0.1,0.1,0.2l-0.3,0.2l-0.1-0.2L144.3,14.9L144.3,14.9z M144,16v-0.2l0.2-0.1l0.1,0.2L144,16L144,16z
				 M145.4,19.4v-0.2l0.1-0.1c0,0.1,0.1,0.1,0.1,0.2L145.4,19.4L145.4,19.4z M146.3,20.9h-0.3v-0.1l0,0l0.4-0.2
				c0,0.1,0.1,0.1,0.1,0.2L146.3,20.9L146.3,20.9z M146.8,22.3h-0.3v-0.7l0.4-0.2c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3L146.8,22.3
				L146.8,22.3z M147.3,23.7V23l0.2,0.3l0.2,0.4L147.3,23.7L147.3,23.7z M147.3,22.6v-0.2l0.1-0.1c0,0.1,0.1,0.1,0.1,0.2L147.3,22.6
				L147.3,22.6z M148.5,26.5v-1.4h-0.6V24l0.4-0.2c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.4,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3
				l0.4,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.4,0.6l0.1,0.1H148.5L148.5,26.5z M166.5,46.6h-0.4V47h0.4v1.3
				c-0.5,0.5-1,1.2-1,1.8h-0.1v0.3h-0.1v0.2h-0.1v0.8H165v0.1h-0.1v0.1h-0.1v0.1h-0.1V52h-0.9v-0.6h-0.6v-0.6h-0.6v-0.6H162v-0.6
				h-0.5v-3.1h-0.6v-0.8h-0.5v-1.1H160c0-4.7-9.9-10.9-9.9-10.9h0v-0.8h-0.5v-3.7h0.4l-0.3-1.5h-0.1v-1l0.3,0.5l0.4-0.3
				c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.4,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.4,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.2
				l-0.4,0.3l0.4,0.6l0.4,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.4,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.4,0.6
				l0.4-0.3c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.5,0.6l0.5,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.5,0.6l0.4-0.3
				c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.5,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.5,0.6l0.5,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.1
				l-0.4,0.3l0.5,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.5,0.6
				l0.4-0.3c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.6l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.6l0.4-0.4c0,0,0.1,0.1,0.1,0.1
				l-0.4,0.4l0.5,0.5l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.6,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.6,0.5l0.3-0.4
				c0,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5c0.2,0.2,0.4,0.3,0.6,0.5l0.3-0.4c0.1,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.3-0.4
				c0.1,0,0.1,0.1,0.2,0.1l-0.3,0.4c0.2,0.2,0.4,0.3,0.6,0.5l0.3-0.4c0.1,0,0.1,0.1,0.2,0.1l-0.3,0.4c0.2,0.2,0.4,0.3,0.6,0.5
				c0.1,0,0.1,0.1,0.1,0.1V46.6L166.5,46.6z M166.5,41.6h-0.4v0.3h0.4v2.3c0,0,0,0-0.1,0l-0.3,0.4c-0.1,0-0.1-0.1-0.2-0.1l0.3-0.4
				c-0.2-0.2-0.4-0.3-0.6-0.5l-0.3,0.4c-0.1,0-0.1-0.1-0.2-0.1l0.3-0.4L165,43l-0.3,0.4c-0.1,0-0.1-0.1-0.1-0.1l0.3-0.4
				c-0.2-0.2-0.4-0.3-0.6-0.5l-0.6-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.6-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4
				l-0.5-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5l-0.5-0.5l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.5l-0.4,0.3
				c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.5-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.1
				l0.4-0.3L158,36l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.5-0.6
				l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.5-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.5-0.6l-0.4,0.3
				c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.5-0.6l-0.4-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2L154,31l-0.4-0.6l-0.4,0.3
				c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.4-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.4-0.6l-0.4-0.6l-0.4,0.3
				c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.4-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.4-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2
				l0.4-0.3l-0.4-0.6l-0.4-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.4-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.4-0.6
				l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.4-0.6l-0.4-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.4-0.6l-0.4,0.3
				c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.4-0.6l-0.4,0.2c0-0.1-0.1-0.1-0.1-0.2l0.4-0.2l-0.4-0.6l-0.4-0.6l-0.4,0.2
				c0-0.1-0.1-0.1-0.1-0.2l0.4-0.2l-0.4-0.7l-0.3,0.1v-0.2h-0.1l0.2-0.1l-0.4-0.7l-0.3,0.2v-0.2l0.2-0.1l-0.4-0.7l-0.4-0.7l0.1,0.1
				l0.4-0.3c0,0.1,0.1,0.1,0.1,0.1l-0.4,0.3l0.4,0.6l0.4,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.1l-0.4,0.3l0.4,0.6l0.4-0.3
				c0,0.1,0.1,0.1,0.1,0.1l-0.4,0.3l0.4,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.5,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.1
				l-0.4,0.3l0.5,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.5,0.6
				l0.4-0.3c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.4-0.3c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.4-0.3c0,0,0.1,0.1,0.1,0.1
				l-0.4,0.3l0.5,0.6l0.5,0.6l0.4-0.3c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.4-0.3c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.4-0.3
				c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.5,0.6l0.4-0.3c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.4-0.3c0,0,0.1,0.1,0.1,0.1
				l-0.4,0.3l0.5,0.6l0.4-0.3c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.5,0.5l0.4-0.3c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.5l0.4-0.3
				c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.5l0.4-0.3c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.5l0.5,0.5l0.4-0.3c0,0,0.1,0.1,0.1,0.1
				l-0.4,0.3l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.5,0.5l0.4-0.4
				c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4
				l0.5,0.5l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.4-0.4
				c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.3,0.3L166.5,41.6L166.5,41.6z M167.7,35.9v1.3h-1.2V39l-0.1-0.1l-0.3,0.4
				c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4
				l-0.5-0.5l-0.5-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.5l-0.4,0.3
				c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.5l-0.5-0.5l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.5l-0.4,0.3c0,0-0.1-0.1-0.1-0.1
				l0.4-0.3l-0.5-0.5l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.5l-0.5-0.5l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.5
				l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.5l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.5l-0.5-0.5l-0.4,0.3
				c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.5l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3
				l-0.5-0.6l-0.5-0.6l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.4,0.3
				c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.5-0.6l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.4,0.3c0,0-0.1-0.1-0.1-0.1
				l0.4-0.3l-0.5-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.5-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6
				l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.4-0.6l-0.4,0.3
				c0-0.1-0.1-0.1-0.1-0.1l0.4-0.3l-0.4-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.1l0.4-0.3l-0.4-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.1
				l0.4-0.3l0.4,0.4l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.4-0.4
				c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1
				l-0.4,0.4l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5L152,22l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.6,0.5l0.4-0.4
				c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.6,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.6,0.5l0.6,0.5l0.3-0.4c0,0,0.1,0.1,0.1,0.1
				l-0.3,0.4l0.6,0.5l0.3-0.4c0,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.3-0.4c0,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.6,0.5l0.3-0.4
				c0,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.3-0.4c0,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.3-0.4c0,0,0.1,0.1,0.1,0.1l-0.3,0.4
				l0.6,0.5l0.6,0.5l0.3-0.4c0.1,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.3-0.4c0.1,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.3-0.4
				c0.1,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.6,0.5l0.3-0.4c0.1,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.3-0.4c0.1,0,0.1,0.1,0.1,0.1
				l-0.3,0.4l0.6,0.5l0.3-0.4c0.1,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.1,0.1v0.3h0.3l0.2,0.1l0.1-0.1h0.3l-0.2,0.3l0.6,0.5
				l0.3-0.4c0.1,0,0.1,0.1,0.2,0.1l-0.3,0.4l0.6,0.5l0.3-0.4c0.1,0,0.1,0.1,0.2,0.1l-0.3,0.4l0.5,0.4L167.7,35.9L167.7,35.9z
				 M167.7,34.6l-0.1,0.2c-0.1,0-0.1-0.1-0.2-0.1l0.3-0.4L167.7,34.6L167.7,34.6z M167.7,34.2l-0.6-0.4h0.6V34.2z M180.5,65.1
				c-0.1,0.3-0.4,0.5-0.7,0.6s-0.6,0.2-1.1,0.2c-0.4,0-0.7-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.7-0.6c-0.2-0.2-0.3-0.6-0.3-0.9h1.2
				c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.4,0.2,0.6,0.2s0.5-0.1,0.6-0.2s0.2-0.3,0.2-0.5c0-0.2-0.1-0.3-0.1-0.4
				c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1-0.1-0.4-0.1-0.6-0.2c-0.4-0.1-0.7-0.2-0.9-0.3c-0.2-0.1-0.4-0.3-0.6-0.5s-0.2-0.5-0.2-0.8
				c0-0.3,0.1-0.6,0.2-0.9c0.2-0.2,0.4-0.4,0.7-0.6s0.6-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4s0.6,0.7,0.6,1.2h-1.2c0-0.2-0.1-0.4-0.2-0.5
				s-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.5c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.4,0.2s0.4,0.1,0.6,0.2
				c0.4,0.1,0.7,0.2,0.9,0.3s0.4,0.3,0.6,0.5c0.2,0.2,0.2,0.5,0.2,0.8C180.8,64.5,180.7,64.8,180.5,65.1L180.5,65.1z M187.1,42.3
				v-0.3h0.4v-0.3h-0.4v-3.9h3.3v6.1h-0.5l-2.8,1.9V42.3z M187.1,47.3v-0.3h0.4v-0.3h-0.4v-0.8h3.3V52h-1.5v-0.2h-0.2v-0.2h-0.2
				v-0.2h-0.2v-0.2h-0.2v-1.1H188v-0.3h-0.1v-0.4h-0.1c0-0.5-0.3-1.1-0.7-1.6L187.1,47.3L187.1,47.3z M186,65.8h-1.1v-2.4
				c0-0.4-0.1-0.6-0.3-0.8c-0.2-0.2-0.4-0.3-0.7-0.3s-0.6,0.1-0.7,0.3s-0.3,0.5-0.3,0.8v2.4h-1.1v-5.9h1.1v2
				c0.1-0.2,0.3-0.3,0.6-0.4s0.5-0.2,0.8-0.2c0.3,0,0.6,0.1,0.9,0.2c0.3,0.1,0.5,0.4,0.6,0.6c0.1,0.3,0.2,0.6,0.2,1L186,65.8
				L186,65.8z M191.3,65.8h-1.1v-0.6c-0.1,0.2-0.3,0.4-0.6,0.5c-0.2,0.1-0.5,0.2-0.9,0.2c-0.4,0-0.7-0.1-1-0.3
				c-0.3-0.2-0.6-0.5-0.7-0.8c-0.2-0.4-0.3-0.8-0.3-1.2s0.1-0.8,0.3-1.2c0.2-0.4,0.4-0.6,0.7-0.8s0.6-0.3,1-0.3
				c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.6,0.5v-0.6h1.1L191.3,65.8L191.3,65.8z M195.1,65.8l-1.5-1.9v1.9h-1.1v-5.9h1.1v3.4
				l1.5-1.9h1.5l-1.9,2.2l2,2.2H195.1L195.1,65.8z M201.2,64H198c0,0.3,0.1,0.6,0.3,0.8s0.4,0.3,0.7,0.3c0.4,0,0.7-0.2,0.9-0.5h1.2
				c-0.1,0.4-0.4,0.8-0.7,1.1c-0.4,0.3-0.8,0.4-1.3,0.4c-0.4,0-0.8-0.1-1.1-0.3s-0.6-0.5-0.8-0.8s-0.3-0.8-0.3-1.2s0.1-0.9,0.3-1.2
				s0.4-0.6,0.8-0.8s0.7-0.3,1.2-0.3s0.8,0.1,1.1,0.3c0.3,0.2,0.6,0.4,0.8,0.8s0.3,0.7,0.3,1.1C201.2,63.7,201.2,63.8,201.2,64
				L201.2,64z M209.9,65.8h-1.3l-1.1-4l-1.1,4h-1.3l-1.5-5.6h1.2l1,4.3l1.1-4.3h1.2l1.1,4.3l1-4.3h1.2L209.9,65.8z M216.2,64H213
				c0,0.3,0.1,0.6,0.3,0.8s0.4,0.3,0.7,0.3c0.4,0,0.7-0.2,0.9-0.5h1.2c-0.1,0.4-0.4,0.8-0.7,1.1c-0.4,0.3-0.8,0.4-1.3,0.4
				c-0.4,0-0.8-0.1-1.1-0.3s-0.6-0.5-0.8-0.8s-0.3-0.8-0.3-1.2s0.1-0.9,0.3-1.2s0.4-0.6,0.8-0.8s0.7-0.3,1.2-0.3s0.8,0.1,1.1,0.3
				c0.3,0.2,0.6,0.4,0.8,0.8s0.3,0.7,0.3,1.1C216.3,63.7,216.3,63.8,216.2,64L216.2,64z M218.2,65.8h-1.1v-5.9h1.1V65.8z
				 M220.4,65.8h-1.1v-5.9h1.1V65.8z M226.8,35.8h2v1.7L226.8,35.8z M227.5,65.1c-0.1,0.2-0.4,0.4-0.6,0.5s-0.6,0.2-1,0.2h-2.4v-5.6
				h2.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.6,0.5s0.2,0.5,0.2,0.7c0,0.3-0.1,0.6-0.3,0.8s-0.4,0.4-0.7,0.5
				c0.3,0.1,0.6,0.2,0.8,0.5c0.2,0.3,0.3,0.6,0.3,0.9C227.7,64.6,227.6,64.9,227.5,65.1L227.5,65.1z M232.7,63.9h-3.2
				c0,0.3,0.1,0.6,0.3,0.8s0.4,0.3,0.7,0.3c0.4,0,0.7-0.2,0.9-0.5h1.2c-0.1,0.4-0.4,0.8-0.7,1.1c-0.4,0.3-0.8,0.4-1.3,0.4
				c-0.4,0-0.8-0.1-1.1-0.3s-0.6-0.5-0.8-0.8s-0.3-0.8-0.3-1.2s0.1-0.9,0.3-1.2s0.4-0.6,0.8-0.8s0.7-0.3,1.2-0.3s0.8,0.1,1.1,0.3
				c0.3,0.2,0.6,0.4,0.8,0.8s0.3,0.7,0.3,1.1C232.7,63.7,232.7,63.8,232.7,63.9L232.7,63.9z M235.5,62.3h-0.8v3.5h-1.1v-3.5h-0.5
				v-0.9h0.5v-0.2c0-0.5,0.1-0.9,0.5-1.2c0.3-0.3,0.8-0.4,1.4-0.4v0.9c-0.3,0-0.5,0-0.6,0.1s-0.2,0.3-0.2,0.5v0.2h0.8L235.5,62.3
				L235.5,62.3z M235.2,25.8h1.1v1L235.2,25.8z M240.2,64.8c-0.2,0.4-0.5,0.6-0.8,0.8s-0.7,0.3-1.2,0.3s-0.8-0.1-1.1-0.3
				s-0.6-0.5-0.8-0.8s-0.3-0.8-0.3-1.2s0.1-0.9,0.3-1.2c0.2-0.4,0.5-0.6,0.8-0.8s0.7-0.3,1.2-0.3s0.8,0.1,1.2,0.3
				c0.4,0.2,0.6,0.5,0.8,0.8c0.2,0.4,0.3,0.8,0.3,1.2S240.5,64.5,240.2,64.8L240.2,64.8z M243.9,62.5h-0.3c-0.4,0-0.6,0.1-0.8,0.2
				s-0.3,0.4-0.3,0.9v2.2h-1.1v-4.4h1.1v0.7c0.1-0.2,0.3-0.4,0.6-0.6s0.5-0.2,0.8-0.2L243.9,62.5L243.9,62.5z M248.7,63.9h-3.2
				c0,0.3,0.1,0.6,0.3,0.8s0.4,0.3,0.7,0.3c0.4,0,0.7-0.2,0.9-0.5h1.2c-0.1,0.4-0.4,0.8-0.7,1.1c-0.4,0.3-0.8,0.4-1.3,0.4
				c-0.4,0-0.8-0.1-1.1-0.3s-0.6-0.5-0.8-0.8s-0.3-0.8-0.3-1.2s0.1-0.9,0.3-1.2s0.5-0.6,0.8-0.8s0.7-0.3,1.2-0.3s0.8,0.1,1.1,0.3
				c0.3,0.2,0.6,0.4,0.8,0.8s0.3,0.7,0.3,1.1C248.7,63.7,248.7,63.8,248.7,63.9L248.7,63.9z M255.9,63.7c0,0.5-0.1,0.9-0.3,1.2
				c-0.2,0.3-0.5,0.6-0.8,0.7s-0.7,0.2-1.1,0.2s-0.8-0.1-1.1-0.2s-0.6-0.4-0.8-0.7s-0.3-0.7-0.3-1.2v-3.4h1.1v3.5
				c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.9v-3.5h1.1L255.9,63.7L255.9,63.7z M260.3,65.2
				c-0.1,0.2-0.3,0.4-0.6,0.5c-0.3,0.1-0.6,0.2-0.9,0.2s-0.7-0.1-1-0.2s-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.3-0.7h1.1
				c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.5,0.2s0.4,0,0.5-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1-0.1-0.2-0.2-0.3s-0.4-0.1-0.7-0.2
				c-0.3-0.1-0.6-0.2-0.8-0.2c-0.2-0.1-0.4-0.2-0.5-0.4s-0.2-0.4-0.2-0.7c0-0.2,0.1-0.5,0.2-0.7c0.1-0.2,0.3-0.4,0.6-0.5
				c0.3-0.1,0.6-0.2,0.9-0.2c0.5,0,1,0.1,1.3,0.4c0.3,0.3,0.5,0.6,0.5,1.1h-1.1c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.5-0.2
				c-0.2,0-0.4,0-0.5,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0.1,0.3,0.2,0.3s0.4,0.2,0.7,0.2s0.6,0.2,0.8,0.2c0.2,0.1,0.4,0.2,0.5,0.4
				s0.2,0.4,0.2,0.7C260.5,64.8,260.4,65.1,260.3,65.2L260.3,65.2z M265.5,63.9h-3.2c0,0.3,0.1,0.6,0.3,0.8s0.4,0.3,0.7,0.3
				c0.4,0,0.7-0.2,0.9-0.5h1.2c-0.1,0.4-0.4,0.8-0.7,1.1c-0.4,0.3-0.8,0.4-1.3,0.4c-0.4,0-0.8-0.1-1.1-0.3s-0.6-0.5-0.8-0.8
				s-0.3-0.8-0.3-1.2s0.1-0.9,0.3-1.2s0.5-0.6,0.8-0.8s0.7-0.3,1.2-0.3s0.8,0.1,1.1,0.3c0.3,0.2,0.6,0.4,0.8,0.8s0.3,0.7,0.3,1.1
				C265.5,63.7,265.5,63.8,265.5,63.9L265.5,63.9z M291.3,49.3h-0.1v0.8H291V52h-16.1v-1.8h-0.2v-0.8h-0.1V36h0.2v-0.5h16.5V36h0.2
				L291.3,49.3L291.3,49.3z M310.8,57.7h2.1V58h-1.6v1.2h1.3v0.4h-1.3v1.6h-0.5L310.8,57.7L310.8,57.7z M313.4,66.6h-2.3
				c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.5-0.1c0.1-0.1,0.2-0.2,0.3-0.4h0.5c-0.1,0.3-0.2,0.5-0.5,0.7
				c-0.2,0.2-0.5,0.3-0.9,0.3c-0.3,0-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.8-0.2s0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.2,0.7C313.4,66.5,313.4,66.6,313.4,66.6
				L313.4,66.6z M316,66.2h-2.1v-0.4h2.1V66.2z M316,60.7c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.5,0.2-0.8,0.2s-0.5-0.1-0.7-0.2
				s-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.7-0.2s0.5,0.1,0.7,0.2
				s0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.5,0.2,0.8S316.1,60.4,316,60.7L316,60.7z M386.8,59.1c0.1-0.2,0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.8-0.2s0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.2,0.7c0,0.1,0,0.2,0,0.3h-2.3
				c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.5-0.1c0.1-0.1,0.2-0.2,0.3-0.4h0.5c-0.1,0.3-0.2,0.5-0.5,0.7
				c-0.2,0.2-0.5,0.3-0.9,0.3c-0.3,0-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8S386.7,59.3,386.8,59.1L386.8,59.1z
				 M355.1,59.1c0.1-0.2,0.3-0.4,0.5-0.5s0.4-0.2,0.7-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.4,0.4v-1.5h0.5v3.9h-0.5v-0.5
				c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.2-0.6,0.2s-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8S355,59.3,355.1,59.1
				L355.1,59.1z M344.3,58.5c0.2-0.1,0.4-0.1,0.6-0.1v0.5h-0.1c-0.5,0-0.8,0.3-0.8,0.9v1.6h-0.5v-2.9h0.5v0.5
				C344,58.7,344.1,58.6,344.3,58.5L344.3,58.5z M340.6,38.6h3.1v3.4l-0.2,0.3l0.2,0.8v0.1h-0.4v0.3h0.1v0.5h-0.4v0.3h0.1V46h-2.6
				L340.6,38.6L340.6,38.6z M340.6,46.6h2.4v0.1h0.1V48h-2.6L340.6,46.6L340.6,46.6z M343.2,48.5v0.8h-2.3v-0.8H343.2z M340.2,59.1
				c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.8-0.2s0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.2,0.7
				c0,0.1,0,0.2,0,0.3h-2.3c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.5-0.1c0.1-0.1,0.2-0.2,0.3-0.4h0.5
				c-0.1,0.3-0.2,0.5-0.5,0.7c-0.2,0.2-0.5,0.3-0.9,0.3c-0.3,0-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8
				S340.1,59.3,340.2,59.1L340.2,59.1z M338.8,38.6h0.9V46h-0.9V38.6z M338.8,46.6h0.9v1.4h-0.9V46.6z M338.8,48.5h0.6v0.8h-0.6
				V48.5z M326.1,36.1v-1.4h0.9v1.4H326.1z M326.1,38.6h0.9V46h-0.9V38.6z M326.1,46.6h0.9v1.4h-0.9V46.6z M327,48.5v0.8h-0.6v-0.8
				H327z M322,43.2l0.2-0.8L322,42v-3.4h3.1V46h-2.6v-1.5h0.1v-0.3h-0.4v-0.5h0.1v-0.3L322,43.2L322,43.2L322,43.2z M322.6,47.9
				v-1.3h0.1v-0.1h2.4v1.4H322.6L322.6,47.9z M324.8,48.5v0.8h-2.3v-0.8H324.8z M320.2,59.1c0.1-0.2,0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.7-0.2c0.4,0,0.6,0.1,0.9,0.3s0.4,0.4,0.5,0.7h-0.5c0-0.2-0.1-0.3-0.3-0.4c-0.1-0.1-0.3-0.1-0.5-0.1
				c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.2,0.4-0.2,0.8s0.1,0.6,0.2,0.8s0.4,0.3,0.7,0.3c0.2,0,0.4-0.1,0.5-0.1
				c0.1-0.1,0.2-0.2,0.3-0.4h0.5c-0.1,0.3-0.2,0.5-0.5,0.7c-0.2,0.2-0.5,0.3-0.9,0.3c-0.3,0-0.5-0.1-0.7-0.2
				c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8S320,59.3,320.2,59.1L320.2,59.1z M316.8,58.4h0.5v0.5c0.1-0.2,0.2-0.3,0.4-0.4
				s0.4-0.1,0.6-0.1v0.5h-0.1c-0.5,0-0.8,0.3-0.8,0.9v1.6h-0.5L316.8,58.4L316.8,58.4z M321.6,67.9h-0.5v-1.6c0-0.3-0.1-0.5-0.2-0.7
				c-0.1-0.1-0.3-0.2-0.6-0.2s-0.5,0.1-0.6,0.2s-0.2,0.4-0.2,0.7v1.6H319v-1.6c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.1-0.3-0.2-0.6-0.2
				s-0.5,0.1-0.6,0.2s-0.2,0.4-0.2,0.7v1.6h-0.5V65h0.5v0.4c0.1-0.1,0.2-0.3,0.4-0.4s0.3-0.1,0.5-0.1c0.2,0,0.5,0.1,0.6,0.2
				c0.2,0.1,0.3,0.3,0.4,0.5c0.1-0.2,0.2-0.4,0.4-0.5s0.4-0.2,0.6-0.2s0.4,0.1,0.6,0.1s0.3,0.2,0.4,0.4s0.1,0.4,0.1,0.7L321.6,67.9
				L321.6,67.9z M325.1,67.9h-0.5v-0.5c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.2-0.7,0.2s-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5
				s-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5s0.4-0.2,0.7-0.2s0.5,0.1,0.7,0.2s0.3,0.2,0.4,0.4V65h0.5V67.9
				L325.1,67.9z M325.1,61.2c-0.2,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.4-0.1-0.6-0.1s-0.3-0.2-0.4-0.4s-0.2-0.4-0.2-0.7v-1.7h0.5V60
				c0,0.3,0.1,0.5,0.2,0.7c0.1,0.1,0.3,0.2,0.6,0.2s0.5-0.1,0.6-0.2s0.2-0.4,0.2-0.7v-1.6h0.5v2.9h-0.5v-0.4
				C325.3,61,325.2,61.1,325.1,61.2L325.1,61.2z M326.4,67.9h-0.5V65h0.5V67.9z M326.4,64.4c-0.1,0.1-0.1,0.1-0.2,0.1
				s-0.2,0-0.2-0.1s-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1s0.2,0,0.2,0.1s0.1,0.1,0.1,0.2S326.5,64.4,326.4,64.4z
				 M327.7,67.9h-0.5V64h0.5V67.9z M328.7,60.9c-0.1,0.1-0.2,0.2-0.4,0.3s-0.3,0.1-0.5,0.1c-0.2,0-0.4,0-0.6-0.1
				c-0.2-0.1-0.3-0.2-0.4-0.3s-0.2-0.3-0.2-0.5h0.5c0,0.1,0.1,0.3,0.2,0.4s0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.4-0.1
				c0.1-0.1,0.2-0.2,0.2-0.3s-0.1-0.2-0.2-0.3s-0.3-0.1-0.5-0.2c-0.2-0.1-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.1-0.4-0.3
				c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.3,0.1-0.4s0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.5-0.1c0.3,0,0.6,0.1,0.8,0.2
				c0.2,0.2,0.3,0.4,0.3,0.7h-0.5c0-0.1-0.1-0.3-0.2-0.4s-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.4,0.1s-0.1,0.2-0.1,0.3
				c0,0.1,0,0.2,0.1,0.2s0.1,0.1,0.2,0.1s0.2,0.1,0.4,0.1c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.4
				C328.8,60.7,328.8,60.8,328.7,60.9L328.7,60.9z M330.3,67.8c-0.1,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.2-0.1s-0.1-0.1-0.1-0.2
				s0-0.2,0.1-0.2s0.1-0.1,0.2-0.1s0.2,0,0.2,0.1s0.1,0.1,0.1,0.2S330.4,67.8,330.3,67.8z M330.3,65.6c-0.1,0.1-0.1,0.1-0.2,0.1
				s-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2s0.1-0.1,0.2-0.1s0.2,0,0.2,0.1s0.1,0.1,0.1,0.2S330.4,65.5,330.3,65.6z
				 M330.8,58.8h-0.8v1.7c0,0.1,0,0.2,0.1,0.3s0.2,0.1,0.3,0.1h0.4v0.4h-0.4c-0.3,0-0.5-0.1-0.6-0.2s-0.2-0.3-0.2-0.6v-1.7h-0.4
				v-0.4h0.4v-0.7h0.5v0.7h0.8V58.8L330.8,58.8z M332.9,67.9h-0.5V65h0.5V67.9z M332.9,64.4c-0.1,0.1-0.1,0.1-0.2,0.1
				s-0.2,0-0.2-0.1s-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1s0.2,0,0.2,0.1s0.1,0.1,0.1,0.2S333,64.4,332.9,64.4z
				 M333.4,61.2c-0.2,0.1-0.5,0.2-0.8,0.2s-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8
				c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.7-0.2s0.5,0.1,0.7,0.2s0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.5,0.2,0.8s-0.1,0.6-0.2,0.8
				S333.6,61,333.4,61.2L333.4,61.2z M336.3,67.9h-0.5v-1.6c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.1-0.3-0.2-0.6-0.2s-0.5,0.1-0.6,0.2
				s-0.2,0.4-0.2,0.7v1.6h-0.5V65h0.5v0.4c0.1-0.1,0.2-0.3,0.4-0.3c0.2-0.1,0.3-0.1,0.5-0.1c0.4,0,0.6,0.1,0.9,0.3s0.3,0.5,0.3,0.9
				L336.3,67.9L336.3,67.9z M336.1,58.8c-0.2,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.2,0.7v1.6h-0.5v-2.9h0.5v0.4c0.1-0.1,0.2-0.3,0.4-0.4
				s0.3-0.1,0.5-0.1c0.2,0,0.5,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1-0.2,0.2-0.4,0.4-0.5s0.4-0.2,0.6-0.2s0.4,0.1,0.6,0.1
				s0.3,0.2,0.4,0.4s0.1,0.4,0.1,0.7v1.7h-0.5v-1.6c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.1-0.3-0.2-0.6-0.2s-0.5,0.1-0.6,0.2
				s-0.2,0.4-0.2,0.7v1.6h-0.5v-1.6c0-0.3-0.1-0.5-0.2-0.7C336.5,58.8,336.3,58.8,336.1,58.8L336.1,58.8z M338.3,65.4h-0.6v2.5h-0.5
				v-2.5h-0.4V65h0.4v-0.2c0-0.3,0.1-0.6,0.2-0.7c0.2-0.1,0.4-0.2,0.8-0.2v0.4c-0.2,0-0.4,0-0.5,0.1s-0.1,0.2-0.1,0.4V65h0.6
				L338.3,65.4L338.3,65.4z M341.4,67.2c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.5,0.2-0.8,0.2s-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5
				c-0.1-0.2-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.7-0.2s0.5,0.1,0.7,0.2s0.4,0.3,0.5,0.5
				c0.1,0.2,0.2,0.5,0.2,0.8S341.5,67,341.4,67.2L341.4,67.2z M346.6,67.1c-0.1,0.2-0.2,0.5-0.4,0.6s-0.4,0.2-0.6,0.2
				c-0.2,0-0.3,0-0.4-0.1s-0.1-0.2-0.2-0.4c-0.1,0.1-0.2,0.3-0.4,0.4c-0.2,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.5-0.1-0.6-0.2
				c-0.1-0.2-0.2-0.4-0.2-0.7s0-0.5,0.2-0.7c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.1,0.4-0.2,0.7-0.2c0.3,0,0.6,0.1,0.7,0.4l0.1-0.3h0.4
				l-0.3,1.5c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2,0.1-0.4,0.1-0.6
				c0-0.5-0.1-0.8-0.4-1.1c-0.3-0.2-0.7-0.4-1.2-0.4c-0.4,0-0.8,0.1-1.1,0.3c-0.3,0.2-0.6,0.5-0.8,0.8s-0.3,0.7-0.3,1.2
				c0,0.5,0.1,0.8,0.4,1.1s0.7,0.4,1.2,0.4c0.3,0,0.6-0.1,0.9-0.2l0.1,0.3c-0.3,0.1-0.7,0.2-1,0.2s-0.7-0.1-1-0.2
				c-0.3-0.1-0.5-0.4-0.7-0.6c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.5,0.1-0.9,0.3-1.4s0.5-0.7,1-1s0.9-0.4,1.4-0.4c0.4,0,0.7,0.1,1,0.2
				s0.5,0.4,0.7,0.6s0.2,0.6,0.2,0.9C346.8,66.5,346.7,66.8,346.6,67.1L346.6,67.1z M346.6,58.8v-0.4h0.4v-0.2
				c0-0.3,0.1-0.6,0.2-0.7c0.2-0.1,0.4-0.2,0.8-0.2v0.4c-0.2,0-0.4,0-0.5,0.1s-0.1,0.2-0.1,0.4v0.2h0.6v0.4h-0.6v2.5h-0.5v-2.5
				L346.6,58.8L346.6,58.8z M350.1,67.2c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.5,0.2-0.8,0.2s-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5
				c-0.1-0.2-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.7-0.2s0.5,0.1,0.7,0.2s0.4,0.3,0.5,0.5
				c0.1,0.2,0.2,0.5,0.2,0.8S350.2,67,350.1,67.2L350.1,67.2z M349.8,61.3c-0.3,0-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5
				s-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.8-0.2s0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.3,0.5,0.5
				c0.1,0.2,0.2,0.4,0.2,0.7c0,0.1,0,0.2,0,0.3h-2.3c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.5-0.1
				c0.1-0.1,0.2-0.2,0.3-0.4h0.5c-0.1,0.3-0.2,0.5-0.5,0.7C350.5,61.3,350.2,61.3,349.8,61.3L349.8,61.3z M353.7,67.9h-0.5v-0.5
				c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.2-0.7,0.2s-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8
				c0.1-0.2,0.3-0.4,0.5-0.5s0.4-0.2,0.7-0.2s0.5,0.1,0.7,0.2s0.3,0.2,0.4,0.4V65h0.5V67.9L353.7,67.9z M353.1,61.3
				c-0.3,0-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.8-0.2
				s0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.2,0.7c0,0.1,0,0.2,0,0.3h-2.3c0,0.3,0.1,0.5,0.3,0.7
				c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.5-0.1c0.1-0.1,0.2-0.2,0.3-0.4h0.5c-0.1,0.3-0.2,0.5-0.5,0.7
				C353.7,61.3,353.4,61.3,353.1,61.3L353.1,61.3z M355.8,65.4h-0.8v1.7c0,0.1,0,0.2,0.1,0.3s0.2,0.1,0.3,0.1h0.4v0.4h-0.4
				c-0.3,0-0.5-0.1-0.6-0.2s-0.2-0.3-0.2-0.6v-1.7h-0.4V65h0.4v-0.7h0.5V65h0.8V65.4L355.8,65.4z M358.4,67.5
				c-0.1,0.1-0.2,0.2-0.4,0.3s-0.3,0.1-0.5,0.1c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.4-0.3s-0.2-0.3-0.2-0.5h0.5
				c0,0.1,0.1,0.3,0.2,0.4s0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.3s-0.1-0.2-0.2-0.3s-0.3-0.1-0.5-0.2
				c-0.2-0.1-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.3,0.1-0.4s0.2-0.2,0.4-0.3
				c0.2-0.1,0.4-0.1,0.5-0.1c0.3,0,0.6,0.1,0.8,0.2c0.2,0.2,0.3,0.4,0.3,0.7H358c0-0.1-0.1-0.3-0.2-0.4s-0.3-0.1-0.5-0.1
				c-0.2,0-0.3,0-0.4,0.1s-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.2s0.1,0.1,0.2,0.1s0.2,0.1,0.4,0.1c0.2,0.1,0.4,0.1,0.5,0.2
				c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.4C358.5,67.2,358.4,67.4,358.4,67.5L358.4,67.5z M359.1,61.3h-0.5v-3.9h0.5v1.6
				c0.1-0.2,0.2-0.3,0.4-0.4s0.4-0.2,0.6-0.2c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.3,0.5,0.5s0.2,0.5,0.2,0.8s-0.1,0.6-0.2,0.8
				s-0.3,0.4-0.5,0.5s-0.4,0.2-0.7,0.2s-0.5-0.1-0.7-0.2s-0.3-0.2-0.4-0.4L359.1,61.3L359.1,61.3z M361.9,67.2
				c-0.1,0.2-0.3,0.4-0.5,0.5s-0.4,0.2-0.7,0.2s-0.5-0.1-0.7-0.2s-0.3-0.2-0.4-0.4v0.5h-0.5V64h0.5v1.6c0.1-0.2,0.2-0.3,0.4-0.4
				s0.4-0.2,0.6-0.2c0.3,0,0.5,0.1,0.7,0.2s0.4,0.3,0.5,0.5s0.2,0.5,0.2,0.8S362,67,361.9,67.2L361.9,67.2z M362.1,59.8
				c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5s0.4-0.2,0.7-0.2s0.5,0.1,0.7,0.2s0.3,0.2,0.4,0.4v-0.5h0.5v2.9h-0.5v-0.5
				c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.2-0.7,0.2s-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5S362.1,60.1,362.1,59.8L362.1,59.8z
				 M363.5,69.2H363l0.6-1.4l-1.2-2.9h0.5l0.9,2.3l0.9-2.3h0.5L363.5,69.2L363.5,69.2z M368.3,67.9h-0.5v-1.6c0-0.3-0.1-0.5-0.2-0.7
				c-0.1-0.1-0.3-0.2-0.6-0.2s-0.5,0.1-0.6,0.2s-0.2,0.4-0.2,0.7v1.6h-0.5V65h0.5v0.4c0.1-0.1,0.2-0.3,0.4-0.3
				c0.2-0.1,0.3-0.1,0.5-0.1c0.4,0,0.6,0.1,0.9,0.3s0.3,0.5,0.3,0.9L368.3,67.9L368.3,67.9z M366.4,60.6c0.2,0.2,0.4,0.3,0.7,0.3
				c0.2,0,0.4-0.1,0.5-0.1c0.1-0.1,0.2-0.2,0.3-0.4h0.5c-0.1,0.3-0.2,0.5-0.5,0.7c-0.2,0.2-0.5,0.3-0.9,0.3c-0.3,0-0.5-0.1-0.7-0.2
				c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.7-0.2c0.4,0,0.6,0.1,0.9,0.3
				s0.4,0.4,0.5,0.7h-0.5c0-0.2-0.1-0.3-0.3-0.4c-0.1-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.2,0.4-0.2,0.8
				S366.2,60.5,366.4,60.6L366.4,60.6z M369,57.4h0.5v2.3l1.1-1.3h0.7l-1.4,1.4l1.4,1.4h-0.6l-1.1-1.3v1.3H369V57.4z M371.6,67.9
				h-0.5v-0.4c-0.1,0.1-0.2,0.3-0.4,0.4c-0.2,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.4-0.1-0.6-0.1s-0.3-0.2-0.4-0.4s-0.2-0.4-0.2-0.7V65h0.5
				v1.6c0,0.3,0.1,0.5,0.2,0.7c0.1,0.1,0.3,0.2,0.6,0.2s0.5-0.1,0.6-0.2s0.2-0.4,0.2-0.7V65h0.5L371.6,67.9L371.6,67.9z M374.4,67.5
				c-0.1,0.1-0.2,0.2-0.4,0.3s-0.3,0.1-0.5,0.1c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.4-0.3s-0.2-0.3-0.2-0.5h0.5
				c0,0.1,0.1,0.3,0.2,0.4s0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.3s-0.1-0.2-0.2-0.3s-0.3-0.1-0.5-0.2
				c-0.2-0.1-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.3,0.1-0.4s0.2-0.2,0.4-0.3
				c0.2-0.1,0.4-0.1,0.5-0.1c0.3,0,0.6,0.1,0.8,0.2c0.2,0.2,0.3,0.4,0.3,0.7H374c0-0.1-0.1-0.3-0.2-0.4s-0.3-0.1-0.5-0.1
				c-0.2,0-0.3,0-0.4,0.1s-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.2s0.1,0.1,0.2,0.1s0.2,0.1,0.4,0.1c0.2,0.1,0.4,0.1,0.5,0.2
				c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.4C374.5,67.2,374.5,67.4,374.4,67.5L374.4,67.5z M374.4,61.3
				c-0.3,0-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.7-0.2s0.5,0.1,0.7,0.2s0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.5,0.2,0.8s-0.1,0.6-0.2,0.8s-0.3,0.4-0.5,0.5
				C374.9,61.3,374.6,61.3,374.4,61.3L374.4,61.3z M377.8,67.9h-0.5v-1.6c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.1-0.3-0.2-0.6-0.2
				s-0.5,0.1-0.6,0.2s-0.2,0.4-0.2,0.7v1.6h-0.5V64h0.5v1.4c0.1-0.1,0.2-0.3,0.4-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0.1,0.6,0.1
				s0.3,0.2,0.4,0.4s0.1,0.4,0.1,0.7V67.9L377.8,67.9z M377.9,58.8h-0.1c-0.5,0-0.8,0.3-0.8,0.9v1.6h-0.5v-2.9h0.5v0.5
				c0.1-0.2,0.2-0.3,0.4-0.4s0.4-0.1,0.6-0.1L377.9,58.8L377.9,58.8z M378.9,67.8c-0.1,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.2-0.1
				s-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2s0.1-0.1,0.2-0.1s0.2,0,0.2,0.1s0.1,0.1,0.1,0.2S379,67.7,378.9,67.8z M380.2,67.2
				c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.4-0.1,0.5-0.1c0.1-0.1,0.2-0.2,0.3-0.4h0.5c-0.1,0.3-0.2,0.5-0.5,0.7c-0.2,0.2-0.5,0.3-0.9,0.3
				c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.7-0.2
				c0.4,0,0.6,0.1,0.9,0.3s0.4,0.4,0.5,0.7h-0.5c0-0.2-0.1-0.3-0.3-0.4c-0.1-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.7,0.3
				c-0.2,0.2-0.2,0.4-0.2,0.8S380.1,67,380.2,67.2L380.2,67.2z M382.6,62.6h-0.5v-1.9c-0.1,0.2-0.2,0.3-0.4,0.4s-0.4,0.2-0.7,0.2
				s-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5s0.4-0.2,0.7-0.2s0.5,0.1,0.7,0.2
				s0.3,0.2,0.4,0.4v-0.5h0.5V62.6L382.6,62.6z M385.4,67.2c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.5,0.2-0.8,0.2s-0.5-0.1-0.7-0.2
				s-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.7-0.2s0.5,0.1,0.7,0.2
				s0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.5,0.2,0.8S385.5,67,385.4,67.2L385.4,67.2z M386,61.3h-0.5v-0.4c-0.1,0.1-0.2,0.3-0.4,0.4
				c-0.2,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.4-0.1-0.6-0.1s-0.3-0.2-0.4-0.4s-0.2-0.4-0.2-0.7v-1.7h0.5V60c0,0.3,0.1,0.5,0.2,0.7
				c0.1,0.1,0.3,0.2,0.6,0.2s0.5-0.1,0.6-0.2s0.2-0.4,0.2-0.7v-1.6h0.5L386,61.3L386,61.3z M390.9,67.9h-0.5v-1.6
				c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.1-0.3-0.2-0.6-0.2s-0.5,0.1-0.6,0.2s-0.2,0.4-0.2,0.7v1.6h-0.5v-1.6c0-0.3-0.1-0.5-0.2-0.7
				c-0.1-0.1-0.3-0.2-0.6-0.2s-0.5,0.1-0.6,0.2s-0.2,0.4-0.2,0.7v1.6h-0.5V65h0.5v0.4c0.1-0.1,0.2-0.3,0.4-0.4s0.3-0.1,0.5-0.1
				c0.2,0,0.5,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1-0.2,0.2-0.4,0.4-0.5s0.4-0.2,0.6-0.2s0.4,0.1,0.6,0.1s0.3,0.2,0.4,0.4
				s0.1,0.4,0.1,0.7L390.9,67.9L390.9,67.9z M391.5,58.8h-0.1c-0.5,0-0.8,0.3-0.8,0.9v1.6H390v-2.9h0.5v0.5c0.1-0.2,0.2-0.3,0.4-0.4
				s0.4-0.1,0.6-0.1L391.5,58.8L391.5,58.8z M392.5,61.3H392v-2.9h0.5V61.3z M392.5,57.8c-0.1,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.2-0.1
				s-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1s0.2,0,0.2,0.1s0.1,0.1,0.1,0.2S392.6,57.8,392.5,57.8z M395.9,60h-2.3
				c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.5-0.1c0.1-0.1,0.2-0.2,0.3-0.4h0.5c-0.1,0.3-0.2,0.5-0.5,0.7
				c-0.2,0.2-0.5,0.3-0.9,0.3c-0.3,0-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.8-0.2s0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.2,0.7C396,59.8,396,59.9,395.9,60L395.9,60
				z M408.3,33h1.3v1.3L408.3,33z M407.5,40l0.6-0.4l0,0h0.4v1.7l-1.2-1.1L407.5,40L407.5,40z M406.3,40C406.3,40,406.3,40,406.3,40
				L406.3,40L406.3,40L406.3,40z M398.5,60.9c-0.1,0.1-0.2,0.2-0.4,0.3s-0.3,0.1-0.5,0.1c-0.2,0-0.4,0-0.6-0.1
				c-0.2-0.1-0.3-0.2-0.4-0.3s-0.2-0.3-0.2-0.5h0.5c0,0.1,0.1,0.3,0.2,0.4s0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.4-0.1
				c0.1-0.1,0.2-0.2,0.2-0.3s-0.1-0.2-0.2-0.3s-0.3-0.1-0.5-0.2c-0.2-0.1-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.1-0.4-0.3
				c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.3,0.1-0.4s0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.5-0.1c0.3,0,0.6,0.1,0.8,0.2
				c0.2,0.2,0.3,0.4,0.3,0.7h-0.5c0-0.1-0.1-0.3-0.2-0.4s-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.4,0.1S397,59,397,59.1
				c0,0.1,0,0.2,0.1,0.2s0.1,0.1,0.2,0.1s0.2,0.1,0.4,0.1c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.4
				C398.6,60.6,398.6,60.8,398.5,60.9L398.5,60.9z M398.9,45.3l-0.2-0.3c0.1,0,0.1-0.1,0.2-0.1l0.2,0.4L398.9,45.3L398.9,45.3z
				 M399.8,45l-0.2-0.4c0.1,0,0.1-0.1,0.2-0.1l0.2,0.4l0.6-0.4l-0.2-0.4c0.1,0,0.1-0.1,0.2-0.1l0.3,0.4l0.6-0.4l0.6-0.4l-0.3-0.4
				c0.1,0,0.1-0.1,0.2-0.1l0.3,0.4l0.6-0.4l-0.3-0.4c0.1,0,0.1-0.1,0.2-0.1l0.3,0.4l0.6-0.4l-0.3-0.4c0.1,0,0.1-0.1,0.2-0.1l0.3,0.4
				l0.6-0.4l0.6-0.4l-0.3-0.4c0.1,0,0.1-0.1,0.2-0.1l0.3,0.4l0.6-0.4l-0.3-0.4c0,0,0.1-0.1,0.2-0.1l0.3,0.4l0.6-0.4l-0.3-0.4
				c0,0,0,0,0,0l2,2v0.3h-0.6v0.3h0.6v0.4h-0.6v0.3h0.6v0.6l-0.2,0.2l0.2,0.3v0.1c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.6,0.4l0.3,0.4
				c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.6,0.4l0.3,0.4c0,0-0.1,0.1-0.2,0.1l-0.3-0.4l-0.6,0.4l-0.6,0.4l0.3,0.4c0,0-0.1,0.1-0.2,0.1
				l-0.3-0.4l-0.6,0.4l0.3,0.4c0,0-0.1,0.1-0.2,0.1l-0.3-0.4l-0.6,0.4l0.3,0.4c0,0-0.1,0.1-0.2,0.1l-0.3-0.4l-0.4,0.3
				c-0.9-1.2-2.4-2.3-2.4-2.3l0,0v-0.3h-1.8L399.8,45L399.8,45z M408.5,45L408.5,45L408.5,45C408.4,45,408.5,45,408.5,45L408.5,45z
				 M403.7,61.3h-0.5l-0.7-2.3l-0.7,2.3h-0.5l-0.9-2.9h0.5l0.7,2.4l0.7-2.4h0.5l0.7,2.4l0.6-2.4h0.5L403.7,61.3L403.7,61.3z
				 M406.5,58.8h-0.1c-0.5,0-0.8,0.3-0.8,0.9v1.6H405v-2.9h0.5v0.5c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.6-0.1L406.5,58.8
				L406.5,58.8z M406.8,49.8v0.9h-1.4v1.2h-0.3v-0.2h-0.2v-0.2h-0.2v-0.2h-0.2v-0.2h-0.2V50h-0.2v-0.3h-0.1v-0.4h-0.1
				c0-0.1,0-0.3-0.1-0.4c0.2-0.1,0.3-0.2,0.5-0.3l-0.3-0.4c0,0,0.1-0.1,0.2-0.1l0.3,0.4l0.6-0.4l-0.3-0.4c0,0,0.1-0.1,0.2-0.1
				l0.3,0.4l0.6-0.4l-0.3-0.4c0,0,0.1-0.1,0.2-0.1l0.3,0.4l0.6-0.4l0.6-0.4l-0.3-0.4c0,0,0.1-0.1,0.2-0.1l0.3,0.4l0.6-0.4l-0.3-0.4
				c0,0,0.1-0.1,0.1-0.1l0.3,0.4l0.3-0.2v2.5c-0.4,0.4-0.8,0.9-1,1.4h-0.7L406.8,49.8L406.8,49.8z M407.5,61.3H407v-2.9h0.5V61.3z
				 M407.5,57.8c-0.1,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.2-0.1s-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1s0.2,0,0.2,0.1
				s0.1,0.1,0.1,0.2S407.6,57.8,407.5,57.8z M409.7,58.8h-0.8v1.7c0,0.1,0,0.2,0.1,0.3s0.2,0.1,0.3,0.1h0.4v0.4h-0.4
				c-0.3,0-0.5-0.1-0.6-0.2s-0.2-0.3-0.2-0.6v-1.7h-0.4v-0.4h0.4v-0.7h0.5v0.7h0.8V58.8L409.7,58.8z M412.9,60h-2.3
				c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.5-0.1c0.1-0.1,0.2-0.2,0.3-0.4h0.5c-0.1,0.3-0.2,0.5-0.5,0.7
				c-0.2,0.2-0.5,0.3-0.9,0.3c-0.3,0-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.8-0.2s0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.2,0.7C412.9,59.8,412.9,59.9,412.9,60
				L412.9,60z M436.4,14.7l0.1,0.1c0,0.1-0.1,0.1-0.1,0.1l-0.1-0.1L436.4,14.7L436.4,14.7z M436.3,14L436.3,14l0,0.2l-0.1-0.1
				C436.2,14.2,436.3,14.1,436.3,14L436.3,14z M435.7,14.7l0.2,0.2l-0.1,0.2l-0.2-0.2C435.5,14.9,435.6,14.8,435.7,14.7L435.7,14.7z
				 M436,15.5c0,0.1-0.1,0.1-0.1,0.1l-0.2-0.2l0.1-0.1L436,15.5L436,15.5z M435,15.4l0.2,0.2l-0.1,0.2l-0.3-0.3
				C434.9,15.5,435,15.4,435,15.4L435,15.4z M415.8,33.6l0.2-0.2l0.5-0.4h0.6l-1.3,1.3V33.6L415.8,33.6z M416.3,58.8h-0.8v1.7
				c0,0.1,0,0.2,0.1,0.3s0.2,0.1,0.3,0.1h0.4v0.4h-0.4c-0.3,0-0.5-0.1-0.6-0.2s-0.2-0.3-0.2-0.6v-1.7h-0.4v-0.4h0.4v-0.7h0.5v0.7
				h0.8V58.8L416.3,58.8z M416.5,35.1h-0.7v-0.2l2-1.9h1v-0.7l-0.4-0.3l-0.1-0.1l0,0l-0.3-0.2l0,0l-0.3-0.4c0,0,0.1-0.1,0.1-0.1
				l0.3,0.4l0.6-0.5l0.6-0.5l-0.3-0.4c0,0,0.1-0.1,0.1-0.1l0.3,0.4l0.6-0.5l-0.3-0.4c0,0,0.1-0.1,0.1-0.1l0.3,0.4l0.6-0.5l-0.3-0.4
				c0,0,0.1-0.1,0.1-0.1l0.3,0.4l0.6-0.5l0.6-0.5l-0.3-0.4c0,0,0.1-0.1,0.1-0.1l0.3,0.4l0.6-0.5l-0.3-0.4c0,0,0.1-0.1,0.1-0.1
				l0.3,0.4l0.6-0.5l-0.3-0.4c0,0,0.1-0.1,0.1-0.1l0.3,0.4l0.6-0.5l0.6-0.5l-0.3-0.4c0,0,0.1-0.1,0.1-0.1l0.3,0.4l0.6-0.5l-0.3-0.4
				c0,0,0.1-0.1,0.1-0.1l0.3,0.4l0.5-0.5l-0.3-0.4c0,0,0.1-0.1,0.1-0.1l0.3,0.4l0.5-0.5l0.5-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1
				l0.4,0.4l0.5-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1L429,22l0.5-0.5l0.5-0.5l-0.4-0.4
				c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.4
				l0.5-0.5l0.5-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.5l-0.4-0.4
				c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.4l0.4,0.3c0,0.1-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.1l-0.4-0.3
				l-0.5,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6l-0.5,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6l0.4,0.3
				c0,0.1-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6l-0.5,0.6l0.4,0.3
				c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6L430,23c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3
				l-0.5,0.6l-0.5,0.6l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6l0.4,0.3
				c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6l-0.5,0.6l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3L425,28l0.4,0.3c0,0-0.1,0.1-0.1,0.1
				l-0.4-0.3l-0.5,0.5l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.5l-0.5,0.5l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.5
				l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.5l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.5l-0.5,0.5l0.4,0.3
				c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.5l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.5l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3
				l-0.5,0.5l-0.5,0.5l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.5l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.1,0.1l-0.2-0.1l0,0
				L416.5,35.1L416.5,35.1L416.5,35.1z M419.1,40.7c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.5,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4
				l-0.5,0.5l0.3,0.4c0,0-0.1,0.1-0.1,0.1l-0.3-0.4l-0.2,0.1l2.5-2.4h0.1l-0.3,0.3l-0.5,0.5L419.1,40.7L419.1,40.7z M416.9,41.3
				v-1.7h1.7L416.9,41.3z M419.4,60.6c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.5,0.2-0.8,0.2s-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5
				c-0.1-0.2-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.7-0.2s0.5,0.1,0.7,0.2s0.4,0.3,0.5,0.5
				c0.1,0.2,0.2,0.5,0.2,0.8S419.5,60.4,419.4,60.6L419.4,60.6z M420.6,44.4v1.1h-0.5v0.8h-0.6v3.1H419v0.6h-0.3v-0.6h-1.7v-5.9
				c0,0,0,0,0.1-0.1h0.5v-0.3h-0.1c0,0,0-0.1,0.1-0.1l0.6-0.5l-0.3-0.4c0,0,0.1-0.1,0.1-0.1l0.3,0.4l0.6-0.5l-0.4-0.4
				c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.5l0.5-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1
				l0.4,0.4l0.4-0.4h0.2v-0.4l0,0.1l0.5-0.6l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1
				l0.4,0.3l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l0.5-0.6
				l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2l0.4,0.3l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2l0.4,0.3l0.5-0.6l-0.4-0.3
				c0-0.1,0.1-0.1,0.1-0.2l0.4,0.3l0.5-0.6l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2l0.4,0.3l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2
				l0.4,0.3l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2L429,30l0.4-0.6l0.4-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2l0.4,0.3l0.4-0.6
				l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2l0.4,0.3l0.4-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2l0.4,0.3l0.3-0.5v1h-0.1l-0.3,1.5h0.4v3.7h-0.5
				v0.8h0c0,0-10,6.2-10,10.9L420.6,44.4L420.6,44.4z M420.1,39.6h0.2C420.3,39.6,420.2,39.6,420.1,39.6L420.1,39.6L420.1,39.6z
				 M424.2,61.3h-0.5v-0.4c-0.1,0.1-0.2,0.3-0.4,0.4c-0.2,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.4-0.1-0.6-0.1s-0.3-0.2-0.4-0.4
				s-0.2-0.4-0.2-0.7v-1.7h0.5V60c0,0.3,0.1,0.5,0.2,0.7c0.1,0.1,0.3,0.2,0.6,0.2s0.5-0.1,0.6-0.2s0.2-0.4,0.2-0.7v-1.6h0.5
				L424.2,61.3L424.2,61.3z M426.9,60.9c-0.1,0.1-0.2,0.2-0.4,0.3s-0.3,0.1-0.5,0.1c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.4-0.3
				s-0.2-0.3-0.2-0.5h0.5c0,0.1,0.1,0.3,0.2,0.4s0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.3s-0.1-0.2-0.2-0.3
				s-0.3-0.1-0.5-0.2c-0.2-0.1-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.3,0.1-0.4
				s0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.5-0.1c0.3,0,0.6,0.1,0.8,0.2c0.2,0.2,0.3,0.4,0.3,0.7h-0.5c0-0.1-0.1-0.3-0.2-0.4
				s-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.4,0.1s-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.2s0.1,0.1,0.2,0.1s0.2,0.1,0.4,0.1
				c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.4C427.1,60.6,427,60.8,426.9,60.9L426.9,60.9z
				 M431.9,61.3h-0.5v-0.5c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.2-0.7,0.2s-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8
				s0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5s0.4-0.2,0.7-0.2s0.5,0.1,0.7,0.2s0.3,0.2,0.4,0.4v-0.5h0.5V61.3L431.9,61.3z
				 M431.5,25.4c0-0.1,0.1-0.1,0.1-0.2l0.4,0.3l0.4-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2l0.4,0.3l0.4-0.6l-0.4-0.3
				c0-0.1,0.1-0.1,0.1-0.2l0.4,0.2V25h-0.6v1.4h-1l0.1-0.1l0.4-0.6L431.5,25.4L431.5,25.4z M434,58.8h-0.8v1.7c0,0.1,0,0.2,0.1,0.3
				s0.2,0.1,0.3,0.1h0.4v0.4h-0.4c-0.3,0-0.5-0.1-0.6-0.2s-0.2-0.3-0.2-0.6v-1.7h-0.4v-0.4h0.4v-0.7h0.5v0.7h0.8V58.8L434,58.8z
				 M433.5,23.3l0.2-0.3v0.7h-0.4L433.5,23.3L433.5,23.3z M433.5,22.4c0-0.1,0.1-0.1,0.1-0.2l0.1,0.1v0.2L433.5,22.4L433.5,22.4z
				 M434.4,22.2h-0.3l0.3-0.4l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.2l0.4,0.2V22.2L434.4,22.2z M435,20.8h-0.3l-0.2-0.1
				c0-0.1,0.1-0.1,0.1-0.2L435,20.8L435,20.8L435,20.8L435,20.8z M435.5,19.3l-0.2-0.1c0-0.1,0.1-0.1,0.1-0.2l0.1,0.1V19.3z
				 M436.2,16.4l-0.4,0.7l0.2,0.1v0.2l-0.3-0.2l-0.4,0.7l0.2,0.1h-0.1v0.2l-0.3-0.1l-0.4,0.7l0.4,0.2c0,0.1-0.1,0.1-0.1,0.2
				l-0.4-0.2l-0.4,0.6l-0.4,0.6l0.4,0.2c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.2l-0.4,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.4,0.6
				l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2L433,22l-0.4,0.6l-0.4,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.4,0.6l0.4,0.3
				c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.4,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.4,0.6l-0.4,0.6l0.4,0.3
				c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.4,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.4,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2
				l-0.4-0.3l-0.4,0.6l-0.4,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.4,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.5,0.6
				l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.5,0.6l-0.5,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.5,0.6l0.4,0.3
				c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.5,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.2l-0.4-0.3l-0.5,0.6l-0.5,0.6l0.4,0.3
				c0,0.1-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.1l-0.4-0.3l-0.5,0.6l0.4,0.3c0,0.1-0.1,0.1-0.1,0.1
				l-0.4-0.3l-0.5,0.6l-0.5,0.6l0.4,0.3c0,0-0.1,0.1-0.1,0.1l-0.4-0.3l-0.3,0.3l-0.9-0.6l-0.1-0.1l0,0l-1.5-1.1l-0.2-0.2
				c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.5l0.5-0.5l-0.4-0.4c0,0,0.1-0.1,0.1-0.1
				l0.4,0.4L421,34l-0.4-0.4c0,0,0.1-0.1,0.1-0.1l0.4,0.4l0.5-0.5l-0.4-0.3c0,0,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.5l0.5-0.5l-0.4-0.3
				c0,0,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.5l-0.4-0.3c0,0,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.5l-0.4-0.3c0,0,0.1-0.1,0.1-0.1l0.4,0.3
				l0.5-0.5l0.5-0.6l-0.4-0.3c0,0,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l-0.4-0.3c0,0,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l-0.4-0.3
				c0,0,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l0.5-0.6l-0.4-0.3c0,0,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l-0.4-0.3c0,0,0.1-0.1,0.1-0.1
				l0.4,0.3l0.5-0.6l-0.4-0.3c0,0,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l0.5-0.6l-0.4-0.3c0,0,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l-0.4-0.3
				c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1
				l0.4,0.3l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l0.5-0.6
				l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.5-0.6L435,17c0-0.1,0.1-0.1,0.1-0.1
				l0.4,0.3l0.5-0.6l0.5-0.6l-0.4-0.3c0-0.1,0.1-0.1,0.1-0.1l0.4,0.3l0.1-0.1L436.2,16.4L436.2,16.4z M436.9,15.9l-0.4-0.2l0.1-0.2
				l0.2,0.1V15.9L436.9,15.9z M436.8,15.2l-0.3-0.2c0-0.1,0.1-0.1,0.1-0.2l0.3,0.2L436.8,15.2L436.8,15.2z M436.9,14.1l0.1-0.1l0,0
				C437.1,14,437,14.1,436.9,14.1L436.9,14.1L436.9,14.1z M437.2,14.1l0.2,0.1l-0.1,0.2l-0.2-0.1C437.1,14.2,437.2,14.1,437.2,14.1
				L437.2,14.1z M437.4,15.1l-0.3-0.2l0.1-0.2l0.3,0.1C437.5,15,437.5,15.1,437.4,15.1L437.4,15.1z M438.6,15.2h-0.9l-0.1-0.1
				c0-0.1,0.1-0.1,0.1-0.2l0.4,0.2l0.4-0.7l-0.5-0.2c0-0.1,0.1-0.1,0.1-0.2l0.5,0.2c0,0,0-0.1,0-0.1L438.6,15.2L438.6,15.2z
				 M437.7,14.2l0.1-0.2l0.1,0.1c0,0.1-0.1,0.1-0.1,0.2L437.7,14.2L437.7,14.2z M438,9.1h-0.2l1.5-5.5l1.5,5.5h-0.2l-1.3-4.7
				L438,9.1z M440.9,14.2l-0.1,0.1c0-0.1-0.1-0.1-0.1-0.2l0.1-0.1L440.9,14.2L440.9,14.2z M440.9,15.2H440v-1c0,0,0,0.1,0,0.1
				l0.5-0.2c0,0.1,0.1,0.1,0.1,0.2l-0.5,0.2l0.4,0.7l0.4-0.2c0,0.1,0.1,0.1,0.1,0.2L440.9,15.2L440.9,15.2z M464.5,33.7h0.3
				l-0.3,0.4c0,0-0.1-0.1-0.2-0.1L464.5,33.7L464.5,33.7z M443.6,15.4c0,0,0.1,0.1,0.1,0.1l-0.3,0.3l-0.1-0.2L443.6,15.4L443.6,15.4
				z M442.9,14.7c0,0,0.1,0.1,0.1,0.1l-0.2,0.2l-0.1-0.2L442.9,14.7L442.9,14.7z M442.8,15.3l0.1,0.1l-0.2,0.2
				c0-0.1-0.1-0.1-0.1-0.1L442.8,15.3L442.8,15.3z M442.3,14c0,0,0.1,0.1,0.1,0.1l-0.1,0.1L442.3,14L442.3,14L442.3,14z M442.2,14.6
				l0.1,0.1l-0.1,0.1c0-0.1-0.1-0.1-0.1-0.1L442.2,14.6L442.2,14.6z M441.1,15.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.1l0.1,0.2L441.1,15.1
				L441.1,15.1z M441.3,14.4l-0.1-0.2l0.2-0.1c0,0,0.1,0.1,0.1,0.2L441.3,14.4L441.3,14.4z M441.6,14.1c0-0.1-0.1-0.1-0.1-0.2l0,0
				L441.6,14.1L441.6,14.1L441.6,14.1z M441.9,14.8c0,0.1,0.1,0.1,0.1,0.2l-0.3,0.2l-0.1-0.2L441.9,14.8L441.9,14.8z M441.7,15.9
				v-0.2l0.2-0.1l0.1,0.2L441.7,15.9L441.7,15.9z M443,19.3v-0.2l0.1-0.1c0,0.1,0.1,0.1,0.1,0.2L443,19.3z M443.9,20.8h-0.3v-0.1
				l0,0l0.4-0.2c0,0.1,0.1,0.1,0.1,0.2L443.9,20.8L443.9,20.8z M444.4,22.2h-0.3v-0.7l0.4-0.2c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3
				L444.4,22.2L444.4,22.2z M444.9,23.6V23l0.2,0.3l0.2,0.4L444.9,23.6L444.9,23.6z M444.9,22.5v-0.2l0.1-0.1c0,0.1,0.1,0.1,0.1,0.2
				L444.9,22.5L444.9,22.5z M446.1,26.4V25h-0.6v-1.1l0.4-0.2c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.4,0.6l0.4-0.3
				c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.4,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.4,0.6l0.1,0.1H446.1L446.1,26.4z M464.1,46.6
				h-0.4v0.3h0.4v1.3c-0.5,0.5-1,1.2-1,1.8H463v0.3h-0.1v0.2h-0.1v0.8h-0.1v0.1h-0.1v0.1h-0.1v0.1h-0.1v0.1h-0.9v-0.6h-0.6v-0.6
				h-0.6v-0.6h-0.5v-0.6h-0.5v-3.1h-0.6v-0.8H458v-1.1h-0.4c0-4.7-10-10.9-10-10.9h0v-0.8h-0.5V29h0.4l-0.3-1.5h-0.1v-1l0.3,0.5
				l0.4-0.3c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.4,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.4,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.2
				l-0.4,0.3l0.4,0.6l0.4,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.5,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.5,0.6
				l0.4-0.3c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.5,0.6l0.5,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.5,0.6l0.4-0.3
				c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.5,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.2l-0.4,0.3l0.5,0.6l0.5,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.1
				l-0.4,0.3l0.5,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.5,0.6
				l0.4-0.3c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.6l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.6l0.4-0.4c0,0,0.1,0.1,0.1,0.1
				l-0.4,0.4l0.5,0.5l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.6,0.5l0.3-0.4
				c0,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5c0.2,0.2,0.4,0.3,0.6,0.5l0.3-0.4c0,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.3-0.4
				c0,0,0.1,0.1,0.2,0.1l-0.3,0.4c0.2,0.2,0.4,0.3,0.6,0.5l0.3-0.4c0,0,0.1,0.1,0.2,0.1l-0.3,0.4c0.2,0.2,0.4,0.3,0.6,0.5
				c0,0,0.1,0.1,0.1,0.1L464.1,46.6L464.1,46.6z M464.1,41.6h-0.4v0.3h0.4v2.3c0,0,0,0,0,0l-0.3,0.4c0,0-0.1-0.1-0.2-0.1l0.3-0.4
				c-0.2-0.2-0.4-0.3-0.6-0.5l-0.3,0.4c0,0-0.1-0.1-0.2-0.1l0.3-0.4l-0.6-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4
				c-0.2-0.2-0.4-0.3-0.6-0.5l-0.6-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4
				l-0.5-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5l-0.5-0.5l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.5l-0.4,0.3
				c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.5-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.1
				l0.4-0.3l-0.5-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.5-0.6
				l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.5-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.5-0.6l-0.4,0.3
				c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.5-0.6l-0.5-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.5-0.6l-0.4,0.3
				c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.4-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.4-0.6l-0.4-0.6l-0.4,0.3
				c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.4-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.4-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2
				l0.4-0.3l-0.4-0.6l-0.4-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.4-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.4-0.6
				l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.4-0.6l-0.4-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.4-0.6l-0.4,0.3
				c0-0.1-0.1-0.1-0.1-0.2l0.4-0.3l-0.4-0.6l-0.4,0.2c0-0.1-0.1-0.1-0.1-0.2l0.4-0.2l-0.4-0.6l-0.4-0.6l-0.4,0.2
				c0-0.1-0.1-0.1-0.1-0.2l0.4-0.2l-0.4-0.7l-0.3,0.1v-0.2H443l0.2-0.1l-0.4-0.7l-0.3,0.2v-0.2l0.2-0.1l-0.4-0.7l-0.4-0.7l0.1,0.1
				l0.4-0.3c0,0.1,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.5,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.4-0.3
				c0,0.1,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.5,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.1
				l-0.4,0.3l0.5,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.4-0.3c0,0.1,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.5,0.6
				l0.4-0.3c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.4-0.3c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.4-0.3c0,0,0.1,0.1,0.1,0.1
				l-0.4,0.3l0.5,0.6l0.5,0.6l0.4-0.3c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.4-0.3c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.4-0.3
				c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.5,0.6l0.4-0.3c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.4-0.3c0,0,0.1,0.1,0.1,0.1
				l-0.4,0.3l0.5,0.6l0.4-0.3c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.6l0.5,0.5l0.4-0.3c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.5l0.4-0.3
				c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.5l0.4-0.3c0,0,0.1,0.1,0.1,0.1l-0.4,0.3l0.5,0.5l0.5,0.5l0.4-0.3c0,0,0.1,0.1,0.1,0.1
				l-0.4,0.3l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.5,0.5l0.4-0.4
				c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4
				l0.5,0.5l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.4-0.4
				c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.3,0.3L464.1,41.6L464.1,41.6z M465.3,35.9v1.3h-1.2v1.8l-0.1-0.1l-0.3,0.4
				c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4
				l-0.5-0.5l-0.5-0.5l-0.3,0.4c0,0-0.1-0.1-0.1-0.1l0.3-0.4l-0.5-0.5l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.5l-0.4,0.3
				c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.5l-0.5-0.5l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.5l-0.4,0.3c0,0-0.1-0.1-0.1-0.1
				l0.4-0.3l-0.5-0.5l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.5l-0.5-0.5l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.5
				l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.5l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.5l-0.5-0.5l-0.4,0.3
				c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.5l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3
				l-0.5-0.6l-0.5-0.6l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.4,0.3
				c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.5-0.6l-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.4,0.3c0,0-0.1-0.1-0.1-0.1
				l0.4-0.3l-0.5-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.5-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6
				l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.5-0.6l-0.4,0.3
				c0-0.1-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.1l0.4-0.3l-0.5-0.6l-0.4,0.3c0-0.1-0.1-0.1-0.1-0.1
				l0.4-0.3l0.5,0.4l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.4-0.4
				c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1
				l-0.4,0.4l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.4-0.4
				c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.4-0.4c0,0,0.1,0.1,0.1,0.1l-0.4,0.4l0.5,0.5l0.5,0.5l0.3-0.4c0,0,0.1,0.1,0.1,0.1
				l-0.3,0.4l0.5,0.5l0.3-0.4c0,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.3-0.4c0,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.6,0.5l0.3-0.4
				c0,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.3-0.4c0,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.3-0.4c0,0,0.1,0.1,0.1,0.1l-0.3,0.4
				l0.6,0.5l0.6,0.5l0.3-0.4c0,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.3-0.4c0,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.3-0.4
				c0,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.6,0.5l0.3-0.4c0,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.3-0.4c0,0,0.1,0.1,0.1,0.1
				l-0.3,0.4l0.6,0.5l0.3-0.4c0,0,0.1,0.1,0.1,0.1l-0.3,0.4l0.6,0.5l0.1,0.1v0.3h0.3l0.2,0.1l0.1-0.1h0.3l-0.2,0.3l0.6,0.5l0.3-0.4
				c0,0,0.1,0.1,0.2,0.1l-0.3,0.4l0.6,0.5l0.3-0.4c0,0,0.1,0.1,0.2,0.1l-0.3,0.4l0.5,0.4L465.3,35.9L465.3,35.9z M465.3,34.5
				l-0.1,0.2c0,0-0.1-0.1-0.2-0.1l0.3-0.4L465.3,34.5L465.3,34.5z M465.3,34.2l-0.5-0.4h0.5V34.2z M475.5,62.8
				c-0.1,0.3-0.4,0.5-0.7,0.6c-0.3,0.2-0.7,0.2-1.1,0.2h-0.9v2.1h-1.1v-5.6h2.1c0.4,0,0.8,0.1,1.1,0.2c0.3,0.1,0.5,0.4,0.7,0.6
				s0.2,0.6,0.2,0.9C475.7,62.2,475.6,62.5,475.5,62.8z M477.6,65.8h-1.1v-5.9h1.1V65.8z M482.8,63.9h-3.2c0,0.3,0.1,0.6,0.3,0.8
				s0.4,0.3,0.7,0.3c0.4,0,0.7-0.2,0.9-0.5h1.2c-0.1,0.4-0.4,0.8-0.7,1.1s-0.8,0.4-1.3,0.4c-0.4,0-0.8-0.1-1.1-0.3s-0.6-0.5-0.8-0.8
				s-0.3-0.8-0.3-1.2s0.1-0.9,0.3-1.2s0.5-0.6,0.8-0.8s0.7-0.3,1.2-0.3s0.8,0.1,1.1,0.3c0.3,0.2,0.6,0.4,0.8,0.8
				c0.2,0.3,0.3,0.7,0.3,1.1C482.8,63.6,482.8,63.8,482.8,63.9L482.8,63.9z M488,65.8h-1.1v-0.6c-0.1,0.2-0.3,0.4-0.6,0.5
				c-0.2,0.1-0.5,0.2-0.9,0.2c-0.4,0-0.7-0.1-1-0.3c-0.3-0.2-0.5-0.5-0.7-0.8c-0.2-0.4-0.3-0.8-0.3-1.2s0.1-0.8,0.3-1.2
				s0.4-0.6,0.7-0.8s0.6-0.3,1-0.3c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.5,0.3,0.6,0.5v-0.6h1.1V65.8z M488,49.4v2.5h-1.5v-0.2h-0.2
				v-0.2h-0.2v-0.2H486v-0.2h-0.2V50h-0.2v-0.3h-0.1v-0.4h-0.1c0-0.5-0.3-1.1-0.7-1.6v-0.9h0.4v-0.3h-0.4v-0.8h3.3L488,49.4
				L488,49.4z M488,41.3v2.5h-0.5l-2.8,1.9v-3.9h0.4v-0.3h-0.4v-3.9h3.3V41.3L488,41.3z M492.3,65.2c-0.1,0.2-0.3,0.4-0.6,0.5
				c-0.3,0.1-0.6,0.2-0.9,0.2s-0.7-0.1-1-0.2s-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.3-0.7h1.1c0,0.2,0.1,0.3,0.2,0.4
				c0.1,0.1,0.3,0.2,0.5,0.2s0.4,0,0.5-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1-0.1-0.2-0.2-0.3s-0.4-0.1-0.7-0.2
				c-0.3-0.1-0.6-0.2-0.8-0.2c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.5,0.2-0.7c0.1-0.2,0.3-0.4,0.6-0.5
				c0.3-0.1,0.6-0.2,0.9-0.2c0.5,0,1,0.1,1.3,0.4c0.3,0.3,0.5,0.6,0.5,1.1h-1.1c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.5-0.2
				c-0.2,0-0.4,0-0.5,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0.1,0.3,0.2,0.3s0.4,0.2,0.7,0.2s0.6,0.2,0.8,0.2c0.2,0.1,0.4,0.2,0.5,0.4
				s0.2,0.4,0.2,0.7C492.5,64.8,492.5,65,492.3,65.2L492.3,65.2z M497.5,63.9h-3.2c0,0.3,0.1,0.6,0.3,0.8s0.4,0.3,0.7,0.3
				c0.4,0,0.7-0.2,0.9-0.5h1.2c-0.1,0.4-0.4,0.8-0.7,1.1s-0.8,0.4-1.3,0.4c-0.4,0-0.8-0.1-1.1-0.3s-0.6-0.5-0.8-0.8
				s-0.3-0.8-0.3-1.2s0.1-0.9,0.3-1.2s0.5-0.6,0.8-0.8s0.7-0.3,1.2-0.3c0.4,0,0.8,0.1,1.1,0.3c0.3,0.2,0.6,0.4,0.8,0.8
				c0.2,0.3,0.3,0.7,0.3,1.1C497.6,63.6,497.6,63.8,497.5,63.9L497.5,63.9z M503.2,65.8l-1.2-2.2h-0.5v2.2h-1.1v-5.6h2.1
				c0.4,0,0.8,0.1,1.1,0.2s0.5,0.4,0.7,0.6c0.2,0.3,0.2,0.6,0.2,0.9c0,0.4-0.1,0.7-0.3,1s-0.5,0.5-1,0.6l1.3,2.3L503.2,65.8
				L503.2,65.8z M509.5,63.9h-3.2c0,0.3,0.1,0.6,0.3,0.8s0.4,0.3,0.7,0.3c0.4,0,0.7-0.2,0.9-0.5h1.2c-0.1,0.4-0.4,0.8-0.7,1.1
				s-0.8,0.4-1.3,0.4c-0.4,0-0.8-0.1-1.2-0.3s-0.6-0.5-0.8-0.8s-0.3-0.8-0.3-1.2s0.1-0.9,0.3-1.2s0.5-0.6,0.8-0.8s0.7-0.3,1.2-0.3
				s0.8,0.1,1.1,0.3c0.3,0.2,0.6,0.4,0.8,0.8c0.2,0.3,0.3,0.7,0.3,1.1C509.5,63.6,509.5,63.8,509.5,63.9L509.5,63.9z M514.5,65.8
				h-1.1v-0.6c-0.1,0.2-0.3,0.3-0.6,0.4s-0.5,0.2-0.8,0.2c-0.4,0-0.7-0.1-0.9-0.2c-0.3-0.1-0.5-0.4-0.6-0.6s-0.2-0.6-0.2-1v-2.6h1.1
				v2.4c0,0.4,0.1,0.6,0.3,0.8s0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3s0.3-0.5,0.3-0.8v-2.4h1.1L514.5,65.8L514.5,65.8z M518.9,65.2
				c-0.1,0.2-0.3,0.4-0.6,0.5c-0.3,0.1-0.6,0.2-0.9,0.2s-0.7-0.1-1-0.2s-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.3-0.7h1.1
				c0,0.2,0.1,0.3,0.2,0.4c0.2,0.1,0.3,0.2,0.5,0.2s0.4,0,0.5-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1-0.1-0.2-0.2-0.3
				c-0.2-0.1-0.4-0.1-0.7-0.2c-0.3-0.1-0.6-0.2-0.8-0.2c-0.2-0.1-0.4-0.2-0.5-0.4c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.5,0.2-0.7
				s0.3-0.4,0.6-0.5c0.3-0.1,0.6-0.2,0.9-0.2c0.5,0,1,0.1,1.3,0.4c0.3,0.3,0.5,0.6,0.5,1.1H518c0-0.2-0.1-0.3-0.2-0.4
				c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0-0.5,0.1s-0.2,0.2-0.2,0.3c0,0.1,0.1,0.3,0.2,0.3c0.2,0.1,0.4,0.2,0.7,0.2
				s0.6,0.2,0.8,0.2c0.2,0.1,0.4,0.2,0.5,0.4s0.2,0.4,0.2,0.7C519.1,64.8,519,65,518.9,65.2L518.9,65.2z M524.1,63.9h-3.2
				c0,0.3,0.1,0.6,0.3,0.8s0.4,0.3,0.7,0.3c0.4,0,0.7-0.2,0.9-0.5h1.2c-0.1,0.4-0.4,0.8-0.7,1.1s-0.8,0.4-1.3,0.4
				c-0.4,0-0.8-0.1-1.2-0.3s-0.6-0.5-0.8-0.8s-0.3-0.8-0.3-1.2s0.1-0.9,0.3-1.2s0.5-0.6,0.8-0.8s0.7-0.3,1.2-0.3s0.8,0.1,1.1,0.3
				c0.3,0.2,0.6,0.4,0.8,0.8c0.2,0.3,0.3,0.7,0.3,1.1C524.1,63.6,524.1,63.8,524.1,63.9L524.1,63.9z M526.5,37.5l-2-1.7h2V37.5z
				 M530.8,64.8c-0.2,0.4-0.5,0.6-0.8,0.8c-0.3,0.2-0.7,0.3-1.2,0.3s-0.8-0.1-1.2-0.3s-0.6-0.5-0.8-0.8c-0.2-0.4-0.3-0.8-0.3-1.2
				s0.1-0.9,0.3-1.2s0.5-0.6,0.8-0.8s0.7-0.3,1.2-0.3s0.8,0.1,1.2,0.3s0.6,0.5,0.8,0.8s0.3,0.8,0.3,1.2S531,64.4,530.8,64.8
				L530.8,64.8z M532.8,25.8h1.1v1L532.8,25.8z M534.4,62.5h-0.3c-0.3,0-0.6,0.1-0.8,0.2s-0.3,0.4-0.3,0.9v2.2h-1.1v-4.4h1.1V62
				c0.1-0.2,0.3-0.4,0.6-0.6s0.5-0.2,0.8-0.2L534.4,62.5L534.4,62.5z M540,65.8l-1.2-2.2h-0.5v2.2h-1.1v-5.6h2.1
				c0.4,0,0.8,0.1,1.1,0.2s0.5,0.4,0.7,0.6c0.2,0.3,0.2,0.6,0.2,0.9c0,0.4-0.1,0.7-0.3,1s-0.5,0.5-1,0.6l1.3,2.3L540,65.8L540,65.8z
				 M546.3,63.9h-3.2c0,0.3,0.1,0.6,0.3,0.8s0.4,0.3,0.7,0.3c0.4,0,0.7-0.2,0.9-0.5h1.2c-0.1,0.4-0.4,0.8-0.7,1.1
				c-0.4,0.3-0.8,0.4-1.3,0.4c-0.4,0-0.8-0.1-1.2-0.3s-0.6-0.5-0.8-0.8s-0.3-0.8-0.3-1.2s0.1-0.9,0.3-1.2s0.5-0.6,0.8-0.8
				s0.7-0.3,1.2-0.3s0.8,0.1,1.1,0.3c0.3,0.2,0.6,0.4,0.8,0.8c0.2,0.3,0.3,0.7,0.3,1.1C546.3,63.6,546.3,63.8,546.3,63.9L546.3,63.9
				z M548.3,64.5c0.2,0.2,0.4,0.4,0.8,0.4c0.5,0,0.8-0.2,0.9-0.6h1.2c-0.1,0.5-0.4,0.9-0.7,1.1s-0.8,0.4-1.4,0.4
				c-0.4,0-0.8-0.1-1.1-0.3s-0.6-0.5-0.8-0.8s-0.3-0.8-0.3-1.2s0.1-0.9,0.3-1.2s0.5-0.6,0.8-0.8s0.7-0.3,1.1-0.3
				c0.5,0,1,0.1,1.4,0.4s0.6,0.7,0.7,1.2h-1.2c-0.1-0.2-0.2-0.3-0.3-0.4s-0.3-0.2-0.6-0.2c-0.3,0-0.6,0.1-0.8,0.4
				c-0.2,0.2-0.3,0.6-0.3,1S548.1,64.3,548.3,64.5L548.3,64.5z M553.5,67.9h-1.2l1-2.2l-1.8-4.3h1.3l1.1,3.1l1.2-3.1h1.2L553.5,67.9
				L553.5,67.9z M557.9,64.5c0.2,0.2,0.4,0.4,0.8,0.4c0.5,0,0.8-0.2,0.9-0.6h1.2c-0.1,0.5-0.4,0.9-0.7,1.1s-0.8,0.4-1.4,0.4
				c-0.4,0-0.8-0.1-1.1-0.3s-0.6-0.5-0.8-0.8s-0.3-0.8-0.3-1.2s0.1-0.9,0.3-1.2s0.5-0.6,0.8-0.8s0.7-0.3,1.1-0.3
				c0.5,0,1,0.1,1.4,0.4s0.6,0.7,0.7,1.2h-1.2c-0.1-0.2-0.2-0.3-0.3-0.4s-0.3-0.2-0.6-0.2c-0.3,0-0.6,0.1-0.8,0.4
				c-0.2,0.2-0.3,0.6-0.3,1S557.7,64.3,557.9,64.5L557.9,64.5z M562.7,65.8h-1.1v-5.9h1.1V65.8z M570.2,60.1l-0.1,3.9h-0.9l-0.1-3.9
				H570.2z M567.9,63.9h-3.2c0,0.3,0.1,0.6,0.3,0.8s0.4,0.3,0.7,0.3c0.4,0,0.7-0.2,0.9-0.5h1.2c-0.1,0.4-0.4,0.8-0.7,1.1
				c-0.4,0.3-0.8,0.4-1.3,0.4c-0.4,0-0.8-0.1-1.2-0.3s-0.6-0.5-0.8-0.8s-0.3-0.8-0.3-1.2s0.1-0.9,0.3-1.2s0.5-0.6,0.8-0.8
				s0.7-0.3,1.2-0.3s0.8,0.1,1.1,0.3c0.3,0.2,0.6,0.4,0.8,0.8c0.2,0.3,0.3,0.7,0.3,1.1C568,63.6,568,63.8,567.9,63.9L567.9,63.9z
				 M570.1,65.6c-0.1,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5s0.1-0.3,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2
				s0.4,0.1,0.5,0.2s0.2,0.3,0.2,0.5S570.3,65.5,570.1,65.6z M588.8,35.4v0.5h0.2v13.4h-0.1v0.8h-0.2v1.8h-16.2v-1.8h-0.2v-0.8h-0.1
				V35.9h0.2v-0.5H588.8L588.8,35.4z M589.9,32L589.9,32c-0.5,0-1,0-1.6,0c0.7,0,1.3,0,2,0C590.2,31.9,590.1,31.9,589.9,32z" />
							<path
								d="M565.8,62.3c-0.3,0-0.5,0.1-0.7,0.2s-0.3,0.4-0.3,0.7h2.1c0-0.3-0.1-0.5-0.3-0.7S566,62.3,565.8,62.3z" />
							<path
								d="M521.9,62.3c-0.3,0-0.5,0.1-0.7,0.2s-0.3,0.4-0.3,0.7h2.1c0-0.3-0.1-0.5-0.3-0.7S522.2,62.3,521.9,62.3z" />
							<path
								d="M495.4,62.3c-0.3,0-0.5,0.1-0.7,0.2s-0.3,0.4-0.4,0.7h2.1c0-0.3-0.1-0.5-0.3-0.7S495.7,62.3,495.4,62.3L495.4,62.3z" />
							<path d="M503,61.4c-0.2-0.1-0.4-0.2-0.7-0.2h-0.9v1.6h0.9c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.2-0.4,0.2-0.6S503.2,61.5,503,61.4
				L503,61.4z" />
							<path d="M486.3,62.5c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.1s-0.3,0.2-0.4,0.4s-0.2,0.4-0.2,0.7s0,0.5,0.2,0.7
				s0.2,0.4,0.4,0.5s0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.2-0.4,0.2-0.7s0-0.5-0.2-0.7
				S486.5,62.6,486.3,62.5L486.3,62.5z" />
							<path
								d="M507.3,62.3c-0.3,0-0.5,0.1-0.7,0.2s-0.3,0.4-0.3,0.7h2.1c0-0.3-0.1-0.5-0.3-0.7S507.6,62.3,507.3,62.3z" />
							<path
								d="M544.1,62.3c-0.3,0-0.5,0.1-0.7,0.2s-0.3,0.4-0.3,0.7h2.1c0-0.3-0.1-0.5-0.3-0.7S544.4,62.3,544.1,62.3z" />
							<path d="M238.3,62.3c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.6-0.3,1s0.1,0.8,0.3,1c0.2,0.2,0.5,0.3,0.8,0.3
				c0.2,0,0.4-0.1,0.6-0.1s0.3-0.2,0.4-0.4c0.1-0.2,0.2-0.4,0.2-0.7c0-0.4-0.1-0.8-0.3-1C238.9,62.4,238.6,62.3,238.3,62.3
				L238.3,62.3z" />
							<path
								d="M246.5,62.3c-0.3,0-0.5,0.1-0.7,0.2s-0.3,0.4-0.4,0.7h2.1c0-0.3-0.1-0.5-0.3-0.7S246.8,62.3,246.5,62.3z" />
							<path
								d="M230.5,62.3c-0.3,0-0.5,0.1-0.7,0.2s-0.3,0.4-0.4,0.7h2.1c0-0.3-0.1-0.5-0.3-0.7S230.8,62.3,230.5,62.3z" />
							<path d="M353.9,59.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.5,0.1-0.6,0.2c-0.2,0.1-0.3,0.4-0.3,0.6h1.8
				C354,59.5,354,59.4,353.9,59.2L353.9,59.2z" />
							<path d="M312.5,65.5c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.5,0.1-0.6,0.2c-0.2,0.1-0.3,0.4-0.3,0.6h1.8c0-0.2,0-0.3-0.1-0.5
				S312.6,65.6,312.5,65.5L312.5,65.5z" />
							<path d="M226.2,62.4c0.1-0.1,0.2-0.3,0.2-0.5s-0.1-0.4-0.2-0.5c-0.1-0.1-0.4-0.2-0.6-0.2h-1v1.4h1
				C225.9,62.6,226.1,62.5,226.2,62.4z" />
							<path
								d="M214.1,62.3c-0.3,0-0.5,0.1-0.7,0.2s-0.3,0.4-0.4,0.7h2.1c0-0.3-0.1-0.5-0.3-0.7S214.4,62.3,214.1,62.3z" />
							<path
								d="M199,62.3c-0.3,0-0.5,0.1-0.7,0.2s-0.3,0.4-0.4,0.7h2.1c0-0.3-0.1-0.5-0.3-0.7S199.3,62.3,199,62.3z" />
							<path d="M225.7,63.4h-1.1v1.5h1.1c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5s-0.1-0.4-0.2-0.6
				C226.2,63.5,226,63.4,225.7,63.4L225.7,63.4z" />
							<path d="M315.2,59c-0.1-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.4s-0.1,0.4-0.1,0.6s0,0.4,0.1,0.6
				s0.2,0.3,0.3,0.4c0.1,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.4-0.4s0.1-0.4,0.1-0.6s0-0.4-0.1-0.6
				C315.4,59.2,315.3,59.1,315.2,59L315.2,59z" />
							<path
								d="M263.3,62.3c-0.3,0-0.5,0.1-0.7,0.2s-0.3,0.4-0.4,0.7h2.1c0-0.3-0.1-0.5-0.3-0.7S263.6,62.3,263.3,62.3z" />
							<path d="M340.6,65.5c-0.1-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.4s-0.1,0.4-0.1,0.6s0,0.4,0.1,0.6
				s0.2,0.3,0.3,0.4c0.1,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.4-0.4s0.1-0.4,0.1-0.6s0-0.4-0.1-0.6
				C340.9,65.8,340.8,65.6,340.6,65.5L340.6,65.5z" />
							<path d="M344.6,65.9c-0.2,0-0.3,0.1-0.5,0.1s-0.2,0.2-0.3,0.4c-0.1,0.2-0.1,0.3-0.1,0.5c0,0.2,0,0.3,0.1,0.4s0.2,0.2,0.4,0.2
				c0.2,0,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.2,0.1-0.3,0.1-0.5s0-0.3-0.1-0.4C344.9,66,344.8,65.9,344.6,65.9
				L344.6,65.9z" />
							<path d="M349.3,65.5c-0.1-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.4s-0.1,0.4-0.1,0.6s0,0.4,0.1,0.6
				s0.2,0.3,0.3,0.4c0.1,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.4-0.4s0.1-0.4,0.1-0.6s0-0.4-0.1-0.6
				C349.6,65.8,349.5,65.6,349.3,65.5L349.3,65.5z" />
							<path d="M350.6,59.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.5,0.1-0.6,0.2c-0.2,0.1-0.3,0.4-0.3,0.6h1.8
				C350.7,59.5,350.7,59.4,350.6,59.2L350.6,59.2z" />
							<path d="M352.7,65.6c-0.1-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.4-0.1,0.6s0,0.4,0.1,0.6
				c0.1,0.2,0.2,0.3,0.4,0.4c0.1,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.4,0.1-0.6s0-0.4-0.1-0.6
				S352.9,65.6,352.7,65.6L352.7,65.6z" />
							<path d="M342.2,59.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.5,0.1-0.6,0.2c-0.2,0.1-0.3,0.4-0.3,0.6h1.8
				C342.4,59.5,342.3,59.4,342.2,59.2L342.2,59.2z" />
							<path d="M388.8,59.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.5,0.1-0.6,0.2c-0.2,0.1-0.3,0.4-0.3,0.6h1.8
				C388.9,59.5,388.9,59.4,388.8,59.2L388.8,59.2z" />
							<path d="M355.9,60.9c0.1,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.4,0.1-0.6s0-0.4-0.1-0.6
				s-0.2-0.3-0.4-0.4s-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.4-0.1,0.6s0,0.4,0.1,0.6
				C355.6,60.7,355.8,60.8,355.9,60.9L355.9,60.9z" />
							<path d="M324.1,65.6c-0.1-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.4-0.1,0.6s0,0.4,0.1,0.6
				c0.1,0.2,0.2,0.3,0.4,0.4c0.1,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.4,0.1-0.6s0-0.4-0.1-0.6
				S324.3,65.6,324.1,65.6L324.1,65.6z" />
							<path d="M333.2,59c-0.1-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.4s-0.1,0.4-0.1,0.6s0,0.4,0.1,0.6
				s0.2,0.3,0.3,0.4c0.1,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.4-0.4s0.1-0.4,0.1-0.6s0-0.4-0.1-0.6
				C333.4,59.2,333.3,59.1,333.2,59L333.2,59z" />
						</g>
						<polygon points="391.5,36.4 391.2,36.4 391.2,32.5 391.2,31.3 391.2,30.9 391.2,30 391.6,30 391.6,29.5 391.2,29.5 391.2,28.7 
			391.6,28.7 391.6,28.4 364,28.4 364,28.7 364.4,28.7 364.4,29.5 364,29.5 364,30 364.4,30 364.4,30.9 364.4,31.3 364.4,32.5 
			364.4,36.4 364,36.4 353,41.8 402.5,41.8 		" />
						<polygon points="388.6,24.2 388.1,24.2 388.1,21.2 388.1,20.3 388.1,20 388.1,19.3 388.4,19.3 388.4,18.9 388.1,18.9 388.1,18.3 
			388.4,18.3 388.4,18.1 367.1,18.1 367.1,18.3 367.5,18.3 367.5,18.9 367.1,18.9 367.1,19.3 367.5,19.3 367.5,20 367.5,20.3 
			367.5,21.2 367.5,24.2 367,24.2 358.3,28.4 397.2,28.4 		" />
						<polygon points="386.1,14.8 384.1,14.8 384.1,14.6 381.6,14.6 381.6,13 380.3,13 377.8,0 375.3,13 373.9,13 373.9,14.6 
			371.4,14.6 371.4,14.8 369.5,14.8 362.8,18.1 392.7,18.1 		" />
					</g>
				</g>
				<rect x="2.7" y="55.9" width="585.6" height="13.3" />
			</svg>
		</div>
	)
}
