import React from 'react'

export default function Beaker() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0,512) scale(0.1,-0.1)"
                fill="#000000" stroke="none">
                <path d="M1855 5106 c-84 -21 -147 -57 -211 -121 -180 -179 -177 -457 5 -635
31 -30 80 -66 109 -79 l52 -24 0 -518 0 -517 -627 -1054 c-348 -585 -641
-1088 -659 -1132 -43 -104 -58 -197 -51 -315 6 -113 26 -190 72 -287 94 -194
270 -339 486 -401 61 -17 140 -18 1529 -18 l1465 0 80 23 c205 59 378 205 470
395 55 116 70 185 70 327 0 142 -16 215 -68 320 -18 36 -310 531 -650 1102
l-617 1036 0 519 0 518 57 30 c122 64 211 187 235 325 40 231 -118 460 -350
509 -79 16 -1330 14 -1397 -3z m1155 -1036 l0 -150 -90 0 c-71 0 -99 -4 -130
-20 -45 -23 -80 -80 -80 -130 0 -50 35 -107 80 -130 31 -16 59 -20 130 -20
l90 0 0 -150 0 -150 -90 0 c-112 0 -159 -20 -190 -80 -25 -50 -25 -90 0 -140
33 -65 75 -80 222 -80 l123 0 326 -544 327 -544 -71 -32 c-147 -66 -312 -92
-447 -71 -186 29 -493 168 -551 249 -20 29 -157 125 -253 177 -222 121 -509
188 -673 157 -29 -5 -53 -7 -53 -5 0 2 96 165 213 361 l212 357 3 547 2 548
450 0 450 0 0 -150z m-1280 -2590 c45 -23 80 -80 80 -130 0 -76 -74 -150 -151
-150 -46 0 -108 39 -130 82 -37 72 -14 151 57 194 48 29 92 30 144 4z m1800
-300 c45 -23 80 -80 80 -130 0 -76 -74 -150 -151 -150 -46 0 -108 39 -130 82
-37 72 -14 151 57 194 48 29 92 30 144 4z m-900 -300 c45 -23 80 -80 80 -130
0 -76 -74 -150 -151 -150 -46 0 -108 39 -130 82 -37 72 -14 151 57 194 48 29
92 30 144 4z"/>
            </g>
        </svg>
    )
}
