import React from 'react'

export default function Oil() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M2019 5009 c-67 -39 -74 -63 -74 -249 0 -139 3 -170 18 -197 20 -38
55 -63 100 -72 l32 -7 0 -74 0 -75 -292 -195 c-160 -107 -299 -201 -308 -209
-9 -8 -25 -32 -36 -52 -17 -35 -19 -61 -19 -280 0 -295 -1 -292 126 -400 47
-39 89 -80 94 -90 6 -10 10 -154 10 -342 0 -373 11 -332 -114 -436 -129 -108
-117 10 -114 -1129 l3 -980 30 -43 c17 -23 48 -52 70 -63 40 -21 46 -21 1015
-21 969 0 975 0 1015 21 22 11 54 40 70 63 l30 43 3 980 c3 1139 15 1021 -114
1129 -125 104 -114 63 -114 436 0 188 4 332 10 342 5 10 47 51 94 90 126 108
126 106 126 394 0 248 -5 282 -48 331 -9 10 -151 108 -315 218 l-298 199 3 71
3 72 32 7 c45 9 80 34 100 72 15 27 18 58 18 199 0 185 -4 197 -75 246 l-33
22 -506 0 c-495 0 -507 -1 -542 -21z m1019 -251 l3 -128 -481 0 -480 0 0 123
c0 68 3 127 7 130 3 4 218 6 477 5 l471 -3 3 -127z m-158 -328 l0 -60 -320 0
-320 0 0 60 0 60 320 0 320 0 0 -60z m355 -403 l300 -202 3 -86 3 -87 -81 -21
c-277 -75 -559 -63 -870 37 -227 74 -379 101 -557 102 -103 0 -289 -22 -373
-45 -83 -22 -81 -23 -78 43 l3 57 300 202 300 202 375 0 375 0 300 -202z
m-926 -427 c58 -12 173 -43 257 -70 187 -61 289 -80 459 -87 149 -6 295 8 426
41 l89 22 0 -67 0 -66 -89 -77 c-49 -42 -99 -92 -111 -111 l-22 -35 -94 0
c-106 0 -134 -14 -134 -69 0 -51 38 -71 136 -71 l84 0 0 -85 0 -85 -88 0
c-101 0 -132 -17 -132 -70 0 -55 28 -70 130 -70 l90 0 0 -85 0 -85 -84 0 c-98
0 -136 -20 -136 -71 0 -55 28 -69 134 -69 l94 0 21 -34 c12 -19 52 -60 89 -91
119 -102 112 -88 112 -236 l0 -129 -980 0 -980 0 0 129 c0 147 -7 131 107 230
40 34 82 78 93 97 l22 34 94 0 c106 0 134 14 134 69 0 51 -38 71 -136 71 l-84
0 0 85 0 85 90 0 c102 0 130 15 130 70 0 53 -31 70 -132 70 l-88 0 0 85 0 85
84 0 c98 0 136 20 136 71 0 55 -28 69 -134 69 l-94 0 -21 34 c-12 18 -62 68
-111 111 l-90 78 0 92 0 93 81 22 c200 54 446 61 648 20z m1231 -2355 l0 -515
-980 0 -980 0 0 515 0 515 980 0 980 0 0 -515z m0 -819 c0 -159 -1 -165 -22
-180 -20 -14 -127 -16 -958 -16 -831 0 -938 2 -958 16 -21 15 -22 21 -22 180
l0 164 980 0 980 0 0 -164z"/>
                <path d="M2521 1602 c-27 -13 -52 -48 -124 -172 -108 -187 -121 -221 -115
-304 12 -147 130 -256 278 -256 148 0 266 109 278 256 6 82 -5 111 -112 298
-109 190 -136 214 -205 178z m113 -299 c71 -127 78 -159 50 -219 -21 -45 -70
-74 -124 -74 -54 0 -103 29 -124 74 -28 60 -21 92 50 219 36 64 69 117 74 117
5 0 38 -53 74 -117z"/>
            </g>
        </svg>
    )
}
