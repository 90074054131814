import React from 'react'

export default function Milled() {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 239.5 264">
            <path d="M114.5,223.2c-3.1,3.7-9.4,4.9-13.3,2.5c-3.7-2.2-2.5-6.3-2.4-9.5c0.2-4.5,5.2-3.9,7.9-5.8
	c1.9-1.3,3.2,1.1,4.8,2.3C115.3,215.5,114.6,219.1,114.5,223.2z" />
            <path d="M129.7,177.6c2.9-1.1,9.6,1.7,10.5,4.7c0.8,2.9,2.6,6.3-1.9,8.5c-2.2,1.1-2.6,5.1-6.5,4.1
	c-3.4-0.9-4.5-4.2-6.7-6.2c-3.2-2.9,0.2-5,2.6-6.9C128.6,181,128.9,179.2,129.7,177.6z" />
            <path d="M110.4,240.6c1.8-2.3,4.3-3.4,4.8-6.4c0.3-1.6,2.1-2.5,4-1.9c7.8,2.3,9.7,9.2,4.3,15c-1.9,2-3.5,5-6.2,3
	C114.3,248,109.9,246,110.4,240.6z" />
            <path d="M137.3,235.1c-4.3,0.8-6.5-0.7-6.5-4.8c0-1.2-0.4-2.2-1-3.2c-0.3-0.5-0.7-1.2-0.6-1.8c0.1-2.3,1.7-4.3,3.6-4
	c3.5,0.6,6.8-6.2,10.3-0.2c2,3.4,3.7,6.1,1,10.5C142.2,234.6,140,234.3,137.3,235.1z" />
            <path d="M106.6,186.2c-4.9-2.7-3-7.6-4.4-11.4c-0.4-1.1,1.3-2.2,2.7-2.5c1-0.2,2.1,0,2.9-0.4c1.9-0.8,3.2-3.2,5.7-2.1
	c2.8,1.2,2.6,4.7,4.8,6.7c1.4,1.3-2.3,8.2-4.5,8.7C111.6,185.8,109.3,186,106.6,186.2z" />
            <path d="M120.8,206.9c0.9-3.2,2.7-5.3,5.5-3.5c2.6,1.7,8.7-0.3,7.1,6.5c-0.7,3,1.9,7.2-3.6,8c-1.8,0.3-3.3,3.2-5.3,1.5
	c-2.2-1.9-4.5-4-5.6-6.8C117.9,210.4,119.3,208.8,120.8,206.9z" />
            <path d="M104.2,237.3c0,1.6-0.3,2.7-0.2,3.9c0.1,4.3-2.8,7.9-7,8.5c-1.8,0.3-7.4-5.1-7.6-7.6c-0.3-3.6,2.6-5.9,4.2-8.6
	c1.1-1.9,3.6-0.5,5.3,0.6C100.6,235.1,103.5,233.9,104.2,237.3z" />
            <path d="M89.8,204.1c-5.4-3.2-2.4-7.7-1.6-11.2c0.8-3.4,5.4-2.9,8.3-4.1c1.2-0.5,5.8,6.3,5.3,7.9
	c-0.9,3.4-0.4,8.2-6.7,6.3C93.7,202.6,92.1,205,89.8,204.1z" />
            <path d="M151.3,250.9c-2.8,1.7-5.7,0.6-8.4,2.1c-1.5,0.8-3.2-0.2-4.3-1.7c-2.3-3.4-0.5-6.9,0.7-9.9
	c1.2-3.1,5.9-4.1,8.1-3.2c2.4,1,5.7,3.6,5.2,7.6C152.4,247.4,151.9,248.9,151.3,250.9z" />
            <path d="M151.7,207.5c-2.8,1.7-5.7,0.6-8.4,2.1c-1.5,0.8-3.2-0.2-4.3-1.7c-2.3-3.4-0.5-6.9,0.7-9.9
	c1.2-3.1,5.9-4.1,8.1-3.2c2.4,1,5.7,3.6,5.2,7.6C152.8,204,152.3,205.6,151.7,207.5z" />
            <path d="M150.3,220.5c1.8-2.3,4.3-3.4,4.8-6.4c0.3-1.6,2.1-2.5,4-1.9c7.8,2.3,9.7,9.2,4.3,15c-1.9,2-3.5,5-6.2,3
	C154.2,227.9,149.8,226,150.3,220.5z" />
            <path d="M165.9,250.7c-5.4-3.2-2.4-7.7-1.6-11.2c0.8-3.4,5.4-2.9,8.3-4.1c1.2-0.5,5.8,6.3,5.3,7.9
	c-0.9,3.4-0.4,8.2-6.7,6.3C169.9,249.2,168.3,251.5,165.9,250.7z" />
            <path d="M71.7,230.6c2.9-1.1,9.6,1.7,10.5,4.7c0.8,2.9,2.6,6.3-1.9,8.5c-2.2,1.1-2.6,5.1-6.5,4.1
	c-3.4-0.9-4.5-4.2-6.7-6.2c-3.2-2.9,0.2-5,2.6-6.9C70.6,234,70.9,232.2,71.7,230.6z" />
            <path d="M75.1,215.1c1.8-2.3,4.3-3.4,4.8-6.4c0.3-1.6,2.1-2.5,4-1.9c7.8,2.3,9.7,9.2,4.3,15c-1.9,2-3.5,5-6.2,3
	C78.9,222.5,74.6,220.6,75.1,215.1z" />
            <path d="M122.4,197.1c-0.8,3.1-3.7,4.4-4.6,7.3c-0.5,1.6-2.5,2.1-4.3,1.7c-4.1-0.8-5.2-4.7-6.4-7.6
	c-1.3-3.1,1.4-7.1,3.6-7.9c2.4-1,6.6-1.4,9.1,1.8C120.8,193.8,121.5,195.3,122.4,197.1z" />
        </svg>
    )
}
