import React from 'react'

export default function Person() {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" viewBox="0 0 1024 1024">
            <path opacity="000000" stroke="none" d="
M473.000000,1025.000000 
	C316.833344,1025.000000 161.166656,1024.984375 5.500066,1025.093994 
	C1.750637,1025.096680 0.905801,1024.249146 0.906913,1020.500000 
	C1.007246,682.166687 1.007246,343.833344 0.906913,5.500028 
	C0.905801,1.750903 1.750902,0.905800 5.500028,0.906912 
	C343.833344,1.007255 682.166687,1.007255 1020.500000,0.906912 
	C1024.249146,0.905800 1025.094360,1.750902 1025.093262,5.500027 
	C1024.992920,343.833344 1024.992798,682.166687 1025.093140,1020.500000 
	C1025.094238,1024.249146 1024.249268,1025.095947 1020.499939,1025.093750 
	C838.166687,1024.986694 655.833313,1025.000000 473.000000,1025.000000 
z" />
            <path fill="#010101" opacity="1.000000" stroke="none" d="
M652.129883,781.000122 
	C652.122437,814.833008 652.110779,848.165894 652.110596,881.498779 
	C652.110535,890.478027 651.330994,891.278381 642.446838,891.274475 
	C553.614685,891.234924 464.782501,891.196167 375.950348,891.161377 
	C371.617340,891.159668 367.282654,891.281311 362.951721,891.195740 
	C357.693268,891.092041 355.863647,889.164062 355.692566,883.844727 
	C355.687225,883.678528 355.736115,883.504822 355.703735,883.346069 
	C353.857635,874.295349 357.421936,869.364014 365.687805,864.494324 
	C405.458893,841.063843 413.267731,784.996094 382.441406,749.431824 
	C376.182251,742.210632 369.142365,735.828125 360.701233,731.290649 
	C357.009003,729.305908 355.763031,726.953979 355.777191,722.742065 
	C355.903961,685.076843 355.818451,647.410583 355.683899,609.745056 
	C355.656616,602.106445 355.794556,594.386536 352.114136,587.270386 
	C350.245728,583.657715 347.929321,580.489319 344.588501,578.116028 
	C338.769409,573.982361 333.212830,573.995056 327.799744,578.976624 
	C320.328247,585.852600 313.188019,593.091187 305.966553,600.235779 
	C290.221039,615.813721 274.486145,631.402649 258.800842,647.041138 
	C248.494263,657.316956 248.606796,664.885315 258.968140,675.189880 
	C289.900024,705.952454 320.505249,737.051697 351.833405,767.404968 
	C375.850616,790.674744 368.054718,827.969971 341.052765,840.250916 
	C323.235748,848.354431 305.626556,846.631897 290.225525,834.449829 
	C274.938568,822.357971 262.083740,807.546143 248.260300,793.833374 
	C217.050217,762.873047 186.171326,731.578552 154.921646,700.658630 
	C130.313568,676.310181 128.792267,642.049316 152.559280,616.709229 
	C170.214035,597.885925 188.872925,580.002136 207.146133,561.761536 
	C244.044647,524.928772 280.863953,488.015320 317.961761,451.384125 
	C335.308136,434.255890 356.331787,425.501556 380.969696,425.915924 
	C392.466125,426.109283 403.968567,425.944641 415.468292,425.941742 
	C417.626923,425.941223 419.641632,425.981079 421.591400,427.426270 
	C444.051208,444.073425 469.815186,449.220367 497.061584,448.237000 
	C520.440125,447.393250 542.403503,441.573914 561.471619,427.218475 
	C563.040649,426.037231 564.794800,425.942657 566.670044,425.945831 
	C582.503052,425.972870 598.337891,425.779846 614.168762,425.961487 
	C644.178589,426.305756 670.932861,435.883667 694.185974,454.930237 
	C696.650330,456.948822 698.988403,457.763885 702.256042,457.505249 
	C725.834106,455.639160 749.426636,453.945251 773.025635,452.361328 
	C776.750305,452.111359 777.314819,451.137238 775.929871,447.665771 
	C763.785278,417.223816 751.811462,386.713715 739.767151,356.231659 
	C737.049377,349.353546 736.991455,349.172668 743.824402,348.390625 
	C766.882141,345.751740 786.747864,334.893402 806.701843,324.232056 
	C811.249268,321.802368 815.912476,319.541351 820.237061,316.759247 
	C823.266724,314.810211 824.399048,315.604126 825.565491,318.618866 
	C832.698669,337.054169 839.997375,355.425385 847.157166,373.850494 
	C858.955505,404.212555 870.602356,434.633759 882.473511,464.967224 
	C897.851135,504.260925 872.497925,544.812195 830.509033,549.139099 
	C788.739990,553.443298 746.847351,555.925720 705.005920,559.172913 
	C698.700073,559.662231 692.391907,560.280457 686.131531,561.168579 
	C666.970398,563.886597 652.423767,580.904907 652.269714,601.505554 
	C651.999207,637.669922 652.135986,673.837280 652.118591,710.003418 
	C652.107300,733.502319 652.125122,757.001160 652.129883,781.000122 
z" />
            <path fill="#010101" opacity="1.000000" stroke="none" d="
M381.006317,203.021118 
	C402.758331,171.674454 432.256042,152.567825 469.595734,147.694397 
	C522.536804,140.784729 565.277222,159.187286 596.375732,203.148544 
	C605.901062,216.613708 612.314453,231.495911 616.069519,247.544113 
	C617.557434,253.902939 617.584778,253.850983 611.237671,254.864456 
	C593.029663,257.771790 579.056946,266.920593 570.200195,283.261353 
	C558.244995,305.318817 563.621338,334.522858 582.639526,351.693634 
	C583.997986,352.920105 585.389648,354.115662 586.827759,355.246948 
	C593.025330,360.122162 593.137207,360.197693 588.156921,366.179504 
	C560.276978,399.665894 524.137817,415.222870 481.073395,412.242401 
	C407.983368,407.183838 353.463257,343.675842 358.158600,270.577179 
	C359.726288,246.170593 367.009125,223.635910 381.006317,203.021118 
z" />
        </svg>
    )
}
