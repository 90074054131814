import React from 'react'

export default function OatDoodle() {
	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
			<g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
				<path d="M910,2733c0-48,20-107,32-95c8,8,8,28,2,63C934,2758,910,2781,910,2733z" />
				<path d="M1095,2738c-10-47-10-101,1-105c14-5,22,13,30,77c6,42,4,50-10,50C1108,2760,1098,2750,1095,2738z" />
				<path
					d="M2086,2735c-3-9-6-53-6-97c0-70,2-79,18-76c15,3,17,15,17,92c0,57-4,91-11,93C2098,2749,2090,2744,2086,2735z" />
				<path d="M727,2713c-8-13,54-113,69-113c22,0,17,22-16,73C752,2718,737,2729,727,2713z" />
				<path d="M1261,2668c-28-48-31-68-12-68c11,0,56,68,65,98C1328,2742,1291,2721,1261,2668z" />
				<path d="M1800,2710c0-18,90-190,100-190c5,0,12,4,15,10c8,14-86,190-102,190C1806,2720,1800,2715,1800,2710z" />
				<path d="M2285,2671c-24-39-26-61-5-61c16,0,53,81,42,92C2315,2709,2303,2698,2285,2671z" />
				<path
					d="M1443,2619c-40-33-73-64-73-69c0-25,25-14,94,41c63,50,75,63,65,75c-6,8-12,14-13,14C1516,2680,1482,2652,1443,2619z" />
				<path d="M1957,2648c6-35,19-55,28-46c11,11-7,78-21,78C1954,2680,1952,2671,1957,2648z" />
				<path d="M535,2660c-8-13,23-51,88-108c43-38,67-46,67-22c0,9-126,133-140,138C546,2670,539,2666,535,2660z" />
				<path d="M2376,2560c-70-74-95-116-63-108c17,4,157,154,157,167C2470,2648,2445,2632,2376,2560z" />
				<path d="M1630,2596c0-18,40-47,52-40c10,6-29,54-44,54C1634,2610,1630,2604,1630,2596z" />
				<path d="M2224,2559c-22-44-3-60,25-21c25,35,27,52,6,52C2247,2590,2233,2576,2224,2559z" />
				<path d="M1756,2562c-6-10,53-102,65-102c15,0,10,14-21,63C1770,2571,1765,2576,1756,2562z" />
				<path d="M795,2522c-59-20-75-45-75-117c0-38,5-55,20-68c26-22,25-41-9-144c-31-95-81-274-101-363c-7-30-16-154-19-275l-6-220
		l-19,63c-11,35-23,65-28,68c-13,8-10-10,16-104c25-93,41-170,33-162c-3,3-22,54-42,114c-21,60-61,170-91,244c-30,75-54,143-54,151
		c0,9,7,25,15,35c21,27,20,116,0,116c-11,0-15,12-15,46c0,41-2,46-20,41c-17-4-19,1-22,47c-3,45-5,51-24,49c-18-2-21,3-19,35
		c3,49-3,64-26,57c-16-5-19-1-19,22c0,16-6,38-14,48c-11,16-17,17-29,7c-13-11-17-8-27,22c-13,40-53,86-75,86c-20,0-20-58-1-105
		c16-38,15-40-15-25c-32,18-35-15-5-55c15-19,22-35,17-35c-20,0-11-36,14-60c19-18,22-26,14-37c-9-10-5-21,15-48c15-19,32-35,38-35
		c7,0,3-6-8-14c-18-14-18-16,13-48c22-23,31-40,27-54c-7-26,65-94,99-94c26,0,20,13,136-290c91-240,112-314,119-415c5-83,4-89-11-76
		c-11,9-17,29-17,57c0,70-28,183-65,259c-55,114-136,193-160,155c-4-6-1-44,5-86s10-77,8-80c-2-2-17,17-33,41c-15,25-34,45-41,45
		c-8,0-14,5-14,10c0,6-9,14-19,18c-11,4-30,23-43,42c-14,21-32,35-44,35c-12,1-33,8-47,18c-15,9-37,18-49,20c-19,2-23-2-20-23
		c1-14,11-37,22-52c16-22,18-28,6-28c-26,0-29-21-6-45c16-17,18-24,8-28c-15-5,45-73,73-82c9-3,20-17,23-30s21-34,39-47
		c17-12,39-30,47-39c25-28,68-51,74-40c4,5-2,15-13,21c-34,18-41,42-23,80c9,19,18,46,22,58c4,20,20,0,100-123
		c52-81,102-152,110-159c42-30,25-34-29-7c-57,29-81,54-81,82c0,7,7,4,15-7c25-34,16,0-12,41c-46,69-95,125-104,119
		c-15-9-11-18,25-60c19-22,37-56,41-76c12-63,36-90,120-132c51-26,81-48,83-60c3-16-1-15-32,9c-20,15-39,25-43,21
		c-6-5,52-62,132-129c35-30,27-47-10-23c-14,9-25,12-25,7s15-18,33-30c31-20,31-21,7-21c-37,0-67,25-88,73c-22,49-66,90-116,108
		c-27,10-36,19-41,46c-13,70-77,156-103,139c-5-3-18,4-28,15s-34,27-53,35c-20,9-53,25-74,36s-45,17-53,14c-13-5-13-10,0-41l15-35
		l-39,6c-33,5-40,4-40-9c0-26,83-104,157-148c32-18,40-28,32-36c-9-9-4-18,16-37l28-26h-77c-76,0-136-16-151-41c-12-18,40-56,97-70
		l49-11l-26-24c-14-12-22-29-19-37c5-15,111-50,125-42c4,3,10,1,13-4c4-5,16-8,29-6c110,16,110,16,98,1c-20-24-4-36,50-36
		c35,0,49-4,49-14s-18-15-60-18c-49-4-66-9-88-31l-26-27l22-22c49-50,228-18,226,39c0,16,7,23,28,27c16,3,49,20,74,36
		c24,17,60,38,79,46c39,16,130,19,120,4c-3-5-18-10-33-10c-37,0-72-11-72-21c0-5,7-9,16-9c24,0,1-25-53-56c-57-34-79-25-25,11
		c23,15,40,30,38,32c-6,6-43-14-84-44c-44-33-29-53,39-53c37,0,49-4,49-15c0-41,138,14,174,69c9,14,23,26,32,26c8,0,12,4,9,10
		c-8,14,51,48,96,55c43,7,99-2,99-16c0-5-11-7-24-3c-32,8-131-13-146-31c-9-11-3-11,36,1c26,8,67,14,92,14h44l-20-32
		c-25-41-94-78-144-78c-55,0-47,15,17,29c30,7,65,21,77,32l23,20l-40-6c-63-10-109-25-136-46c-37-28-16-53,47-57c66-4,151,38,179,89
		c17,32,23,35,40,25c11-6,25-17,31-25c9-11,12-12,19-1c5,9,4,11-3,6c-7-4-12-2-12,3c0,6,7,11,15,11s15-4,15-10c0-5,5-10,11-10
		c5,0,7,6,4,13c-4,6,0,4,8-5c8-10,16-16,18-14s19,6,37,9c25,5,31,4,26-6c-5-7,0-5,11,3c10,8,16,10,12,4c-5-9,11-11,55-9
		c36,1,64,7,66,13c2,7,8,12,13,12c6,0,7-4,4-10c-8-12-3-13,29,0c21,8,26,6,26-6s2-12,14-2c8,7,10,15,5,16c-18,7-8,20,11,15
		c11-3,17-10,13-17s-1-6,9,1c8,7,18,10,22,6s4,1,0,12c-7,17-5,18,7,8s18-9,31,7c16,17,15,19-2,17c-11-1-16-7-13-17c4-13,4-13-6,0
		c-17,24,18,46,39,25c12-13,13-17,1-24c-24-15-4-27,38-23c40,4,49,22,10,22c-10,0-19,3-19,6c0,24,65,104,85,104c6,0,20,7,31,16
		c18,14,18,15-6,8c-14-3-40-17-57-31c-44-32-42-13,2,26c39,34,105,44,105,17c0-16-41-52-89-78c-17-9-31-22-31-28s12-2,26,9
		c48,38,135,21,118-23c-4-11-1-16,10-16c9,0,16,4,16,9c0,9,64-9,76-22c14-13-50-19-87-8c-23,7-59,9-84,5c-50-7-61-24-17-24
		c18,0,44-13,69-35c23-19,47-35,55-35c7,0-5,16-27,35c-42,36-39,42,12,25c29-11,53-45,53-75c0-17,26-20,35-5c3,5-2,23-11,40
		c-10,16-14,33-10,37c5,4,11,1,14-7c11-31,70-79,113-90c68-19,249,12,249,42c0,4,12,8,28,8c35,0,72,26,72,51c0,10-9,28-21,40
		c-11,11-18,24-14,27c3,4,9,2,13-4s25-11,47-12c31,0,35,2,20,8c-11,5-14,9-7,9c7,1,26-9,42-21c44-32,137-37,170-10
		c20,17,23,24,14,39c-18,29-56,44-107,41c-34-2-47,1-47,11c0,8,6,11,16,7c14-5,15-4,5,8c-19,22,4,29,38,11c48-24,150-20,192,8
		c19,12,34,29,34,37s-20,25-45,38c-51,26-124,25-166-3c-13-8-25-15-26-15c-2,0-2,18,0,40c4,36,7,40,31,40c37,1,111,36,111,54
		c0,8,12,21,28,28c41,22,72,42,72,49c0,4,20,27,45,51c27,27,45,55,45,68c0,20-4,22-32,16c-32-6-33-5-33,31c0,46-14,51-53,18
		c-16-14-39-25-49-25c-11,0-32-11-47-25s-33-25-41-25s-24-13-36-29l-21-29l-23,26l-22,26l57,27c32,15,62,33,67,41c4,7,25,26,46,41
		c20,15,37,35,37,43c0,7,6,14,13,14s28,16,47,35c28,28,31,35,17,41c-13,5-12,10,11,30c24,22,25,26,11,36c-15,10-14,15,7,46
		c43,63,21,90-43,52c-23-14-39-18-41-11c-7,22-28,10-86-49c-32-33-67-60-77-60s-19-3-19-7c0-5-13-23-29-42l-28-34l14,88
		c15,98,9,120-29,96c-19-12-20-11-14,6c4,10,30,86,57,170c46,137,53,152,77,158c35,9,97,74,83,88c-7,7,0,21,21,45c27,31,29,37,15,47
		c-15,10-13,15,13,40c32,31,38,50,18,58c-9,3-4,14,15,34c27,29,36,63,17,63c-5,0-1,15,10,33c19,30,27,67,14,67c-3,0-15-5-26-11
		c-18-10-20-9-14,7c14,37,19,111,7,118c-17,11-80-57-87-93c-5-25-9-29-20-20c-20,16-33,3-40-40c-4-27-11-38-23-37s-17-9-19-41
		c-2-31-8-43-19-43c-12,0-14-9-11-40c2-28,0-40-9-40c-12,0-18-31-13-73c1-10-4-16-12-15c-22,5-22-69,1-112l20-39l-23-63
		c-13-35-44-124-69-199c-25-74-56-153-68-173c-12-21-33-66-46-100l-23-61l5,115c2,63,1,230-3,370l-7,255h-93c-50,0-92,3-92,6
		s25,14,54,25c30,10,54,24,53,29c-1,6-4,9-7,9c-3-1-15-5-27-9c-30-8-29,5,2,28c24,17,25,17,63-18c64-57,73-43,16,24
		c-20,23-34,44-31,47c9,10,41-17,72-59l30-42l5-234c3-161,9-236,16-238c20-7,23,22,25,252l1,226l-53,77l-54,77v83c-1,56-5,86-13,91
		c-12,7-415,21-623,21c-116,0-118-2-114-97c2-57,0-64-27-90c-42-40-138-158-138-170c0-5-9-18-19-27c-11-10-23-31-26-47
		c-9-39-25-47-25-11c0,37-29,147-80,302c-43,133-49,180-25,200c10,8,15,31,15,70c0,113-45,130-336,130C882,2539,834,2535,795,2522z
		 M1154,2484c27-5,37-3,32,5c-5,7,3,11,23,11c24,0,31-4,31-19c0-11,4-22,9-25s11-15,14-26c5-18,0-20-44-20c-42,0-49,3-49,20
		c0,11,5,20,11,20c5,0,7,5,4,10c-15,24-35,9-35-27c0-40-16-35-22,7c-5,39-34,32-30-7c2-18-1-33-7-33c-5,0-12,15-14,33
		c-3,27-4,26-5-11l-2-43l108,3l107,3l3,32c2,22-3,37-19,52c-19,18-20,21-6,21c29,0,57-37,57-75c0-29-5-37-27-46c-30-11-418-14-489-3
		l-44,6v48c0,48,9,63,45,73C828,2500,1097,2492,1154,2484z M800,2330c0-5-4-10-9-10c-6,0-13,5-16,10c-3,6,1,10,9,10
		C793,2340,800,2336,800,2330z M1295,2322c-3-3-11,0-18,7c-9,10-8,11,6,5C1293,2331,1298,2325,1295,2322z M1250,2319
		c0-14-108-30-200-30c-100,0-243,18-236,30C819,2327,1250,2327,1250,2319z M814,2282c5-8-27-146-58-249c-63-209-64-220-68-617
		c-2-273,0-350,10-353c9-4,12,28,12,137v142l23-6c168-49,476-49,600-1c16,7,18,2,15-61c-2-53-6-69-18-69c-11,0-17,15-21,55l-6,55
		l-1-57c-2-44-6-58-17-58s-15,12-15,50c0,28-4,50-10,50c-5,0-10-22-10-50c0-54,3-52-85-64c-118-16-205-66-205-119c0-30-10-40-27-26
		c-21,18-88,14-125-8c-52-30-78-77-78-139c0-44,5-59,33-96c18-24,46-49,62-57c44-21,11-26-41-6c-68,26-75,44-75,185
		c1,93-2,121-12,117c-9-3-12-38-12-130c0-70-1-127-2-127s-10,14-18,31c-14,27-16,87-12,517c3,355,7,499,17,532
		c75,266,126,430,132,430C806,2290,811,2286,814,2282z M1299,2233c7-32,28-105,46-163c67-210,85-340,85-635c0-93,4-176,9-184
		c11-16,31-6,31,16c0,9,10,52,23,97l22,81l5-105c4-84,8-105,20-105c13,0,15,45,18,332l2,333l108-6c59-3,112-7,118-9s14-20,18-39
		c9-45,36-50,36-7c0,17,3,32,8,35c4,2,81,1,171-4c118-6,166-12,173-22c5-7,8-136,6-292c-2-217,0-280,10-284c14-4,12-25,21,323l6,250
		l55,8c30,4,65,5,78,2l22-5v-360v-359l-31,11c-37,13-94-1-125-30c-11-10-40-21-64-25c-33-4-52-14-73-37c-27-30-27-33-22-118
		c7-88,7-153,2-159c-4-3-187,16-203,22c-8,2-7,19,3,57c8,29,16,96,17,150c1,89-1,98-23,122c-46,49-214,81-448,85l-33,1v233
		c0,326-2,337-109,751c-12,43-18,82-15,87C1278,2301,1287,2287,1299,2233z M2886,2263c-11-49-28-83-37-77c-11,6-1,51,17,82
		C2885,2298,2894,2296,2886,2263z M186,2245c7-19,14-45,13-57c0-18-4-15-19,17c-18,40-25,75-14,75C169,2280,178,2264,186,2245z
		 M1233,2253c3-16,4-29,3-30s-19,2-41,7c-61,14-178,13-173-2c3-7,22-13,44-14c89-6,164-16,169-24c12-19-103-32-240-27
		c-158,6-165,7-165,19c0,13,19,18,90,26c40,4,65,12,68,21c4,11-6,13-54,8c-33-3-67-8-76-12c-14-6-18-2-18,18v25l133-5
		c110-4,158-1,248,16C1225,2279,1230,2268,1233,2253z M1784,2240c31,0,32-2,28-31c-4-30-5-30-46-24c-51,9-56,12-56,34
		c0,23,10,33,28,26C1745,2242,1766,2240,1784,2240z M2060,2235c0-3-10-16-22-30c-29-33-58-32-93,5l-29,30h72
		C2028,2240,2060,2238,2060,2235z M2155,2211c0-20-45-51-88-61c-47-10-47,2-2,47c26,26,41,34,62,31C2143,2226,2155,2219,2155,2211z
		 M2376,2219c13-22-23-37-107-42l-86-6l1,30l1,29h92C2333,2230,2371,2226,2376,2219z M1919,2194c42-35,40-52-2-20
		c-36,27-57,33-57,15c0-5-4-7-10-4c-5,3-10,13-10,21C1840,2229,1886,2222,1919,2194z M265,2130c-4-17-11-30-16-30c-10,0-12,50-3,75
		c6,16,8,16,16,1C267,2167,268,2146,265,2130z M2814,2159c7-36-2-58-15-38c-11,18-11,69,0,69C2804,2190,2810,2176,2814,2159z
		 M2877,2132c-26-26-37-32-37-20c0,17,60,71,69,62C2912,2171,2897,2153,2877,2132z M1848,2148c83-14,90-29,10-22
		c-103,11-141,19-145,31C1708,2170,1730,2168,1848,2148z M204,2121c11-12,16-24,13-28c-8-7-67,45-67,59C150,2164,182,2146,204,2121z
		 M1280,2046c13-55,24-117,24-139c2-72-19-49-38,42c-21,104-40,164-53,168c-6,2,3-47,19-109c15-62,27-122,26-133c-2-16-16-22-68-32
		c-122-23-401-6-434,27c-6,6,2,49,23,122c17,62,31,121,31,130c0,26,14,29,67,17c66-14,257-12,318,4c28,7,53,11,56,8
		S1267,2100,1280,2046z M2368,2148c-3-7-29-16-59-20c-71-8-214-10-206-2c4,5,162,25,255,33C2366,2159,2370,2154,2368,2148z
		 M2000,2129c0-24-23-21-28,4c-2,10,3,17,12,17C1994,2150,2000,2141,2000,2129z M2075,2077c17-31,25-57,24-88l-1-44l-7,35
		c-4,20-17,56-29,81s-22,48-22,52C2040,2129,2051,2118,2075,2077z M2398,2082c8-23,8-32,1-30c-6,2-9,6-7,9s-3,13-10,22
		c-8,9-11,21-7,27C2384,2124,2384,2123,2398,2082z M2780,2080c9-23,10-34,2-42s-14-4-22,16C2737,2116,2755,2140,2780,2080z
		 M306,2055c-4-19-11-37-16-40c-19-12-21,23-5,61C304,2120,316,2107,306,2055z M1863,2100h29l-36-41c-39-43-58-80-58-116
		c0-20-6-23-37-23c-76,0-202,13-199,21c2,4,36,45,77,90l75,81l59-6C1806,2103,1846,2100,1863,2100z M1913,2058c-28-29-58-68-68-86
		c-24-49-30-32-7,20c19,43,91,118,114,118C1959,2110,1941,2086,1913,2058z M2290,2080h85l-95-94c-90-89-97-93-131-89l-36,5l11,39
		c10,38,4,78-19,133c-8,18-5,19,46,13C2181,2083,2243,2080,2290,2080z M2860,2049c-40-32-50-35-50-14c0,15,71,57,80,48
		C2893,2081,2879,2065,2860,2049z M243,2032c18-16,22-32,8-32c-13,0-71,50-71,61S216,2055,243,2032z M2010,2028c0-24-5-50-10-58
		c-7-11-10,2-10,43c0,31,4,57,10,57S2010,2051,2010,2028z M1962,2016c-4-47,11-86,32-86c23,0,37,26,39,74c2,38,4,43,14,30
		c11-16,29-121,22-129c-2-2-52-1-111,2l-107,6l20,38c20,39,76,109,88,109C1962,2060,1964,2040,1962,2016z M2757,1982c3-13,3-26-1-29
		c-12-12-27,13-32,53c-5,37-5,38,11,19C2744,2014,2753,1995,2757,1982z M340,1945c-6-19-16-33-21-30c-14,9-11,43,8,78
		c16,30,18,31,21,9C350,1990,346,1964,340,1945z M2860,1992c0-5-12-17-27-28c-47-34-53-36-53-19c0,8,15,24,33,35
		C2848,2002,2860,2005,2860,1992z M250,1960c39-20,53-40,27-40c-14,0-87,59-74,60C207,1980,229,1971,250,1960z M2718,1925
		c14-30,15-55,4-55c-10,0-32,45-32,65C2690,1958,2706,1952,2718,1925z M378,1848l-13-23l-3,27c-2,15,3,37,10,50l13,23l3-27
		C390,1883,385,1861,378,1848z M1537,1895c-7-13-14-20-15-14c-2,5,2,16,8,24C1548,1926,1550,1923,1537,1895z M1835,1900
		c3-5-1-10-9-10c-9,0-16,5-16,10c0,6,4,10,9,10C1825,1910,1832,1906,1835,1900z M2790,1869c-36-31-54-37-48-16c6,16,70,59,77,51
		C2822,1901,2809,1885,2790,1869z M2230,1875c-7-9-15-13-18-10c-3,2,1,11,8,20s15,13,18,10C2241,1893,2237,1884,2230,1875z
		 M283,1874c31-16,47-31,47-46s-15-7-55,27C231,1893,234,1899,283,1874z M2695,1829c8-34-1-51-15-29c-12,19-13,73-1,65
		C2684,1862,2691,1846,2695,1829z M1501,1779c-6-20-18-43-27-50c-15-12-16-9-9,31c4,25,15,59,23,75l16,30l4-25
		C1510,1827,1507,1799,1501,1779z M2430,1533c0-179-2-324-4-322c-9,10-14,649-5,649C2426,1860,2430,1733,2430,1533z M420,1785
		c-6-19-16-35-21-35c-15,0-10,44,8,70C428,1850,435,1832,420,1785z M819,1818c70-17,353-14,431,5c36,8,67,13,69,11
		c11-11,19-131,20-291l1-172l-52-15c-29-8-98-20-153-27c-85-10-121-9-228,5c-71,9-144,23-164,31l-36,15l6,188c6,201,19,277,45,267
		C766,1832,794,1824,819,1818z M2765,1795c-34-28-45-31-45-12c0,15,35,35,60,36C2789,1819,2783,1810,2765,1795z M370,1750
		c0-16-26-11-54,11c-39,31-31,42,14,19C352,1769,370,1755,370,1750z M1503,1674c-23-47-47-59-37-17c7,31,47,80,52,65
		C1520,1716,1513,1695,1503,1674z M1514,1593c-8-34-35-83-46-83c-5,0-5,12-2,28c8,33,35,82,46,82C1517,1620,1517,1608,1514,1593z
		 M1530,1508c-1-38-2-41-14-25c-11,14-11,23-2,42c6,14,12,25,14,25C1529,1550,1530,1531,1530,1508z M1485,1437c-8-20-17-37-19-37
		c-7,0,4,52,16,74C1500,1507,1502,1478,1485,1437z M132,1403c27-26,37-53,19-53c-4,0-20,18-35,40C86,1436,92,1442,132,1403z
		 M2934,1398c-15-26-48-58-61-58c-22,0-15,17,18,44C2923,1411,2946,1418,2934,1398z M215,1338c17-41,12-47-17-21c-10,9-18,30-18,47
		C180,1400,192,1391,215,1338z M2846,1374c-3-9-6-22-6-29c0-14-40-58-47-51s37,96,48,96C2847,1390,2849,1383,2846,1374z M454,1305
		c52-81,86-172,97-263c6-46,8-86,5-90c-18-17-103,150-115,227c-12,75-7,89,14,38c13-32,35-58,35-42c0,13-70,144-81,151
		c-7,4-9,0-6-12c3-11,9-41,13-69c4-36,3-47-5-40c-12,12-31,101-31,146c0,27,2,28,21,17C412,1361,436,1333,454,1305z M2615,1280
		c-12-76-52-197-62-187c-7,7,39,221,54,250c8,16,16,27,18,25C2627,1367,2622,1327,2615,1280z M179,1316c31-17,57-44,48-52
		c-8-8-90,30-106,49c-12,15-11,17,8,17C141,1330,163,1324,179,1316z M273,1297c18-27,38-97,29-97c-11,0-49,63-56,93
		C238,1326,252,1328,273,1297z M2770,1290c0-29-59-110-60-82c0,26,43,112,56,112C2768,1320,2770,1307,2770,1290z M2910,1310
		c0-12-46-36-90-45c-32-7-34-6-20,10C2827,1307,2910,1334,2910,1310z M2540,1241c-11-34-20-69-20-76s-7-30-16-50c-22-53-35-27-19,35
		c14,55,63,165,70,159C2557,1306,2550,1276,2540,1241z M263,1222c42-37-4-33-78,7l-40,21l50-6C223,1240,253,1231,263,1222z
		 M2684,1218c-7-41-24-78-37-78s-1,55,19,85C2687,1258,2691,1257,2684,1218z M2850,1221c-19-10-55-22-80-26l-45-7l24,26
		c21,22,33,26,80,26l56-1L2850,1221z M283,1174c3-3,3-12,0-20c-4-11-10-11-34,2c-16,8-29,17-29,19C220,1182,276,1181,283,1174z
		 M620,1170c0-5-2-10-4-10c-3,0-8,5-11,10c-3,6-1,10,4,10C615,1180,620,1176,620,1170z M1572,1156c6-8,8-7,8,2c0,6,7,12,15,12
		s15-7,15-15c0-18,17-20,22-4s128-8,169-33c53-32,35-49-82-80c-74-19-110-22-299-23c-174,0-229,3-290,18c-76,18-120,39-120,57
		c0,26,156,71,295,84C1377,1180,1565,1168,1572,1156z M2775,1149c-22-10-55-19-74-19c-33,0-34,0-16,20c14,16,30,20,74,19l56-1
		L2775,1149z M2450,1119c0-12-7-19-18-19c-27,0-32,9-20,36c9,18,14,21,25,13C2444,1143,2450,1129,2450,1119z M2643,1105
		c12-2,36-6,52-8l30-3l-37-17c-48-22-69-22-61-1c5,15,3,15-26,0c-37-20-38-17-8,34c18,32,23,36,25,20C2620,1119,2631,1108,2643,1105
		z M2377,1098c9-7,0-16-35-34c-26-13-53-24-59-24c-26,0-12,18,25,33c24,11,30,16,17,16c-11,1-32-6-47-13c-29-15-47-6-25,12
		C2290,1119,2347,1123,2377,1098z M169,1064c23-30,30-64,9-46c-20,17-50,72-39,72C144,1090,158,1078,169,1064z M2896,1024
		c-23-33-40-30-20,4c13,22,11,21-15-8c-17-19-31-31-31-26c0,17,23,52,48,73c27,23,27,23,30,2C2910,1058,2904,1038,2896,1024z
		 M2456,1055c9-23-5-65-22-65c-12,0-13,4-4,21c11,19,10,20-9,9c-12-6-21-15-21-20c0-6-7-10-15-10c-25,0-16,24,18,48
		c19,13,26,22,16,22c-9,0-28-11-41-25c-21-21-27-23-37-11c-8,10-8,16,1,23C2375,1073,2448,1078,2456,1055z M225,1023
		c16-38,15-52-3-31c-12,16-27,68-18,68C207,1060,217,1044,225,1023z M2241,1043c11-12,11-17,2-20c-7-3-22-20-34-39
		c-15-24-25-32-32-25s-6,15,3,26c8,10,9,15,2,15c-6,0-16-9-22-20c-6-12-21-20-35-20c-34,0-33,39,1,74C2158,1065,2219,1070,2241,1043
		z M1075,1017c196-64,618-50,756,24l22,11l-6-93c-11-199-94-327-244-381c-83-29-264-29-345,0c-149,53-238,172-260,348
		c-12,95-7,134,15,116C1019,1037,1048,1025,1075,1017z M2565,1025c14-13,25-32,25-41c0-10-3-15-6-11c-3,3-18,1-32-4
		c-36-14-82,5-75,31c5,18,8,18,39,5c18-7,36-10,39-5s-11,14-30,20s-35,16-35,21C2490,1060,2542,1049,2565,1025z M2624,1017
		c8-32,0-32-17,2c-11,22-12,28-2,25C2612,1041,2621,1029,2624,1017z M2810,1003c-14-42-28-63-41-63c-14,0,1,51,24,83
		C2818,1059,2825,1051,2810,1003z M299,972c6-19,6-32,1-32c-13,0-37,40-46,75l-6,30l21-20C281,1013,294,990,299,972z M2329,1006
		c17-18,19-25,9-29c-7-2-21,2-31,11c-9,8-20,12-25,7c-4-4-1-10,6-12c18-7,15-21-7-28c-20-6-51,3-51,15c0,13,49,60,63,60
		C2300,1030,2317,1019,2329,1006z M160,1004c49-20,40-38-12-24c-40,12-72,40-44,40C112,1020,138,1013,160,1004z M924,1010
		c16-6,26-17,26-30c0-17-4-19-25-14c-40,10-74-4-95-39c-18-29-20-30-20-9c0,14,11,29,30,41c34,20,30,35-6,19c-12-6-30-26-39-45
		c-39-81,28-153,140-153c35,0,47-5,56-21c11-22,10-22-48-17c-69,7-136,37-159,74c-10,14-19,47-22,74c-4,47-3,52,36,90
		C840,1022,872,1030,924,1010z M2932,991c-34-29-112-57-112-40c0,10,29,27,85,50C2963,1025,2969,1023,2932,991z M359,945
		c29-62,26-76-9-37c-28,30-41,82-21,82C334,990,347,970,359,945z M2725,904c-36-56-42-6-6,61l16,30l3-33C2740,943,2734,918,2725,904
		z M2654,964c18-48-25-110-99-141c-46-18-53-27-35-38c8-5,7-11-1-21c-19-23,10-17,60,11c23,14,45,25,47,25c8,0,4-60-7-87
		c-13-34-55-80-91-99c-42-22-153-18-215,8c-53,22-62,31-43,43c18,11,10,22-29,40c-38,17-129,100-120,109c2,3,26-9,52-25
		c63-39,165-62,240-55c70,6,75,9,75,40c0,23-2,23-127,28c-120,4-130,6-188,37c-34,18-64,39-67,46c-3,8,11,3,31-11
		c52-35,96-45,218-51c96-4,117-2,167,17c68,25,122,80,115,116C2632,983,2645,989,2654,964z M247,939c-18-10-67-2-67,11s43,17,64,7
		C261,949,262,947,247,939z M2489,946c44-23,55-41,35-60c-12-12-27-15-60-11c-26,3-44,1-44-5c0-5,15-10,33-10c24,0,28-3,17-10
		c-8-5-42-9-75-9c-59,1-59,1-30,14c27,13,28,14,7,15c-12,0-31-6-43-12c-17-11-22-10-35,6c-21,29-18,35,29,56c23,11,34,20,24,20
		c-21,0-59-18-69-34c-5-8-10-8-18,0C2221,945,2423,980,2489,946z M2617,944c5-14-35-49-44-40c-4,4-1,11,7,16c12,8,10,10-7,10
		c-25,0-31,15-10,23C2584,960,2613,955,2617,944z M2184,920c-11-10-74-1-74,10c0,13,43,17,64,7C2184,932,2188,925,2184,920z
		 M2238,932c2-7-6-12-17-12c-21,0-27,11-14,24C2216,953,2233,946,2238,932z M935,926c20-15,47-104,34-112s-79,13-100,32
		C817,893,879,967,935,926z M2860,925c0-10-84-45-109-45c-23,0-23,1-6,19c9,11,34,22,54,24s42,4,49,5C2854,929,2860,928,2860,925z
		 M335,900c17-19,17-20,0-20c-26,0-85,22-85,32C250,929,317,920,335,900z M615,882c3-19,1-20-16-9c-22,14-24,23-6,30
		C608,908,610,907,615,882z M2256,879c24-26,10-35-22-14c-18,12-29,14-33,6c-5-8-13-7-27,3c-18,14-18,15,6,24
		C2210,909,2234,904,2256,879z M430,829c25-11,58-32,73-47c32-30,73-102,58-102c-27,0-227,117-275,161C266,859,386,849,430,829z
		 M2755,841c-6-5-27-13-47-16c-31-6-38-4-38,9c0,12,10,16,47,16C2746,850,2760,846,2755,841z M2650,820c10-19,10-20-9-8
		c-11,6-17,16-15,20C2634,844,2638,842,2650,820z M640,801c0-15,31-43,44-39c6,2,26-11,45-29c18-18,26-30,17-27s-24,0-33-7
		c-9-8-14-9-10-2c4,6-12,29-38,55c-45,43-54,58-35,58C636,810,640,806,640,801z M2319,774c2-2,2-7-1-10c-8-8-78,7-78,16
		C2240,788,2309,782,2319,774z M360,750c30-16,47-29,38-30c-37-1-16-18,30-24c47-6,105-35,72-36c-8,0-33-10-55-21
		c-37-20-155-39-155-25c0,4,12,11,26,17c47,17,18,21-37,5c-29-9-59-16-66-16c-26,0-12,17,27,35c41,19,52,35,24,35c-9,0-40,7-70,16
		c-54,16-64,34-18,34c13,0,24,5,24,11s-8,8-17,6c-50-14-93-17-93-7c0,19,43,29,130,29C295,779,312,776,360,750z M1847,745
		c-1-19-5-35-9-35c-3,0-14-3-24-7c-16-6-15-2,4,24c12,17,22,36,22,42s2,11,4,11C1847,780,1848,764,1847,745z M2180,712
		c-16-6-60,27-60,44c0,11,9,7,35-13C2174,728,2185,713,2180,712z M1969,741c9-6-1-11-31-15c-47-7-82,0-72,15
		C1873,752,1951,752,1969,741z M2103,725c8-16,6-17-19-10c-16,4-43,8-60,8c-19,0-30,5-28,12C2003,753,2091,745,2103,725z M1043,707
		c8-12,36-44,63-70l48-47l-33,3c-18,2-37,10-42,18s-9,10-9,6c0-5-7-1-16,8c-11,11-25,14-45,10c-16-4-29-2-29,3c0,13-124,46-143,39
		c-8-3-23,1-34,9c-17,14-17,15,3,9c11-3,29,1,40,9c14,11,37,13,87,9c47-4,67-2,67,6C1000,738,1027,731,1043,707z M2837,708
		c18-7,33-15,33-18c0-26-132-40-172-19c-21,11-20,12,13,30C2751,722,2791,725,2837,708z M2060,690c13-9,13-10,0-10c-8,0-27-6-42-14
		c-28-15-72-11-62,6C1971,696,2034,707,2060,690z M2200,681c0-4-37-16-82-26c-46-9-91-22-101-27c-28-15-32-2-5,16
		C2058,674,2200,702,2200,681z M775,666c18-8,39-24,45-36c17-31,7-34-20-5c-13,14-28,25-34,25c-6-1,2-12,19-25c34-28,27-45-9-21
		c-30,19-66,57-66,68C710,684,737,682,775,666z M600,650c0-5-2-10-4-10c-3,0-8,5-11,10c-3,6-1,10,4,10C595,660,600,656,600,650z
		 M948,624c40-13,81-24,92-24c34,0,31-19-4-25c-40-8-92,6-153,42c-42,23-61,53-25,38C867,651,908,637,948,624z M2190,640
		c-6-11-29-34-52-50c-51-37-130-43-148-11c-5,11-10,21-10,24c0,2,35,12,78,22c42,10,88,21,102,26C2198,663,2202,662,2190,640z
		 M570,643c0-5-19-16-42-25c-23-10-39-21-36-24c4-3,16,0,28,6c11,6,28,10,38,8c22-4-30-38-75-48c-37-9-42-4-15,16c16,13,16,13-2,14
		c-16,0-17,2-5,17C485,636,570,664,570,643z M1757,622c-2-28-24-45-31-25c-4,9,1,21,11,29C1760,643,1759,643,1757,622z M1968,624
		c-10-11-23-14-38-9c-18,6-20,9-9,16c8,5,25,9,39,9C1982,640,1983,639,1968,624z M726,609c8-6,11-15,8-20c-5-9-127-79-137-79
		c-21,0,0,20,45,45c29,16,47,30,40,33c-7,2-40-15-73-37c-87-58-110-56-63,7C593,619,683,645,726,609z M2312,583c74-50,47-69-49-37
		c-91,31-97,34-86,45c7,7,16,7,29,0c37-21,94-40,94-31c0,4-20,16-45,25c-24,10-42,22-39,26C2224,624,2270,611,2312,583z M2604,607
		c4,4,4,0,0-10c-3-9-17-17-30-17c-21,0-23,2-12,19c8,13,16,17,24,11C2592,604,2600,603,2604,607z M1705,581c-3-6-11-9-16-6
		c-5,4-9,2-9-3c0-13-53-38-86-41c-9,0-12-6-8-13s3-8-4-4c-18,11-14,26,6,27c9,0,40,13,67,28C1705,598,1718,600,1705,581z M2719,579
		c34-12,40-24,15-33c-37-14-124,3-124,25C2610,590,2672,595,2719,579z M1264,544c23-4,46-24,29-24c-7,0-14,4-18,10c-3,5-12,7-19,4
		c-8-3-17,0-21,6s-15,8-24,5c-11-3-18,0-18,9c0,11,6,12,26,4C1233,553,1254,546,1264,544z M2448,538c41-39,25-68-38-68
		c-34,0-59,24-34,34c8,3,27,3,41,0c18-5,24-3,19,5c-4,6-13,11-20,11c-8,0-21,12-31,26l-17,27l28-7C2412,562,2435,549,2448,538z
		 M960,551c0-5-24-20-52-34c-29-14-68-39-87-56c-34-31-61-40-61-21c0,18,90,100,118,106c15,3,32,7,37,9C935,562,960,560,960,551z
		 M2205,551c6-11-22-19-47-13c-10,2-18,8-18,13C2140,563,2197,563,2205,551z M2195,488c27-32,32-63,12-71c-23-9-91,21-114,50
		c-31,39-11,41,39,4c23-17,49-31,57-31c22,1-20,33-79,62c-42,21-43,22-20,28C2125,539,2166,523,2195,488z M2317,495
		c13-14,23-35,23-47c0-19-4-20-47-15c-33,3-52,12-64,28c-28,36-23,38,28,13c44-23,61-25,50-7c-3,4-28,17-56,28c-64,24-66,39-4,32
		C2282,523,2300,515,2317,495z M876,465c-22-19-44-35-50-35c-6,1,7,16,28,35c22,19,44,35,50,35C910,499,897,484,876,465z M521,468
		c22-6,39-14,39-18c0-13-111-32-146-25c-39,7-44,27-11,43C433,482,474,483,521,468z" />
				<path d="M830,2425c0-19,5-35,10-35c6,0,10,16,10,35s-4,35-10,35C835,2460,830,2444,830,2425z" />
				<path d="M890,2425c0-19,3-35,8-35c4,0,7,16,7,35s-3,35-7,35C893,2460,890,2444,890,2425z" />
				<path d="M955,2419c0-22,5.6-38,11.3-35c11.3,5,5.6,76-5.6,76C957.8,2460,955,2442,955,2419z" />
				<path
					d="M1013.5,2421.8c0-22,5-40,10-40c6,0,10,18,10,40s-4,40-10,40C1018.5,2461.8,1013.5,2443.8,1013.5,2421.8z" />
				<path d="M780,2425c0-14,5-25,10-25c6,0,10,11,10,25s-4,25-10,25C785,2450,780,2439,780,2425z" />
				<path d="M1610,1750c0-47,4-80,10-80s10,33,10,80s-4,80-10,80S1610,1797,1610,1750z" />
				<path d="M2138,1790c-5-30,3-46,16-33c9,10,7,63-3,63C2146,1820,2140,1806,2138,1790z" />
				<path d="M1714,1759c-3-6,0-17,8-25c13-13,16-13,27,0c7,8,10,20,6,26C1747,1773,1723,1773,1714,1759z" />
				<path d="M2044,1755c-9-23,14-45,26-25c5,8,8,19,7,24C2071,1771,2050,1772,2044,1755z" />
				<path d="M2130,1685c0-25,5-45,10-45c6,0,10,20,10,45s-4,45-10,45C2135,1730,2130,1710,2130,1685z" />
				<path d="M1805,1680c-12-19,24-59,64-71c47-14,85-2,122,37c17,19,20,27,11,36s-18,6-40-15c-36-35-80-36-120-2
		C1822,1681,1809,1687,1805,1680z" />
				<path d="M1679,1585c-74-40-68-202,7-221c40-10,71,13,89,67c13,37,14,56,6,86c-13,47-20,57-48,72C1707,1602,1711,1603,1679,1585z
		 M1741,1533c15-39,5-102-20-127c-19-18-22-19-36-5c-24,24-29,80-11,122C1693,1569,1725,1574,1741,1533z" />
				<path d="M1857,1538c-28-44-60-159-48-171c8-8,14-3,23,21c18,47,51,44,64-5c15-61,29-39,26,40c-3,90-14,131-35,135
		C1879,1560,1865,1551,1857,1538z M1890,1480c0-20-5-30-15-30c-17,0-18,4-9,38C1875,1520,1890,1515,1890,1480z" />
				<path d="M1948,1549c-18-12,2-29,34-29h30l-5-84c-4-86,2-114,22-102c7,4,11,40,11,91v84l33,3c20,2,32,8,32,18c0,12-17,16-73,20
		C1991,1553,1953,1552,1948,1549z" />
				<path d="M2087,2194c-16-9-27-18-24-21c6-5,67,24,67,32S2116,2210,2087,2194z" />
				<path d="M1182,1989c3-8,11-40,18-72c13-66,34-79,24-14c-11,68-19,90-34,95C1182,2001,1179,1997,1182,1989z" />
				<path d="M1725,2030c-44-45-66-53-40-14c22,34,9,46-16,14c-24-32-21-64,7-68c15-2,34,11,63,45c22,26,41,51,41,56
		C1780,2079,1762,2068,1725,2030z" />
				<path d="M1275,1791c-3-5-1-12,5-16c5-3,10,1,10,9C1290,1802,1284,1805,1275,1791z" />
				<path d="M764,1776c-8-20-1-67,10-64c11,4,15,78,4,78C773,1790,767,1784,764,1776z" />
				<path d="M868,1761c-27-21-48-79-48-131c0-66,45-120,99-120c46,0,91,72,91,143c0,38-29,106-51,117C931,1786,895,1782,868,1761z
		 M957,1713c20-50,13-127-15-156c-30-30-68-11-78,38C843,1711,918,1806,957,1713z" />
				<path d="M1117,1753c-37-6-29-27,11-31l32-3v-94c0-58,4-96,11-101c21-12,29,21,28,106c-2,76,0,85,17,89c25,4,45,21,38,32
		C1249,1760,1171,1761,1117,1753z" />
				<path d="M1280,1720c0-16,5-30,10-30c6,0,10,14,10,30c0,17-4,30-10,30C1285,1750,1280,1737,1280,1720z" />
				<path
					d="M1056,1692c-6-10-46-153-46-164c0-5,7-8,15-8c9,0,18,12,22,30c8,41,38,40,52-1c6-18,16-29,23-26c15,5,15,1-2,87
		c-7,36-17,71-23,78C1086,1702,1064,1704,1056,1692z M1078,1611c-15-6-20,7-12,31l8,23l6-25C1083,1626,1082,1613,1078,1611z" />
				<path d="M760,1670c0-5,5-10,10-10c6,0,10,5,10,10c0,6-4,10-10,10C765,1680,760,1676,760,1670z" />
				<path d="M1277,1654c-8-8,1-24,14-24c5,0,9,7,9,15C1300,1660,1288,1665,1277,1654z" />
				<path
					d="M790,1496c0-25,45-31,250-31c140,0,201,4,218,13c37,21,16,34-41,25c-67-10-318-10-379,0C798,1510,790,1509,790,1496z" />
				<path d="M807,1444c-4-4-7-18-7-31c0-28,23-27,28,1C832,1434,817,1454,807,1444z" />
				<path d="M1270,1415c0-25,4-35,13-32c6,2,12,16,12,32s-6,30-12,33C1274,1450,1270,1440,1270,1415z" />
				<path d="M870,1405c0-20,4-26,18-23c9,2,17,12,17,23s-8,21-17,23C874,1431,870,1425,870,1405z" />
				<path d="M1076,1423c-12-13-6-53,9-53c9,0,15,9,15,24C1100,1420,1088,1434,1076,1423z" />
				<path d="M1193,1414c-7-18,11-39,26-30c17,11,13,46-4,46C1206,1430,1197,1423,1193,1414z" />
				<path d="M476,1151c-8-13,23-98,42-113c14-11,15-10,8,7c-4,11-14,41-22,68C489,1161,485,1166,476,1151z" />
				<path d="M1337,1153c-13-13-7-21,13-16c11,3,17,9,14,14C1358,1162,1346,1163,1337,1153z" />
				<path d="M1471,1141c-9-6-6-10,10-14c28-8,34-3,16,11C1490,1144,1478,1145,1471,1141z" />
				<path d="M1579,1119c-7-5-12-9-9-10c61-11,76-8,42,10C1601,1125,1588,1125,1579,1119z" />
				<path d="M1211.2,1124.8c-14-13-6-20,18-15c14,2,22,8,19,13C1242.2,1133.8,1220.2,1134.8,1211.2,1124.8z" />
				<path d="M1386,1100c0-5,9-10,21-10c11,0,17,5,14,10c-3,6-13,10-21,10S1386,1106,1386,1100z" />
				<path d="M1686,1100c3-5,10-10,16-10c5,0,9,5,9,10c0,6-7,10-16,10C1687,1110,1683,1106,1686,1100z" />
				<path d="M1110,1084c0-8,5-12,10-9c6,3,10,10,10,16c0,5-4,9-10,9C1115,1100,1110,1093,1110,1084z" />
				<path d="M1290,1071c-21-8-15-22,7-16c11,3,20,9,20,14C1317,1079,1309,1079,1290,1071z" />
				<path d="M1740,1070c0-5,5-10,10-10c6,0,10,5,10,10c0,6-4,10-10,10C1745,1080,1740,1076,1740,1070z" />
				<path d="M1615,1059c-10-14-1-21,19-13c9,3,13,10,10,15C1637,1073,1623,1072,1615,1059z" />
				<path d="M1190,1060c0-5,5-10,10-10c6,0,10,5,10,10c0,6-4,10-10,10C1195,1070,1190,1066,1190,1060z" />
				<path d="M1440,1040c0-5,4-10,9-10c6,0,13,5,16,10c3,6-1,10-9,10C1447,1050,1440,1046,1440,1040z" />
				<path d="M1540,1040c0-5,5-10,10-10c6,0,10,5,10,10c0,6-4,10-10,10C1545,1050,1540,1046,1540,1040z" />
				<path d="M2143,1016c-15-13-26-26-23-29s21,8,40,24s29,29,23,29C2176,1040,2158,1029,2143,1016z" />
				<path d="M1787,928c-8-105-38-177-104-249c-31-33-54-62-51-64c10-10,99,78,125,122c31,54,53,138,53,211
		C1810,1026,1794,1011,1787,928z" />
				<path
					d="M1030,959c0-71,49-209,84-238c22-19,20-6-8,42c-28,47-56,145-56,194c0,18-4,33-10,33C1035,990,1030,976,1030,959z" />
				<path d="M1745,978c-2-7-6-35-8-63c-3-27-8-58-12-67c-4-11-3-18,4-18c15,0,27,33,35,103c5,39,4,57-4,57C1754,990,1748,984,1745,978z
		" />
				<path d="M1086,971c-8-13,3-61,15-61c5,0,6,15,2,33C1096,981,1094,984,1086,971z" />
				<path d="M1256,898c-18-26-5-58,24-58c25,0,40,29,29,58C1298,926,1276,926,1256,898z" />
				<path d="M1520,905c-26-31,13-82,44-56c19,16,21,42,4,59C1552,924,1535,923,1520,905z" />
				<path
					d="M1360,832c0-38,48-55,91-32c22,13,26,50,4,50c-8,0-15-6-15-14c0-23-29-27-47-6C1371,855,1360,855,1360,832z" />
				<path d="M1177,833c-11-10-8-53,3-53c6,0,10,14,10,30C1190,841,1188,845,1177,833z" />
				<path d="M1220,811c0-17,5-31,10-31c6,0,10,11,10,24c0,14-4,28-10,31C1224,839,1220,828,1220,811z" />
				<path d="M1585,820c-3-11-3-24,0-30c12-19,22-10,22,20C1607,844,1594,850,1585,820z" />
				<path d="M1633,810c0-30,10-39,22-20c8,12-3,50-14,50C1637,840,1633,827,1633,810z" />
				<path d="M1690,767c-12-26-40-63-62-82c-46-42-44-58,2-25c42,30,106,128,92,142C1715,809,1705,798,1690,767z" />
				<path d="M1190,673c0-13,70-54,81-48c5,4-7,17-26,31C1209,680,1190,686,1190,673z" />
				<path d="M1518,621c-27-9-48-22-48-29c0-9,7-11,23-6c50,15,87,34,87,43C1580,642,1579,642,1518,621z" />
				<path d="M2570,734c-5-16-15-37-21-47c-16-29,0-34,22-7c26,34,33,60,20,73C2584,760,2578,755,2570,734z" />
				<path d="M2310,708c0-11,168-10,174,0c3,5-35,8-84,8C2350,715,2310,712,2310,708z" />
				<path d="M2435,680c-17-7-15-9,12-9c17-1,35,4,38,9C2492,692,2463,692,2435,680z" />
				<path d="M2330,649c0-10,40-19,80-19s80,9,80,19c0,3-36,6-80,6S2330,652,2330,649z" />
				<path
					d="M2338,908c-16-5-28-14-28-19c0-6,8-6,19,1c11,5,52,10,91,10c45,0,69,4,65,10C2477,923,2374,922,2338,908z" />
				<path d="M380,823c0-5,20-19,45-31s45-18,45-13s-17,17-37,26c-21,10-41,20-45,22C383,829,380,827,380,823z" />
				<path d="M240,720c0-5,25-10,55-10s55,5,55,10c0,6-25,10-55,10S240,726,240,720z" />
				<path d="M378,653c-10-2-18-9-18-15c0-5,7-7,16-4s20,6,25,6s9,5,9,10C410,660,407,661,378,653z" />
				<path d="M2737,688c-7-4,7-8,31-8c23,0,42,4,42,10C2810,700,2755,699,2737,688z" />
				<path d="M2040,601c-22-4-31-9-23-14c12-7,93,9,93,18C2110,611,2087,610,2040,601z" />
				<path d="M2668,563c12-2,30-2,40,0c9,3-1,5-23,4C2663,567,2655,565,2668,563z" />
				<path d="M425,450c-15-7-10-9,23-9c23-1,42,4,42,9C490,462,453,462,425,450z" />
				<path
					d="M431,2506c-14-16-11-18,92-75c53-29,77-33,77-11c0,4-21,20-47,35c-27,15-62,36-79,46C447,2517,441,2518,431,2506z" />
				<path d="M1511,2477c-74-42-100-68-64-66c30,2,158,73,158,88C1605,2524,1587,2520,1511,2477z" />
				<path d="M1980,2486c-53-17-130-65-130-81c0-20,14-19,31,1c25,31,94,57,161,62c76,5,138-12,192-53c20-15,37-22,40-16
		c8,12-48,55-99,77C2126,2496,2027,2501,1980,2486z" />
				<path d="M2545,2479c-16-11-60-35-97-54c-70-36-86-51-71-66c6-6,39,8,87,35c42,24,90,49,106,56c18,8,30,20,30,31
		C2600,2505,2583,2504,2545,2479z" />
				<path d="M1670,2441c0-12,83-60,93-54c12,7-2,22-43,43C1676,2452,1670,2454,1670,2441z" />
				<path d="M2627,2413c-11-10-8-62,3-84c14-26,33-24,69,6c35,30,32,60-5,51c-19-5-24-2-24,14C2670,2419,2642,2428,2627,2413z
		 M2665,2360c3-5,1-10-4-10c-6,0-11,5-11,10c0,6,2,10,4,10C2657,2370,2662,2366,2665,2360z" />
				<path d="M397,2404c-4-4-7-15-7-24c0-13-6-16-24-13c-51,10-44-33,10-62c36-19,36-19,51,0c19,26,14,99-7,103
		C411,2409,401,2408,397,2404z M410,2340c0-5-4-10-10-10c-5,0-10,5-10,10c0,6,5,10,10,10C406,2350,410,2346,410,2340z" />
				<path d="M1626,2371c-3-5,1-14,8-20c16-13,32,2,21,19C1647,2382,1633,2383,1626,2371z" />
				<path d="M1820,2360c-15-28-4-39,17-17c13,12,14,20,6,28S1829,2376,1820,2360z" />
				<path
					d="M1940,2363c0-10,34-13,135-14c74-1,141-2,148-3c6-1,12,5,12,13c0,10-30,14-148,15C1975,2375,1940,2372,1940,2363z" />
				<path d="M1430,2286c0-23,12-26,88-19c51,4,72,10,72,19c0,11-19,14-80,14C1447,2300,1430,2297,1430,2286z" />
				<path d="M617,2273c-4-3-7-14-7-23c0-11-8-16-22-16c-37,2-33-29,7-54c21-13,40-19,45-14c16,17,28,80,18,97
		C649,2280,629,2285,617,2273z M630,2210c0-5-2-10-4-10c-3,0-8,5-11,10c-3,6-1,10,4,10C625,2220,630,2216,630,2210z" />
				<path d="M2547,2273c-13-12-7-68,9-91c15-22,17-22,42-6c55,34,63,64,18,64c-15,0-26,7-29,20C2582,2279,2560,2287,2547,2273z
		 M2584,2214c3-8,2-12-4-9s-10,10-10,16C2570,2235,2577,2232,2584,2214z" />
				<path d="M1553,2164c-7-18,11-39,26-30c17,11,13,46-4,46C1566,2180,1557,2173,1553,2164z" />
				<path d="M570,2053c0-4-7-16-14-25c-11-15-17-16-44-6c-36,14-62-1-62-35c0-24,106-130,124-124c22,7,50,82,50,137c1,53,0,55-26,58
		C582,2060,570,2058,570,2053z M605,1993c-7-37-31-93-40-93c-3,0-23,16-45,36c-51,46-54,77-6,58c9-3,16-14,16-23c0-18,18-41,32-41
		c4,0,8,15,8,34c0,45,9,66,27,66C609,2030,611,2022,605,1993z" />
				<path d="M2562,1793c-11-27-12-273-2-273c13,0,44,153,44,220c1,61-2,75-15,78C2579,1820,2569,1810,2562,1793z M2590,1743
		c0-16-5-35-10-43c-7-11-10-4-10,28c0,23,5,42,10,42C2586,1770,2590,1758,2590,1743z" />
				<path d="M2846,1719c-14-11-26-26-26-33s-11-19-25-26c-14-8-29-27-33-44c-4-16-14-32-21-34c-14-5-13,6,4,68l8,25l-24-22
		c-16-16-23-34-23-62c-1-27-8-47-24-64c-13-14-19-27-14-31c5-3,21,7,36,23c20,20,34,27,52,23c18-3,32,4,58,32c19,19,36,39,38,43
		c1,5,17,16,33,25c37,20,54,60,35,82C2903,1746,2878,1744,2846,1719z M2879,1693c-12-15-12-16,5-10c16,6,17,5,6-8c-14-17-36-20-45-6
		c-6,11,21,41,37,41C2888,1710,2887,1703,2879,1693z M2820,1626c0-16-18-31-27-22c-8,8,5,36,17,36C2815,1640,2820,1634,2820,1626z
		 M2780,1570c0-5-4-10-10-10c-5,0-10,5-10,10c0,6,5,10,10,10C2776,1580,2780,1576,2780,1570z" />
				<path d="M484,1688c4-24,9-58,11-75c4-38,75-127,90-112c5,6,5,30-3,67c-7,32-12,68-12,79c0,29-47,83-72,83
		C479,1730,478,1726,484,1688z M550,1642c0-13-3-12-20,3c-11,10-20,28-20,39c1,20,1,20,20-3C541,1668,550,1651,550,1642z M550,1589
		c7-11,9-23,6-26c-8-9-36,17-36,33C520,1617,538,1613,550,1589z" />
				<path d="M133,1710c-42-17-24-79,27-95c12-4,32-17,45-31c13-13,29-24,35-24c5,0,10-7,10-15c0-20,35-37,62-30c18,5,32-3,62-34
		c22-22,43-38,49-34c5,3-10,25-33,48c-32,32-40,48-36,63c8,23-18,82-35,82c-7,0-8-7-4-17c14-34,17-63,6-63c-6,0-17,16-26,36
		c-10,23-25,40-45,47c-16,6-30,15-30,20c0,11-47,57-57,56C159,1719,145,1715,133,1710z M187,1674c3-8,1-20-4-25c-14-14-43,0-43,22
		C140,1695,178,1697,187,1674z M260,1604c0-9-7-14-17-12c-25,5-28,28-4,28C251,1620,260,1614,260,1604z M295,1550c3-5,2-10-4-10
		c-5,0-13,5-16,10c-3,6-2,10,4,10C284,1560,292,1556,295,1550z" />
				<path d="M2490,1405c0-10,7-15,18-13c21,4,19,22-2,26C2496,1420,2490,1415,2490,1405z" />
				<path d="M440,892c0-10,21-32,32-32c16,0,8,19-12,30C449,896,440,897,440,892z" />
				<path
					d="M1861,566c-19-7-37-18-39-24c-3-9,2-10,20-3c12,5,36,12,51,16c15,3,25,10,22,15C1908,582,1904,582,1861,566z" />
				<path d="M2838,568c-30-25-29-47,2-63c55-28,130-9,130,34C2970,574,2872,595,2838,568z M2935,540c0-18-62-25-85-10c-13,9-13,11,0,20
		C2873,565,2935,558,2935,540z" />
				<path d="M2875,540c3-5,13-10,21-10s14,5,14,10c0,6-9,10-21,10C2878,550,2872,546,2875,540z" />
				<path d="M1743,483c-19-7,18-73,61-111c36-31,93-49,121-38c9,3,15,18,15,36v30l39-20c22-11,42-16,45-11c3,4-13,17-36,27
		s-58,31-79,46C1860,478,1778,498,1743,483z M1877,443c21-14,38-57,28-73c-12-20-83,16-110,57c-25,38-11,39,35,3c23-18,44-29,48-25
		c4,3,0,10-9,15s-27,19-40,30l-24,21l25-7C1844,460,1865,451,1877,443z" />
				<path d="M2554,472c-29-4-58-37-50-57c7-20,66-45,103-45c36,0,73,25,73,49C2680,456,2619,481,2554,472z M2641,439c30-16,13-29-39-29
		s-78,12-68,29C2543,453,2615,453,2641,439z" />
				<path d="M2580,430c36-11,52-11,45,0c-3,6-21,10-38,9C2556,438,2556,438,2580,430z" />
				<path d="M1283,438c-37-18-30-56,12-74c56-23,145,5,145,47c0,10-9,23-19,29C1396,453,1312,452,1283,438z M1405,410c0-20-64-34-99-20
		c-24,9-26,13-15,26C1308,436,1405,432,1405,410z" />
				<path d="M1315,410c-4-6,8-10,29-10c20,0,36,5,36,10c0,6-13,10-29,10C1334,420,1318,416,1315,410z" />
				<path d="M1578,439c-49-28,3-83,78-81c71,1,100,43,52,76C1683,452,1604,455,1578,439z M1699,416c17-21-21-40-64-32
		c-34,7-60,28-48,39C1600,436,1687,430,1699,416z" />
				<path d="M1615,410c3-5,20-10,36-10c18,0,28,4,24,10c-3,6-20,10-36,10C1621,420,1611,416,1615,410z" />
				<path d="M1103,385c-39-17-53-53-27-72c55-40,174,24,134,72C1195,404,1145,404,1103,385z M1186,368c10-17-25-38-64-38s-39,18-1,37
		C1151,383,1177,383,1186,368z" />
				<path d="M1120,350c0-12,8-12,35,0c18,8,17,9-7,9C1132,360,1120,356,1120,350z" />
				<path d="M1443,359c-77-23-56-80,32-87c57-5,105,14,105,42C1580,351,1502,377,1443,359z M1545,329c15-15-13-29-61-29
		c-43,0-81,25-53,34c8,2,21,7,29,10C1480,351,1532,342,1545,329z" />
				<path d="M1468,323c12-2,30-2,40,0c9,3-1,5-23,4C1463,327,1455,325,1468,323z" />
				<path d="M798.1,346.6c-22.6-36.8,23.6-71,64.8-47.9c26.5,14.5,28.5,23.1,13.7,47.9C862.9,368.9,811.9,368.9,798.1,346.6z
		 M857,340.6c10.8-10.3,9.8-15.4-2.9-27.4c-15.7-12.8-17.7-12.8-33.4,0c-12.8,12-13.7,17.1-2.9,27.4c6.9,7.7,15.7,13.7,19.6,13.7
		C841.3,354.3,850.2,348.3,857,340.6z" />
				<path d="M2039,321c-21-17-22-22-11-45c9-21,18-27,38-24c15,2,33,13,41,25c13,19,12,24-6,42C2075,345,2068,345,2039,321z M2087,309
		c15-15,5-39-17-39c-19,0-34,25-24,41C2053,323,2074,322,2087,309z" />
				<path d="M1230,299c-74-30-44-89,45-89c66,0,101,24,92,62C1357,309,1288,322,1230,299z M1332,279c21-11,21-12,3-25
		c-23-17-101-18-111-2C1206,281,1289,302,1332,279z" />
				<path d="M1263,263c9-2,25-2,35,0c9,3,1,5-18,5S1253,266,1263,263z" />
				<path d="M1626,289c-33-26-33-42,2-69c50-40,182-13,182,37c0,7-9,22-20,33C1761,319,1663,318,1626,289z M1766,275c19-15,19-15,0-30
		c-11-8-36-15-56-15s-45,7-56,15c-19,15-19,15,0,30c11,8,36,15,56,15S1755,283,1766,275z" />
				<path d="M1675,260c3-5,19-10,36-10c16,0,29,5,29,10c0,6-16,10-36,10C1683,270,1671,266,1675,260z" />
			</g>
		</svg>
	)
}