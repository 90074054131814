import React from 'react'

export default function HeroLogo() {

	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 111.1 80.8">
			<path d="M77.9,15.8L77.9,15.8L77.9,15.8L77.9,15.8z" />
			<path d="M59.1,36.1L59.1,36.1L59.1,36.1L59.1,36.1z" />
			<path d="M72.9,17.7L72.9,17.7L72.9,17.7z" />
			<path d="M33.6,60c-0.1-0.1-0.2-0.2-0.3-0.2s-0.3-0.1-0.4-0.1c-0.2,0-0.3,0-0.5,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0,0.2,0.1,0.3
	s0.2,0.2,0.3,0.2l0.4,0.1c0.1,0,0.1,0.1,0.2,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.1-0.1,0.2c-0.1,0-0.2,0.1-0.3,0.1s-0.2,0-0.3-0.1
	c-0.1,0-0.2-0.1-0.3-0.2l-0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.3s0.3,0.1,0.5,0.1s0.4,0,0.5-0.2c0.1-0.1,0.2-0.2,0.2-0.4
	c0-0.1,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.2l-0.4-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0.1-0.2
	c0.1,0,0.2-0.1,0.3-0.1s0.2,0,0.2,0c0.1,0,0.2,0.1,0.2,0.1L33.6,60L33.6,60z M30.7,59.8v1.9h1.2v-0.3H31v-0.5h0.7v-0.3H31v-0.5h0.9
	v-0.3L30.7,59.8L30.7,59.8z" />
			<polygon points="34.4,61.7 34.7,61.7 34.7,60.1 35.2,60.1 35.2,59.8 33.9,59.8 33.9,60.1 34.4,60.1 " />
			<rect x="35.4" y="61.4" width="0.3" height="0.3" />
			<path d="M37,61.5v0.2h1.2v-0.3h-0.8l0.6-0.6c0.1-0.1,0.2-0.3,0.2-0.4S38.1,60.1,38,60s-0.3-0.2-0.4-0.2s-0.3,0.1-0.4,0.2
	c-0.1,0.1-0.2,0.3-0.2,0.4h0.3c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2
	c0,0.1,0,0.2-0.1,0.2L37,61.5L37,61.5z" />
			<path d="M40.9,61.5v0.2h1.2v-0.3h-0.8l0.6-0.6c0.1-0.1,0.2-0.3,0.2-0.4S42,60.1,41.9,60s-0.3-0.2-0.4-0.2s-0.3,0.1-0.4,0.2
	C41,60.1,41,60.2,41,60.4h0.3c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2
	c0,0.1,0,0.2-0.1,0.2L40.9,61.5L40.9,61.5z M39.2,61.3l1-1c0.1,0.1,0.1,0.2,0.1,0.4s-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2
	C39.5,61.5,39.3,61.4,39.2,61.3L39.2,61.3z M39.6,60c0.1,0,0.3,0,0.4,0.1l-1,1c-0.1-0.1-0.1-0.2-0.1-0.4s0.1-0.4,0.2-0.5
	C39.2,60.1,39.4,60.1,39.6,60L39.6,60z M39.6,59.8c-0.3,0-0.5,0.1-0.7,0.3s-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.7s0.4,0.3,0.7,0.3
	s0.5-0.1,0.7-0.3s0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7C40.1,59.9,39.9,59.8,39.6,59.8L39.6,59.8z" />
			<path d="M43.1,60.8c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2s0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1
	s-0.1-0.1-0.1-0.2h-0.3c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2s0.2-0.3,0.2-0.4
	c0-0.2-0.1-0.3-0.2-0.5c0.1-0.1,0.2-0.2,0.2-0.4s0-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.3,0-0.4,0.2
	c-0.1,0.1-0.2,0.2-0.2,0.4h0.3c0-0.1,0-0.1,0.1-0.2c0,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0.1,0.1,0.2s0,0.1-0.1,0.2
	c0,0-0.1,0.1-0.2,0.1V60.8L43.1,60.8z" />
			<rect x="27.1" y="60.8" width="1.9" height="0.2" />
			<rect x="45.3" y="60.8" width="1.9" height="0.2" />
			<path d="M29.4,50.7c0.2,0,0.4,0.1,0.6,0.3s0.3,0.4,0.3,0.6S30.2,52,30,52.2c0,0,0,0,0.1,0.1c0.2,0.2,0.3,0.4,0.3,0.7
	s-0.1,0.5-0.3,0.7S29.7,54,29.4,54H28v-3.2L29.4,50.7L29.4,50.7z M28.4,52h1c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3
	s0-0.2-0.1-0.3s-0.2-0.1-0.3-0.1h-1V52z M28.4,52.5v1h1c0.1,0,0.3,0,0.3-0.1s0.1-0.2,0.1-0.3s0-0.3-0.1-0.3
	c-0.1-0.1-0.2-0.1-0.3-0.1L28.4,52.5L28.4,52.5z" />
			<polygon points="34.9,50.7 33.6,52.8 33.6,53.9 33.2,53.9 33.2,52.8 32,50.7 32.5,50.7 33.4,52.2 34.3,50.7 " />
			<polygon
				points="41.3,52.9 41.3,50.7 41.8,50.7 41.8,53.9 41.5,53.9 39.7,51.7 39.7,53.9 39.2,53.9 39.2,50.7 39.6,50.7 " />
			<path d="M43.7,52.7v-2h0.5v2c0,0.2,0.1,0.4,0.2,0.6c0.2,0.2,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.6v-2h0.5v2
	c0,0.4-0.1,0.7-0.4,0.9S45.4,54,45,54s-0.7-0.1-0.9-0.4S43.7,53.1,43.7,52.7" />
			<path d="M50.2,51.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0-0.3,0-0.5,0.1c-0.1,0.1-0.2,0.2-0.2,0.3s0,0.2,0.1,0.3
	s0.2,0.1,0.3,0.2l0.8,0.2c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0.2,0.2,0.3,0.2,0.6s-0.1,0.5-0.3,0.7S49.8,54,49.4,54
	c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.6-0.5l0.4-0.3c0.1,0.2,0.3,0.3,0.5,0.4s0.3,0.1,0.5,0.1s0.4,0,0.6-0.1
	c0.1-0.1,0.2-0.2,0.2-0.3s0-0.2-0.1-0.3s-0.2-0.1-0.3-0.2L49,52.4c-0.3-0.1-0.5-0.2-0.6-0.4s-0.2-0.3-0.2-0.6s0.1-0.5,0.3-0.7
	s0.5-0.3,0.8-0.3c0.2,0,0.4,0,0.6,0.1s0.4,0.2,0.6,0.4L50.2,51.4L50.2,51.4z" />
			<polygon points="53,52.6 53,53.9 52.5,53.9 52.5,50.7 53,50.7 53,52.1 54.6,52.1 54.6,50.7 55.1,50.7 55.1,53.9 54.6,53.9 
	54.6,52.6 " />
			<path d="M9,48.2c-0.7-0.4-1.2-1-1.6-1.8c-0.4-0.9-0.7-1.9-0.7-3c-0.1-1.2,0.1-2.5,0.5-3.9s1-3,1.9-4.5l0.3-0.5l0,0L9.7,34
	c0.9-1.6,1.9-2.9,3-3.9s2.1-1.8,3.2-2.4l0,0c0.5-0.3,1.1-0.4,1.7-0.5c2.5-0.4,3.4,0,3.1,0.8c-0.3,0.7-6,10.9-6,10.9
	c2.6-2.2,6-9.7,6.5-10.5c0.3-0.5,1,0,1.7,0.7c0,0,0,0.1,0.1,0.1c0.4,0.8,0.7,1.8,0.7,3c0,1.2-0.1,2.5-0.4,3.9s-1,2.9-1.9,4.5
	L21.2,41c0,0.1-0.1,0.1-0.1,0.2L21,41.3l0,0l0,0l0,0c0,0,0,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2l-0.2,0.4c-0.9,1.5-1.9,2.8-3,3.8
	s-2.1,1.8-3.2,2.3c-1,0.5-2,0.8-3,0.8c-0.1,0-0.2,0-0.3,0C10.3,48.7,9.6,48.6,9,48.2 M3.4,35.1C1.8,42.4,5.7,49.5,12.2,51
	S25.3,47.8,27,40.5S24.7,26,18.2,24.6c-0.8-0.2-1.6-0.3-2.4-0.3C10.1,24.3,4.9,28.7,3.4,35.1" />
			<path d="M51.1,23.4c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.3,0.3-0.2,0.4s0.1,0.4-0.1,1s-0.4,1.3-0.8,2.2c-1.2-0.1-2.4-0.2-3.6-0.2
	c-1.2,0-2.2,0-3.1,0.2c-0.9,0.2-1.7,0.4-2.2,0.9s-0.9,1.1-1,1.9c0,0.5,0,0.9,0.1,1.3c0.1,0.3,0.2,0.5,0.4,0.6s0.3,0.1,0.5,0
	c0.1-0.1,0.2-0.3,0.2-0.6c0-0.5,0.2-0.8,0.7-1.1c0.4-0.3,1-0.5,1.7-0.6s1.5-0.2,2.5-0.3c0.9,0,1.9,0,3,0c-0.5,1.2-1.1,2.6-1.7,4
	c-0.6,1.4-1.2,2.8-1.7,4.2c-0.2,0.5-0.4,1-0.6,1.5c-0.1,0.1-0.1,0.3-0.2,0.4c-0.3,0.5-0.6,1.1-1,1.7c-0.3,0.6-0.7,1.2-1.1,1.7
	c-0.4,0.6-0.8,1.1-1.2,1.6s-0.7,0.9-1.1,1.2c-0.3,0.3-0.6,0.5-0.8,0.5c-0.5,0-0.7-0.1-0.6-0.6c0-0.4,0.2-1,0.6-1.7
	c0.3-0.7,0.7-1.5,1.2-2.4s1-1.8,1.4-2.7s0.8-1.8,1-2.6s0.3-1.5,0.1-2.1s-0.5-1-0.9-1.2s-0.9-0.3-1.3-0.3c-0.4,0-0.7,0.1-1,0.3
	s-0.3,0.3-0.2,0.4c0.1,0.1,0.2,0.3,0.1,0.7c-0.1,0.3-0.2,0.7-0.4,1.2s-0.4,1-0.7,1.6s-0.5,1.2-0.8,1.9c-0.3,0.5-0.5,1-0.8,1.6
	s-0.6,1.2-0.9,1.8c-0.3,0.6-0.6,1.2-1,1.7c-0.3,0.5-0.7,1-1,1.4S33,45.9,32.7,46c-0.3,0.2-0.6,0.2-0.9,0.1c-0.4-0.1-0.6-0.4-0.8-0.9
	c-0.1-0.5-0.2-1-0.2-1.6s0.1-1.3,0.3-1.9c0.2-0.7,0.4-1.3,0.7-1.9c0.4-0.9,0.8-1.8,1.3-2.5s0.8-1.4,1.2-1.9s0.8-0.9,1.1-1.2
	s0.7-0.4,0.9-0.4s0.4,0.1,0.5,0.4c-0.1,0.1-0.3,0.2-0.3,0.4c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.3,0.1,0.5,0.3,0.6
	c0.2,0.2,0.4,0.2,0.7,0.2s0.5-0.1,0.6-0.3c0.2-0.2,0.3-0.5,0.3-0.9s-0.1-0.7-0.2-1c-0.2-0.3-0.4-0.5-0.6-0.7
	c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3-0.1-0.5-0.1-0.8-0.1c-0.7,0-1.4,0.1-2.1,0.3c-0.7,0.2-1.4,0.6-2,1.2c-0.7,0.6-1.3,1.3-1.9,2.1
	c-0.6,0.9-1.1,1.9-1.6,3.2c-0.3,0.7-0.5,1.4-0.7,2.2s-0.2,1.6-0.2,2.3c0.1,0.7,0.3,1.4,0.6,2c0.3,0.6,0.9,1.1,1.7,1.4
	c0.7,0.3,1.3,0.4,1.8,0.3c0.6-0.1,1.1-0.2,1.6-0.5s1-0.7,1.4-1.2s0.8-1.1,1.2-1.7c-0.1,1,0,1.9,0.4,2.5c0.4,0.7,1,1,1.7,0.9
	c0.5,0,1-0.2,1.5-0.5c0.5-0.4,1-0.8,1.6-1.4c0.5-0.6,1-1.2,1.5-1.9c0.1-0.2,0.2-0.3,0.3-0.5c0,0.2-0.1,0.4-0.1,0.5
	c-0.1,0.5-0.1,1,0,1.5s0.2,0.9,0.4,1.2s0.4,0.6,0.7,0.8s0.6,0.3,1,0.2c0.5,0,1-0.2,1.5-0.5c0.5-0.4,1-0.8,1.6-1.4
	c0.5-0.6,1-1.2,1.5-1.9s0.9-1.4,1.3-2c0.4-0.7,0.8-1.3,1.1-1.9c0.3-0.5,0.5-0.9,0.6-1.3l0.1-0.1c0.2-0.4,0.4-0.8,0.7-1.3
	s0.5-0.9,0.7-1.3c0.6,1.3,1,2.5,1.3,3.5c0.2,1,0.3,2,0.3,2.7c0,0.8-0.2,1.5-0.4,2c-0.2,0.6-0.5,1-0.8,1.4c-0.3,0.3-0.6,0.6-0.9,0.8
	s-0.5,0.2-0.7,0.2c-0.4,0-0.8-0.2-1-0.4c-0.3-0.3-0.4-0.6-0.5-1s0-0.8,0.1-1.2s0.3-0.8,0.5-1.1c0.1,0.2,0.2,0.3,0.3,0.4
	c0.2,0.1,0.3,0.2,0.5,0.2s0.5-0.1,0.7-0.3s0.3-0.4,0.3-0.7c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.4-0.3-0.6-0.3c-0.2,0-0.5,0-0.8,0.2
	c-0.3,0.1-0.7,0.4-1.1,0.8c-0.3,0.3-0.5,0.7-0.6,1.3s-0.1,1.1,0.1,1.7c0.1,0.6,0.4,1,0.9,1.5s1,0.6,1.9,0.6c0.3,0,0.6-0.1,1-0.2
	c0.4-0.1,0.8-0.3,1.3-0.5l0,0l0,0c0.7-0.3,1.4-0.7,2-1.2s1.2-1,1.7-1.6c0.8-0.9,1.5-1.7,2.1-2.6c0.6-0.9,1.2-1.8,1.7-2.8
	c0.1-0.1,0.1-0.3,0-0.4c0-0.1-0.1-0.3-0.3-0.3c-0.2-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0.1-0.3,0.3c-0.3,0.7-0.8,1.4-1.3,2.1
	c-0.5,0.7-1.1,1.5-1.8,2.3c0.1-0.6,0.2-1.2,0.2-1.8s-0.2-1.3-0.4-2.1c-0.2-0.7-0.6-1.5-1.1-2.4c-0.5-0.8-1.1-1.7-1.9-2.6l0.1-0.2
	c0.3-0.4,0.5-0.8,0.6-1.2c0.2-0.4,0.2-0.8,0.2-1.1c0-0.3-0.1-0.6-0.4-0.8c-0.2-0.2-0.6-0.3-1.1-0.3s-0.9,0.1-1.2,0.3
	c-0.3,0.2-0.5,0.4-0.7,0.7c-0.2,0.3-0.3,0.5-0.4,0.8c-0.1,0.3-0.1,0.5-0.1,0.7c0,0.4,0.1,0.8,0.2,1.2c0.1,0.4,0.3,0.7,0.5,1.1
	c-0.1,0.3-0.3,0.5-0.4,0.8c-0.1,0.3-0.3,0.5-0.4,0.7c-0.1,0.2-0.3,0.5-0.4,0.8c-0.1,0.3-0.3,0.5-0.4,0.8c0,0-0.1,0.1-0.1,0.2
	c-0.1,0.3-0.3,0.8-0.6,1.3s-0.6,1.1-1,1.7c-0.4,0.6-0.7,1.2-1.1,1.7c-0.4,0.6-0.8,1.1-1.2,1.6s-0.7,0.9-1,1.2s-0.6,0.5-0.8,0.5
	c-0.4,0-0.5-0.2-0.4-0.7s0.3-1.2,0.6-2.1c0.3-0.9,0.8-1.9,1.3-3c0.6-1.2,1.1-2.4,1.7-3.6c0.6-1.3,1.2-2.5,1.8-3.8s1.1-2.4,1.5-3.5
	c1,0,2-0.1,3-0.2s1.8-0.3,2.6-0.4s1.5-0.4,2-0.6c0.6-0.2,1-0.5,1.3-0.7c0.2-0.2,0.3-0.5,0.3-0.7c-0.1-0.3-0.2-0.5-0.4-0.6
	C60.8,26,60.6,26,60.5,26c-0.2,0-0.3,0.2-0.3,0.5S60,27,59.5,27.2c-0.4,0.2-1,0.3-1.7,0.4s-1.5,0.2-2.4,0.2s-1.8,0-2.7,0
	c0.2-0.6,0.3-1.1,0.4-1.6c0.1-0.5,0.1-0.9,0-1.1c-0.2-0.6-0.5-1-0.9-1.2C52,23.6,51.6,23.5,51.1,23.4C51.2,23.5,51.2,23.5,51.1,23.4
	" />
			<path
				d="M25.3,19.9c0.1,0.3,2.3,3.3,1.7,3.5c0,0-1.9-3.1-2-3.3c-0.2-0.2-0.3-0.2-0.6,0.5c-0.1,0.2-0.1,0.4-0.1,0.5l0,0
	c0,0.4,0,0.8,0.1,1.3s0.3,1,0.6,1.5l0.1,0.2l0,0l0.1,0.2c0.3,0.5,0.6,1,0.9,1.3s0.7,0.6,1,0.8s0.7,0.3,1,0.3s0.6,0,0.8-0.1
	c0.2-0.1,0.4-0.3,0.5-0.6c0.1-0.3,0.2-0.6,0.3-1c0-0.4,0-0.8-0.1-1.3s-0.3-1-0.5-1.5L29,22.1c0,0,0,0,0-0.1l0,0l0,0l0,0l0,0l0,0
	c0,0,0,0,0-0.1l-0.1-0.1c-0.3-0.5-0.6-0.9-0.9-1.3c-0.3-0.3-0.7-0.6-1-0.8s-0.6-0.3-0.9-0.3l0,0C25.4,19.6,25.2,19.7,25.3,19.9" />
			<path d="M32,24.5c-0.2,0-0.4,0.1-0.5,0.2l0,0c-0.3,0.2-0.6,0.5-0.9,0.9C30.3,26,30,26.5,29.8,27l-0.1,0.2l0,0l-0.1,0.2
	c-0.2,0.5-0.4,1.1-0.4,1.5c-0.1,0.5,0,0.9,0,1.3c0.1,0.4,0.2,0.7,0.4,0.9c0.2,0.3,0.4,0.4,0.6,0.5s0.5,0.1,0.8,0.1
	c0.3-0.1,0.6-0.2,0.9-0.4c0.3-0.2,0.6-0.5,0.9-0.9s0.6-0.8,0.8-1.4l0.1-0.1c0,0,0,0,0-0.1l0,0l0,0l0,0l0,0l0,0c0,0,0,0,0-0.1
	l0.1-0.1c0.2-0.5,0.3-1,0.4-1.5c0-0.5,0-0.9,0-1.3c-0.1-0.4-0.2-0.7-0.4-0.9l0,0c-0.2-0.2-0.5-0.3-0.6-0.1c-0.1,0.3-1.1,3.9-1.6,3.6
	c0,0,1.2-3.5,1.2-3.7s0-0.3-0.2-0.3C32.5,24.4,32.3,24.4,32,24.5" />
			<g>
				<polygon points="10.7,57.1 11.8,57.1 10.6,58 10.8,58.2 12.5,57 12.4,56.8 11,56.8 11.4,55.4 11.3,55.2 9.5,56.4 9.7,56.7 
		10.9,55.8 10.6,56.9 	" />
				<path
					d="M12.6,60.5l0.2,0.3l1-2.1l-0.2-0.2l-2.3,0.6l0.2,0.3l0.3-0.1l0.8,0.9V60.5L12.6,60.5z M12.3,59.2l1-0.3l-0.5,1L12.3,59.2z" />
				<path d="M15.6,61.4c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2L14,61.5l1.1-1l0.4,0.4
		C15.6,61,15.6,61.2,15.6,61.4L15.6,61.4z M16,61.4c0-0.3-0.1-0.5-0.3-0.8L15,60l-1.5,1.5l0.6,0.6c0.2,0.2,0.5,0.3,0.8,0.3
		s0.5-0.1,0.8-0.3C15.9,62,16,61.7,16,61.4L16,61.4z" />
				<polygon points="17.1,62 15.7,63.7 16.8,64.5 17,64.3 16.2,63.6 16.6,63.2 17.1,63.7 17.4,63.4 16.8,62.9 17.1,62.5 17.9,63.2 
		18.1,62.9 	" />
				<polygon points="21,65.3 19.9,66.1 20.3,64.5 20,64.3 19.5,66.6 19.7,66.7 20.8,65.9 20.5,67.2 20.7,67.3 22.5,65.9 22.2,65.7 
		20.9,66.7 21.2,65.4 	" />
				<rect x="21.8" y="67.2" transform="matrix(0.4661 -0.8847 0.8847 0.4661 -47.4247 56.1864)" width="2.1"
					height="0.3" />
				<polygon points="23.9,69 24.2,69.2 25,67.5 25.5,67.8 25.6,67.5 24.3,66.8 24.2,67.1 24.7,67.4 	" />
				<polygon points="27.3,69.4 27,70.3 27.3,70.4 28,68.4 27.7,68.3 27.4,69.1 26.4,68.7 26.7,67.9 26.4,67.8 25.7,69.8 26,69.9 
		26.3,69 	" />
				<path d="M31.4,71.5l0.3,0.1l-0.2-0.7c0.1,0,0.1,0,0.2-0.1c0.2-0.1,0.3-0.3,0.3-0.5s0-0.4-0.1-0.6s-0.3-0.3-0.5-0.3l-0.8-0.2
		l-0.4,2.1l0.3,0.1l0.1-0.5l0.5,0.1h0.1L31.4,71.5L31.4,71.5z M30.9,69.5l0.5,0.1c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.4
		c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.1l-0.5-0.1L30.9,69.5L30.9,69.5z" />
				<polygon points="33.2,69.6 33,71.8 34.3,71.9 34.4,71.6 33.3,71.5 33.4,70.9 34.2,71 34.2,70.7 33.4,70.6 33.5,70 34.5,70.1 
		34.6,69.8 	" />
				<path
					d="M37.1,72.1h0.4l-1-2.1h-0.2l-1.1,2h0.4l0.2-0.3l1.2,0.1L37.1,72.1L37.1,72.1z M35.9,71.4l0.5-1l0.4,1H35.9z" />
				<polygon points="38.4,72.1 39.7,72.1 39.7,71.8 38.7,71.8 38.7,70 38.4,70 	" />
				<path d="M43.7,70c0.2,0.1,0.3,0.3,0.3,0.5s0,0.4-0.1,0.6s-0.3,0.3-0.5,0.3s-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.3-0.3-0.5s0-0.4,0.1-0.6
		s0.3-0.3,0.5-0.3C43.3,69.9,43.5,69.9,43.7,70z M43.9,69.8c-0.3-0.2-0.5-0.3-0.8-0.2c-0.3,0-0.6,0.2-0.7,0.4
		c-0.2,0.3-0.3,0.5-0.2,0.8s0.2,0.6,0.4,0.7c0.3,0.2,0.5,0.3,0.8,0.2c0.3,0,0.6-0.2,0.7-0.4c0.2-0.3,0.3-0.5,0.2-0.8
		C44.3,70.2,44.1,70,43.9,69.8L43.9,69.8z" />
				<path d="M47.2,70.9l0.3-0.1L46,69l-0.2,0.1l-0.4,2.3l0.3-0.1l0.1-0.3l1.2-0.3C47,70.6,47.2,70.9,47.2,70.9z M45.9,70.6l0.2-1.1
		l0.7,0.8L45.9,70.6z" />
				<polygon points="48.8,70.4 49.1,70.3 48.5,68.6 49.1,68.4 49,68.1 47.6,68.6 47.7,68.9 48.2,68.7 	" />
				<path d="M51.3,67.4c-0.1,0-0.3-0.1-0.5-0.1s-0.3,0-0.4,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.3,0,0.5
		c0.1,0.1,0.1,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1l0.5-0.1c0.1,0,0.2,0,0.2,0l0.1,0.1c0,0.1,0,0.2,0,0.3c-0.1,0.1-0.2,0.2-0.3,0.2
		s-0.2,0.1-0.3,0.1s-0.3,0-0.4-0.1l-0.2,0.3c0.1,0.1,0.3,0.1,0.5,0.2s0.3,0,0.5-0.1s0.4-0.2,0.5-0.4c0.1-0.2,0.1-0.3,0-0.5
		c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.4-0.1l-0.5,0.1c-0.1,0-0.2,0-0.3,0s-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2
		s0.1-0.1,0.2-0.2c0.1,0,0.2-0.1,0.3-0.1s0.2,0,0.3,0L51.3,67.4L51.3,67.4z" />
			</g>
			<path className='oat oat2' d="M95,7c-0.9-0.3-1.9,0.8-2.3,1.6c-0.5,0.3-1,0.7-1.5,1.1c0.3-0.4,0.5-0.9,0.6-1.3c0-0.1-0.1-0.2-0.2-0.1
	c-0.4,0.3-0.7,0.7-0.9,1.1c-0.2,0.4-0.4,0.8-0.5,1.2c-0.4,0.3-0.8,0.7-1.2,1.1c0.2-0.6,0.3-1.2,0.3-1.6c0-0.1-0.1-0.2-0.2-0.1
	c-0.5,0.2-2.3,2.7-1.5,3.1c-0.1,0.1-0.2,0.2-0.2,0.3l0,0c0,0-0.1,0-0.1,0.1c-0.6,0.6-1.1,1.2-1.6,1.8c0.1-0.2,0.2-0.4,0.2-0.6
	c0.2-0.6,0.4-1.2,0.4-1.8c0-0.1-0.2-0.1-0.2-0.1c-0.8,0.8-1.3,2-1.3,3.1c0,0.1,0.1,0.1,0.1,0.1s0,0-0.1,0.1v0.1
	c-0.6,0.7-1.3,1.4-1.9,2.1c0.5-0.8,0.7-1.9,0.6-2.7c0-0.1-0.2-0.2-0.3-0.1c-1.1,0.9-1.6,2.4-1.7,3.8c0,0.2,0.3,0.2,0.3,0.1
	c0.1-0.1,0.1-0.2,0.2-0.4l0,0c0.4-0.8,0.7-1.6,1-2.4c0.1-0.1-0.1-0.2-0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1,1.1-0.6,2.3-1.2,3.2v0.1
	c-0.3,0.3-0.6,0.6-0.9,0.9c-0.6,0.6-1.1,1.2-1.7,1.8c0.5-1,0.8-2.1,0.6-3.1c0-0.1-0.2-0.1-0.2-0.1c-0.1,0.1-0.2,0.1-0.3,0.2l0,0
	c-0.8,0.8-1.3,2.4-1.1,3.5c0,0.1,0,0.3-0.1,0.4c0,0.1,0.1,0.2,0.2,0.1c-0.8,0.9-1.6,1.8-2.3,2.7c0.6-1,0.8-2.4,0.5-3.4
	c0-0.1-0.1-0.1-0.2-0.1c-1.1,0.6-1.6,2.4-1.2,3.7c0,0.2,0,0.5,0,0.7c0,0.1,0.2,0.2,0.2,0.1s0.1-0.2,0.1-0.3c0,0,0.1,0.1,0.1,0
	c-0.9,1-1.7,2.1-2.5,3.2c0.5-0.8,0.4-2.6,0.3-3.2c0-0.1-0.2-0.2-0.3,0c-0.5,0.9-0.8,1.9-0.9,2.9c-0.1,0.4-0.2,1-0.2,1.6
	c-0.9,1.2-1.7,2.4-2.5,3.7c-2.2,3.5-4.2,7.1-5.9,10.9h0.1c0.1,0,0.2,0.1,0.3,0.1c0.8-1.6,4.1-8.1,6.2-11.3c0.6-0.9,1.2-1.9,1.9-2.8
	c0,0.1,0.1,0,0.2,0c0-0.1,0-0.1,0-0.2c0.5-0.7,1.1-1.5,1.6-2.2l0,0c-0.1,0-0.1,0.2,0,0.2c1.4,0.5,3.2-0.6,4.2-1.5
	c0.1-0.1,0-0.2-0.1-0.3c-1.1-0.5-2.6,0.3-3.7,0.9c0.7-0.9,1.4-1.8,2.2-2.7c0,0,0,0.1,0.1,0.1h0.1l0,0c0.5,0.1,1.1,0,1.6-0.2
	c1-0.2,2-0.6,2.8-1.2c0.1-0.1,0.1-0.2,0-0.2c-1.4-0.9-3.3,0.4-4.4,1.2c1-1.2,1.9-2.3,3-3.4c0.3,0.1,0.6,0.1,0.9,0
	c0.4,0,0.9-0.2,1.3-0.4c0.6-0.2,1.1-0.5,1.6-0.9c0.1-0.1,0.1-0.3,0-0.3c-1.2-0.5-2.4,0.3-3.4,1c0.9-1,1.8-2,2.8-3l0,0
	c0.5,0.4,1.3,0.2,1.8,0c0.6-0.2,1.3-0.6,1.7-1.1c0.1-0.1,0-0.2,0-0.2c-1-0.3-2.3,0.3-3.1,0.9c-0.1,0.1-0.1,0.2,0,0.3c0,0,0,0,0.1,0
	c-0.1,0-0.2,0-0.3-0.1l0,0c0.9-0.9,1.8-1.9,2.7-2.8c1.1,0.1,2.4,0,3.3-0.7c0.1-0.1,0.1-0.2,0-0.3c-0.8-0.3-1.7,0-2.5,0.3h-0.1
	c0.5-0.5,1.1-1.1,1.7-1.6c0.3,0.1,0.7-0.1,1-0.2c0.6-0.2,1.2-0.4,1.7-0.8c0.1-0.1,0.1-0.2,0-0.3c-0.5-0.1-0.9-0.1-1.4,0
	c0.3-0.3,0.6-0.6,1-0.9c0.1,0,0.1-0.1,0.2-0.1c0.8,0.2,1.9-0.3,2.5-0.8c0.1-0.1,0.1-0.2,0-0.2c-0.4-0.3-0.9-0.2-1.4,0
	c0.5-0.5,1-0.9,1.5-1.4l0,0c0.4,0.2,0.8-0.3,1.1-0.5c0.4-0.3,0.8-0.7,1-1.1C95.1,7.1,95.1,7.1,95,7z M82.4,17
	c-0.2,0.4-0.3,0.7-0.5,1.1c0.1-0.6,0.4-1.1,0.7-1.6C82.6,16.6,82.5,16.8,82.4,17z M79.8,19.3c0.1,0.5,0,1-0.2,1.5
	C79.7,20.3,79.8,19.8,79.8,19.3z M76.4,22.6c0.2,0.6,0.1,1.3-0.1,1.9c0,0,0,0,0,0.1c0.1-0.5,0.1-1.1,0-1.6c0-0.1-0.1-0.1-0.1-0.2
	C76.3,22.7,76.3,22.6,76.4,22.6z M72.5,29.8L72.5,29.8c0-0.1,0-0.1,0-0.2c0.1-0.5,0.2-1,0.3-1.5C72.8,28.7,72.7,29.3,72.5,29.8z
	 M77.6,27.8c-0.2,0.1-0.3,0.3-0.5,0.4l0.1-0.1c0.1-0.1,0-0.2-0.1-0.3c-0.1,0-0.1,0-0.2,0C77.1,27.8,77.3,27.8,77.6,27.8z M76.6,28.2
	c-0.5,0.5-1.4,0.8-2.1,0.7C75.2,28.9,75.9,28.3,76.6,28.2z M75.6,25.8c0.1-0.7,0.2-1.4,0.4-2C76,24.5,75.9,25.2,75.6,25.8z
	 M80.5,24.3c0.1,0,0.2,0.1,0.3,0.1c-0.3,0.2-0.6,0.4-1,0.5C80.1,24.7,80.3,24.5,80.5,24.3z M77.5,25.5c0.8-0.4,1.5-0.9,2.3-1.1
	c-0.5,0.5-1.3,0.9-2,1c-0.1,0-0.1,0-0.2,0C77.6,25.5,77.5,25.5,77.5,25.5L77.5,25.5L77.5,25.5L77.5,25.5z M78.9,21.8
	c0.1-0.5,0.2-0.9,0.4-1.4C79.3,20.9,79.1,21.4,78.9,21.8z M82.4,20.8c-0.2,0.2-0.5,0.4-0.7,0.6h-0.1c-0.3,0.1-0.6,0.2-0.8,0.2
	C81.3,21.4,81.8,20.9,82.4,20.8z M85.4,17.7c-0.4,0.4-1,0.7-1.6,0.6C84.4,18.1,84.9,17.8,85.4,17.7z M87.9,14.9c0.2,0,0.5,0,0.7,0
	c-0.3,0.2-0.6,0.3-1,0.4C87.7,15.1,87.8,15,87.9,14.9z M88.3,12.2L88.3,12.2L88.3,12.2c0.2-0.4,0.4-0.8,0.5-1.2
	c0-0.1-0.1-0.2-0.2-0.1c-0.2,0.3-0.4,0.5-0.5,0.8c0-0.1,0.1-0.2,0.1-0.3s-0.1-0.1-0.1,0c-0.1,0.2-0.1,0.4-0.2,0.5V12
	c-0.1,0.2-0.2,0.3-0.3,0.5c0,0.1,0,0.1,0,0.2c0,0-0.1,0-0.1,0.1c-0.4-0.5,0.7-2,1.2-2.5l0,0l0,0l0,0C89,10.9,88.7,11.6,88.3,12.2
	L88.3,12.2z M89.6,12.5c0.2-0.1,0.4-0.1,0.6-0.1c-0.2,0.1-0.4,0.2-0.6,0.3V12.5C89.8,12.5,89.7,12.4,89.6,12.5z M91.4,8.8
	c-0.1,0.3-0.3,0.7-0.5,1C91,9.4,91.1,9.1,91.4,8.8z M92.7,10.3c-0.3,0.2-0.6,0.4-1,0.5c-0.3,0.1-0.7,0.2-1,0.2
	C91.3,10.6,91.9,10,92.7,10.3z M94.6,7.6C94.4,7.8,94.2,8,94,8.1s-0.6,0.6-0.9,0.6l0,0l0,0l0,0c0.1-0.1,0.2-0.2,0.2-0.2
	c0.1-0.1,0-0.2,0-0.2c0.4-0.5,0.7-1,1.3-1C95,7.2,94.7,7.4,94.6,7.6z" />
			<path className='oat oat1' d="M63.8,44.1C63.9,44,64,44,64.1,44s0.2,0,0.2,0.1l0,0c0.4-2.8,0.8-5.5,1.2-8.3c0.1,0,0.1-0.1,0.1-0.1v-0.1v-0.1
	c0.1-0.5,0.1-1.1,0.2-1.6c0.9,0.7,3-1.9,3.3-2.5c0.1-0.1-0.1-0.3-0.2-0.2c-0.2,0.1-0.4,0.2-0.6,0.3c0,0-0.1-0.1-0.2-0.1
	c-1,0.1-1.8,1.2-2.3,2c0.1-0.6,0.1-1.2,0.2-1.8c0.1,0,0.1-0.1,0.1-0.1s0-0.1-0.1-0.1c0.1-0.5,0.1-1,0.1-1.5l0,0l0,0
	c0,0.1,0,0.1,0.1,0.1l0.1-0.1c0.9,0,1.5-1.1,2-2c0.1,0,0.2-0.1,0.2-0.1c-0.1,0.9-1.2,2.2-2.1,2.3c-0.1,0-0.1,0.2,0,0.2
	c1.1,0,2.3-1.6,2.3-2.6c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.2,0c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1-0.1-0.2-0.2-0.2
	c-0.1,0.1-0.7,0.6-1.1,1.1c-0.4,0.4-0.8,0.9-1.1,1.4l0,0h-0.1c0.2-2.3,0.4-4.6,0.5-6.8l0,0c0,0.1,0.1,0.2,0.2,0.1l0,0
	c1.1,0,2.1-2.3,2.3-3.1c0-0.1-0.1-0.2-0.2-0.2c-1.1,0.5-2,1.9-2.3,3c0,0,0,0-0.1,0c0-0.3,0-0.5,0.1-0.8c0.1-1,0.2-2,0.3-3
	c0.1,0,0.1,0,0.1-0.1v-0.1c0.7-0.1,1.4-2,1.6-2.4c0.1-0.1-0.1-0.3-0.2-0.2c-0.5,0.4-0.9,0.9-1.2,1.5c-0.1,0.1-0.2,0.2-0.3,0.4
	c0.1-0.7,0.1-1.4,0.1-2.2c0-0.1,0-0.3,0-0.4h0.1c0.1-0.2,0.1-0.3,0.2-0.5c0.7-0.2,0.9-1.8,1-2.4c0-0.1-0.2-0.2-0.3-0.1
	c-0.4,0.5-0.8,1.3-1,2.1c-0.1-1.1-0.1-2.2-0.1-3.3c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.4,0.1,0.4-0.1c-0.1-0.4-0.2-0.8-0.3-1.2
	c0.2-0.4,0-1.7,0-1.8s-0.2-0.2-0.3,0c-0.1,0.1-0.1,0.2-0.1,0.4c-0.1,0-0.1,0.1-0.1,0.1l0,0l0,0l0,0c0,0.5,0,1,0.1,1.4v0.1l0,0
	c-0.1,1.3-0.1,2.6,0,4l0,0c-0.1-0.3-0.2-0.7-0.4-0.9c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.3-0.3-0.7-0.6-0.9c-0.1-0.1-0.2,0-0.2,0.1
	c0,1,0.6,2.1,1.2,2.9c0,0.1,0.1,0.1,0.2,0.1c0,0.9-0.1,1.8-0.2,2.8c-0.1-0.5-0.2-1.1-0.4-1.6c-0.1-0.1-0.2-0.1-0.3,0
	c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.7,0.1,1.4,0.3,2.1c0.1,0.2,0.1,0.4,0.2,0.6c0,0.1,0.1,0.1,0.2,0.1c0,0,0,0,0,0.1
	c0,0.5-0.1,0.9-0.1,1.4c-0.1-0.6-0.3-1.1-0.7-1.5c-0.1-0.1-0.2,0-0.3,0.1c-0.2,1.1,0.3,2.2,0.7,3.1c0,0.1,0.1,0.1,0.1,0.1
	c-0.1,0.7-0.1,1.4-0.2,2.2c-0.3-0.7-0.8-1.4-1.2-1.8c-0.1-0.1-0.2,0-0.3,0.1c-0.5,1.1,0.5,2.6,1.2,3.5l0.1,0.1l-0.2,2l0,0v-0.1
	c-0.1-0.4-0.3-0.8-0.6-1.2c-0.1-0.1-0.2,0-0.3,0.1c-0.1,0.6,0,1.3,0.2,1.9c0.1,0.4,0.3,0.9,0.6,1.2c-0.1,0.8-0.2,1.5-0.2,2.3
	c-0.3-0.8-1-1.8-1.2-2.1c-0.1-0.1-0.2-0.1-0.3,0c-0.3,0.9,0.3,1.9,0.8,2.8c0.1,0.3,0.3,0.6,0.4,0.9c0,0.3-0.1,0.5-0.1,0.8
	C64.6,38.7,64.2,41.4,63.8,44.1z M68.1,31.9c0.1-0.1,0.2-0.1,0.3-0.2c-0.1,0.2-0.3,0.4-0.5,0.6l0,0C68,32.2,68,32,68.1,31.9z
	 M67.6,31.9c-0.5,0.4-1,0.9-1.3,1.5c0,0.1,0,0.2,0.1,0.2c-0.1,0.1-0.3,0.1-0.4,0.1C66.5,33,66.9,32.2,67.6,31.9z M67.1,29L67.1,29
	c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.2,0.4-0.4c-0.3,0.5-0.7,1.1-1.1,1.4C66.9,29.3,67,29.2,67.1,29z
	 M68.8,20c-0.3,0.8-1,2.3-1.7,2.6C67.6,21.7,67.9,20.6,68.8,20z M67.7,17.6c0.1-0.2,0.3-0.5,0.4-0.7c-0.3,0.6-0.7,1.4-1,1.5
	C67.4,18.1,67.7,17.7,67.7,17.6z M67.6,14.1c0.1-0.4,0.3-0.8,0.5-1.1l0,0c-0.1,0.5-0.2,1.2-0.6,1.5C67.5,14.4,67.5,14.3,67.6,14.1z
	 M66,13.8c-0.1-0.2-0.1-0.4-0.2-0.6c0.1,0.2,0.2,0.3,0.2,0.5C66.1,13.7,66,13.8,66,13.8z M66,21.9c-0.2-0.5-0.3-1-0.3-1.5
	c0.1,0.1,0.2,0.3,0.2,0.4c0,0-0.1,0-0.1,0.1C65.9,21.2,66,21.5,66,21.9L66,21.9z M64.9,24.1c0.2,0.2,0.3,0.4,0.5,0.6
	c0,0-0.1,0,0,0.1c0.1,0.6,0.5,1.2,0.5,1.8C65.3,25.9,64.7,24.9,64.9,24.1z M65.5,30.3c-0.1-0.1-0.1-0.3-0.1-0.4
	c-0.1-0.4-0.2-0.9-0.2-1.3c0.2,0.3,0.3,0.5,0.4,0.9C65.4,29.7,65.4,30,65.5,30.3z M64.7,33c0.3,0.4,0.5,0.9,0.6,1.2v0.1
	C65.1,33.8,64.9,33.4,64.7,33z" />
			<path className='oat oat3' d="M78.1,35.6c-0.2,0-0.4,0-0.6,0c0,0,0-0.1,0.1-0.1c0.1-0.1-0.1-0.2-0.1-0.2c-0.9,0-1.8,0.6-2.5,1c-0.1,0,0,0.1,0.1,0.1
	s0.2-0.1,0.2-0.1c-0.1,0-0.2,0.1-0.2,0.2l0,0c-0.1,0.1-0.2,0.1-0.3,0.1h-0.1c-0.5,0.2-1.1,0.5-1.6,0.8c0.4-0.4,0.6-0.8,0.8-1.3
	c0-0.1-0.1-0.2-0.2-0.1c-1.2,0.3-2,1.4-2.8,2.3c-0.1,0.1,0,0.2,0,0.2s0,0-0.1,0.1c0,0,0,0.1,0.1,0.1l0.1-0.1c0,0,0,0,0,0.1
	c-0.5,0.3-1.1,0.7-1.6,1.1c0.3-0.6,0.5-1.2,0.4-1.7c0-0.1-0.1-0.1-0.2-0.1c-1.2,0.5-2.5,2.2-2.4,3.6c-1.1,1.1-3,3.5-3,3.6
	c0.1,0.1,0.1,0.2,0.1,0.3c1-1.1,2.3-2.6,3-3.4c0.1,0,0.2,0,0.2-0.1c0,0,0,0,0-0.1c0,0,0.1,0,0.1-0.1c0.2-0.1,0.4-0.2,0.5-0.4
	c0.1,0,0.1,0,0.2,0c1.1,0.4,2.7,0,3.5-0.7c0.1-0.1,0-0.2-0.1-0.2c-0.8-0.4-1.9-0.3-2.8,0.1c0.9-0.7,1.9-1.4,2.9-2c0,0,0,0.1,0.1,0.1
	c0.2,0.1,0.5,0.2,0.7,0.2c0.8,0.3,1.8,0.3,2.6-0.1c0.1,0,0.1-0.1,0-0.2c-0.2-0.1-0.3-0.2-0.5-0.3c0,0,0-0.1-0.1-0.1
	c-0.7-0.2-1.4-0.2-2.1,0c0.7-0.4,1.4-0.8,2.1-1.2c1.1,0.6,2.6-0.3,3.4-1.1C78.3,35.7,78.2,35.6,78.1,35.6z M71,40.6
	c-0.7,0.4-1.6,0.6-2.4,0.5l0,0l0,0c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0.1,0C69.6,40.8,70.3,40.4,71,40.6z M69,40.1
	c-0.1,0.1-0.3,0.2-0.4,0.3c0.2-0.3,0.4-0.6,0.7-0.9C69.2,39.7,69.1,39.9,69,40.1z M68.4,40.2c-0.2,0.3-0.5,0.6-0.7,0.9
	c0.1-0.6,0.4-1.1,0.7-1.6l0,0c0.2-0.3,0.4-0.6,0.7-0.8c0.1,0,0.7-0.7,0.6-0.3c0,0.2-0.1,0.5-0.2,0.8l0,0
	C69,39.4,68.7,39.9,68.4,40.2z M72.8,36.8c-0.3,0.3-0.6,0.6-0.9,0.9C72.1,37.4,72.4,37.1,72.8,36.8z M75.1,38.5
	c-0.5,0.2-1,0.3-1.6,0.2c0.4-0.1,0.8-0.2,1.1-0.4C74.8,38.4,75,38.5,75.1,38.5z M73.3,38.2L73.3,38.2c0.4,0,0.7,0,1,0
	c-0.6,0.2-1.3,0.3-1.8,0.2l0,0C72.7,38.3,73,38.3,73.3,38.2z M71.3,38.4c0.8-0.5,1.5-1.2,2.1-1.9C73,37.3,72.1,38.1,71.3,38.4z
	 M76.3,36.2L76.3,36.2c0.2-0.1,0.4-0.2,0.6-0.2l0,0l0,0l0,0c-0.3,0.3-0.8,0.4-1.2,0.5C75.8,36.4,76.1,36.3,76.3,36.2z M75,36.8
	L75,36.8L75,36.8L75,36.8L75,36.8c0.8,0.1,1.7-0.3,2.3-0.9c0.2,0,0.3,0,0.5,0C77.1,36.5,75.9,37.1,75,36.8z" />
			<path d="M83.5,28.1L83.5,28.1L83.5,28.1z" />
			<path d="M82.4,39.5L82.4,39.5L82.4,39.5L82.4,39.5z" />
			<path d="M80.6,40.3L80.6,40.3L80.6,40.3z" />
			<path d="M65.3,44.3c-0.2,0-0.3,0-0.5,0c-0.1,0.1-0.2,0.2-0.2,0.3c0.1-0.1,0.1-0.2,0.2-0.4c-0.2,0-0.3-0.1-0.4-0.1l0,0l0,0
	c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1,0.8-0.3,1.6-0.4,2.4c-0.4,1-0.8,2-1.2,3c-2.1,5.6-4.1,11.6-4.6,17.6c0,0.1,0.2,0.1,0.2,0
	c1.5-6.1,2.7-12.2,5.1-18.1c0.1-0.2,0.1-0.3,0.2-0.5c-0.1,0.6-0.2,1.1-0.3,1.7c-0.9,4.7-2.1,9.4-3,14.1c0,0.2,0.2,0.2,0.3,0
	c1.7-5.7,2.9-11.6,3.9-17.5C64.3,45.7,64.8,45,65.3,44.3L65.3,44.3z" />
		</svg>
	)
}