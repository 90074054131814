import React from 'react'

export default function Salt() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M1455 5106 c-121 -29 -117 -26 -748 -655 -329 -328 -612 -615 -629
-639 -69 -99 -94 -258 -64 -402 18 -86 80 -218 128 -272 18 -20 474 -384 1013
-809 803 -632 979 -776 977 -793 -1 -11 3 -43 9 -71 11 -47 26 -65 228 -268
234 -237 301 -289 433 -340 80 -30 89 -32 233 -32 139 0 156 2 230 28 145 51
177 77 538 436 367 365 410 416 461 560 29 82 31 93 31 236 0 144 -2 153 -32
233 -51 132 -103 199 -340 433 -203 202 -221 217 -268 228 -27 6 -60 10 -71 9
-18 -2 -95 90 -419 502 -218 278 -412 520 -431 538 -25 23 -43 32 -68 32 -62
0 -114 -64 -98 -122 3 -11 191 -257 419 -546 l414 -526 -73 -73 c-40 -40 -75
-69 -79 -65 -3 4 -169 205 -368 447 l-363 440 -76 37 c-85 42 -196 78 -317
105 -122 27 -405 24 -542 -5 -263 -57 -478 -167 -627 -321 -37 -39 -77 -93
-91 -123 -30 -65 -34 -148 -7 -195 11 -20 297 -256 775 -637 416 -334 757
-610 757 -614 0 -4 -31 -38 -68 -75 l-68 -68 -270 212 c-148 117 -341 269
-429 339 -88 69 -266 209 -395 311 -129 102 -373 294 -542 427 -168 133 -320
258 -336 278 -51 62 -77 145 -77 249 0 73 4 99 22 135 16 32 189 212 610 632
587 585 588 586 648 604 113 32 269 0 354 -72 22 -19 76 -81 120 -138 104
-132 147 -153 209 -101 37 31 49 75 30 112 -27 55 -197 259 -241 291 -143 102
-347 146 -502 108z m593 -1541 c83 -14 211 -50 300 -87 25 -10 123 -121 395
-451 199 -241 363 -441 365 -445 1 -4 -126 -135 -282 -291 -217 -217 -288
-282 -298 -274 -42 33 -1474 1177 -1481 1183 -14 12 18 62 70 111 218 204 599
308 931 254z m1629 -847 c35 -34 63 -67 63 -73 0 -13 -1252 -1265 -1265 -1265
-13 0 -135 122 -135 135 0 13 1252 1265 1265 1265 5 0 38 -28 72 -62z m276
-277 c66 -72 109 -145 132 -225 33 -115 8 -314 -50 -391 -15 -18 -17 -18 -60
25 -41 40 -51 45 -91 45 -59 0 -94 -36 -94 -96 0 -34 7 -48 50 -93 l49 -52
-69 -69 -70 -69 -42 42 c-57 55 -94 68 -142 48 -42 -17 -70 -70 -60 -111 3
-14 27 -48 52 -76 l45 -50 -68 -69 -69 -69 -52 49 c-45 43 -59 50 -93 50 -60
0 -96 -35 -96 -94 0 -40 5 -50 45 -91 42 -43 43 -45 25 -59 -79 -59 -276 -84
-391 -51 -81 23 -149 64 -225 134 l-64 60 635 635 c349 350 638 636 641 636 4
0 32 -27 62 -59z"/>
                <path d="M2350 4409 c-98 -39 -68 -189 38 -189 103 0 133 145 38 185 -40 17
-42 17 -76 4z"/>
                <path d="M4554 1180 c-55 -22 -72 -108 -31 -155 48 -56 133 -40 163 31 12 29
12 39 0 68 -24 57 -75 79 -132 56z"/>
                <path d="M4034 1086 c-37 -37 -43 -70 -20 -115 15 -28 53 -51 86 -51 51 0 100
49 100 99 0 34 -23 72 -51 87 -45 23 -78 17 -115 -20z"/>
                <path d="M4983 755 c-99 -43 -71 -185 36 -185 96 0 138 124 59 173 -37 24 -61
27 -95 12z"/>
                <path d="M4456 664 c-20 -21 -26 -37 -26 -69 0 -59 36 -95 95 -95 59 0 95 36
95 95 0 59 -36 95 -95 95 -32 0 -48 -6 -69 -26z"/>
                <path d="M3965 598 c-56 -49 -40 -134 31 -164 29 -12 39 -12 68 0 102 43 76
186 -34 186 -26 0 -47 -7 -65 -22z"/>
                <path d="M4401 184 c-13 -9 -29 -32 -37 -50 -29 -68 30 -142 104 -131 112 18
110 179 -3 193 -28 4 -47 0 -64 -12z"/>
            </g>
        </svg>
    )
}
